import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 40px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.buttonText &&
    css`
      width: 70px;
    `};
  ${(props) =>
    props.dark &&
    css`
      width: 255px;
      padding-left: 5px;
      padding-right: 5px;
      height: 39px;
      border-radius: 20px;
      background-color: ${Theme.colors.colorSecondary};
    `};
`;

export const AddIcon = styled.img`
  width: 19.4px;
  height: 19.4px;
  object-fit: contain;
`;

export const ButtonText = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  color: ${Theme.colors.colorSecondary};
  ${(props) =>
    props.dark &&
    css`
      color: ${Theme.colors.colorDisabled};
    `};
`;
