import { DOC_TYPE_ACTIONS } from "../actions/DocTypesActions";
import {
  DocTypeRow,
  DocType,
  DocTypeCountryFilter,
} from "../../utils/DataType";

export type docTypeStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  docTypes: [] as DocType[],
  docTypeRows: [] as DocTypeRow[],
  countryOption: [] as DocTypeCountryFilter[],
  successOnEditAction: false,
  successOnFormAction: false,
  loading: false,
  error: "",
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DOC_TYPE_ACTIONS.FETCH_DOC_TYPE_LIST_START:
      return { ...state, loading: true };
    case DOC_TYPE_ACTIONS.FETCH_DOC_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        docTypes: action.payload.docTypes,
        docTypeRows: action.payload.docTypeRows,
        countryOption: action.payload.countryOption,
      };
    case DOC_TYPE_ACTIONS.FETCH_DOC_TYPE_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case DOC_TYPE_ACTIONS.FORM_ACTION_START:
      return { ...state, loading: true };
    case DOC_TYPE_ACTIONS.FORM_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successOnFormAction: true,
        error: "",
      };
    case DOC_TYPE_ACTIONS.FORM_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case DOC_TYPE_ACTIONS.DOC_TYPE_EDIT_ACTION_START:
      return { ...state, loading: true };
    case DOC_TYPE_ACTIONS.DOC_TYPE_EDIT_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successOnEditAction: true,
        error: "",
      };
    case DOC_TYPE_ACTIONS.DOC_TYPE_EDIT_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case DOC_TYPE_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: action.payload };
    case DOC_TYPE_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case DOC_TYPE_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
