import React, { ReactElement } from "react";
import Search from "../../../assets/icons/search-regular.png";
import { SearchIcon } from "./styles";

type Props = {
  onClick?: () => void;
};

const SearchButton = ({ onClick }: Props): ReactElement => {
  return (
    <>
      <SearchIcon onClick={onClick} src={Search} />
    </>
  );
};

export default SearchButton;
