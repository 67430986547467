import * as axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: false,
});

export default class RequestManager {
  post(url, params, headers) {
    return api.post(url, params, { headers });
  }

  get(url, headers) {
    return api.get(url, { headers });
  }

  delete(url) {
    return api.delete(url);
  }

  patch(url, params, headers) {
    return api.patch(url, params, { headers });
  }

  put(url, params, headers) {
    return api.put(url, params, { headers });
  }

  all(promises) {
    return axios.all([...promises]);
  }
}
