import {
  AccountType,
  CashierType,
  UserType,
  TableColumn,
  StatusType,
  CelupagoUserRole,
  CountryOption,
  OperationStatus,
  ClientType,
  ChartFilter,
  MonthType,
  WeekType,
} from "./DataType";

export const CASHIER_TYPES: CashierType[] = [
  { id: 1, value: "MASTER", label: "Senior" },
  { id: 2, value: "STANDARD", label: "Junior" },
];

export const ACCOUNT_TYPES: AccountType[] = [
  { id: 1, value: "SAVING", label: "Ahorro", acronym: "CA" },
  { id: 2, value: "CHECKING", label: "Corriente", acronym: "CC" },
];

export const ACCOUNT_TYPES_LONG: AccountType[] = [
  { id: 1, value: "SAVING", label: "Cuenta ahorro", acronym: "CA" },
  { id: 2, value: "CHECKING", label: "Cuenta corriente", acronym: "CC" },
];

export const TREASURER_TYPES: UserType[] = [
  { id: 1, value: "TREASURER_ADMIN", label: "Tesorero Admin" },
  { id: 2, value: "TREASURER_AGENT", label: "Tesorero Agente" },
];

export const ROLES_CREATED_BY_SUPERUSER: UserType[] = [
  { id: 1, value: "ADMIN", label: "Administrador" },
];

export const CASHIER_TABLE_COLUMNS: TableColumn[] = [
  { title: "ID cajero" },
  { title: "Nombre cajero" },
  { title: "Fecha creación" },
  { title: "Creado por" },
  { title: "Tipo de cajero" },
  { title: "Estatus" },
];

export const STATUS_TYPES: StatusType[] = [
  { id: 1, value: "ACTIVE", label: "Activo" },
  { id: 2, value: "INACTIVE", label: "Inactivo" },
];

export const CHART_FILTER: ChartFilter[] = [
  { id: 1, value: 0.1, label: "Hoy" },
  { id: 2, value: 0.7, label: "1 semana" },
  { id: 3, value: 1, label: "1 mes" },
  { id: 4, value: 3, label: "3 meses" },
  { id: 5, value: 6, label: "6 meses" },
  { id: 6, value: 9, label: "9 meses" },
  { id: 7, value: 12, label: "12 meses" },
];

export const WEEK: WeekType[] = [
  { id: 1, value: "1", label: "Lun" },
  { id: 2, value: "2", label: "Mar" },
  { id: 3, value: "3", label: "Mir" },
  { id: 4, value: "4", label: "Jue" },
  { id: 5, value: "5", label: "Vie" },
  { id: 6, value: "6", label: "Sab" },
  { id: 7, value: "0", label: "Dom" },
];

export const MONTHS: MonthType[] = [
  { id: 1, value: "1", label: "Ene" },
  { id: 2, value: "2", label: "Feb" },
  { id: 3, value: "3", label: "Mar" },
  { id: 4, value: "4", label: "Abr" },
  { id: 5, value: "5", label: "May" },
  { id: 6, value: "6", label: "Jun" },
  { id: 7, value: "7", label: "Jul" },
  { id: 8, value: "8", label: "Ago" },
  { id: 9, value: "9", label: "Sep" },
  { id: 10, value: "10", label: "Oct" },
  { id: 11, value: "11", label: "Nov" },
  { id: 12, value: "12", label: "Dic" },
];

export const USER_TABLE_COLUMNS: TableColumn[] = [
  { title: "Rol" },
  { title: "Nombre" },
  { title: "Fecha creación" },
  { title: "Creado por" },
  { title: "Estatus" },
];

export const CELUPAGO_USER_ROLE: CelupagoUserRole[] = [
  { id: 1, value: "SUPERUSER", label: "Super usuario" },
  { id: 2, value: "ADMIN", label: "Administrador" },
  { id: 3, value: "TREASURER_ADMIN", label: "Tesorero Administrador" },
  { id: 4, value: "TREASURER_AGENT", label: "Tesorero Agente" },
];

export const COUNTRY_TABLE_COLUMNS: TableColumn[] = [
  { title: "País" },
  { title: "% Tasa de cambio" },
  { title: "% Ahorro" },
  { title: "Monto max" },
  { title: "Monto min" },
  { title: "Estatus" },
];

export const DOC_TYPE_TABLE_COLUMNS: TableColumn[] = [
  { title: "Tipos de documento" },
  { title: "Descripción" },
  { title: "País" },
  { title: "Estatus" },
];

export const FLAGS = {
  venFlag: require("../assets/flags/ven.png"),
  peruFlag: require("../assets/flags/peru.png"),
  argFlag: require("../assets/flags/arg.png"),
  bolFlag: require("../assets/flags/bol.png"),
  clpFlag: require("../assets/flags/clp.png"),
  colFlag: require("../assets/flags/col.png"),
  ecuFlag: require("../assets/flags/ecu.png"),
};

export const COUNTRY_OPTIONS: CountryOption[] = [
  {
    flag: FLAGS.venFlag,
    countryCurrency: "VES",
    iso: "VE",
  },
  {
    flag: FLAGS.peruFlag,
    countryCurrency: "PEN",
    iso: "PE",
  },
  {
    flag: FLAGS.argFlag,
    countryCurrency: "ARS",
    iso: "AR",
  },
  {
    flag: FLAGS.clpFlag,
    countryCurrency: "CLP",
    iso: "CL",
  },
  {
    flag: FLAGS.colFlag,
    countryCurrency: "COP",
    iso: "CO",
  },
  {
    flag: FLAGS.ecuFlag,
    countryCurrency: "USD",
    iso: "EC",
  },
];

export const OPERATION_TABLE: TableColumn[] = [
  { title: "ID cajero" },
  { title: "Nº de Transacción" },
  { title: "Fecha" },
  { title: "Monto orígen" },
  { title: "Monto destino" },
  { title: "Cripto orígen" },
  { title: "Cripto destino" },
  { title: "Estatus" },
];

export const OPERATION_STATUS: OperationStatus[] = [
  { id: 3, value: "RZ", label: "Rechazada" },
  { id: 4, value: "DT", label: "Devuelta" },
  { id: 5, value: "VD", label: "Validada" },
  { id: 6, value: "CE", label: "Compra exitosa" },
  { id: 7, value: "CNE", label: "Compra no exitosa" },
  { id: 8, value: "EP", label: "En proceso" },
  { id: 9, value: "CR", label: "Compra recibida" },
  { id: 10, value: "CRZ", label: "Compra rechazada" },
  { id: 11, value: "VR", label: "Venta realizada" },
  { id: 12, value: "CT", label: "Completada" },
];
export const OPERATION_STATUS_BY_ADMIN: OperationStatus[] = [
  { id: 3, value: "RZ", label: "Rechazada" },
  { id: 4, value: "DT", label: "Devuelta" },
  { id: 5, value: "VD", label: "Validada" },
  { id: 6, value: "CE", label: "Compra exitosa" },
  { id: 7, value: "CNE", label: "Compra no exitosa" },
  { id: 9, value: "CR", label: "Compra recibida" },
  { id: 10, value: "CRZ", label: "Compra rechazada" },
  { id: 11, value: "VR", label: "Venta realizada" },
  { id: 12, value: "CT", label: "Completada" },
];

export const OPERATION_STATUS_BY_AGENT: OperationStatus[] = [
  { id: 9, value: "CR", label: "Compra recibida" },
  { id: 10, value: "CRZ", label: "Compra rechazada" },
  { id: 11, value: "VR", label: "Venta realizada" },
  { id: 12, value: "CT", label: "Completada" },
];

export const BANK_TABLE: TableColumn[] = [
  { title: "País" },
  { title: "Banco" },
  { title: "Estatus" },
];

export const CLIENT_TABLE: TableColumn[] = [
  { title: "Nombre" },
  { title: "Documento de identidad" },
  { title: "País" },
  { title: "Tipo de cliente" },
  { title: "Estatus" },
];

export const CLIENT_TYPE: ClientType[] = [
  { id: 1, value: "VIP", label: "VIP" },
  { id: 2, value: "MASS", label: "MASS" },
];

export const SESSION_TIME_TO_ALERT: number = 600;
