import styled from "styled-components";

export const DetailIcon = styled.img`
  width: 14.9px;
  height: 9.9px;
  object-fit: contain;
`;

export const EditIcon = styled.img`
  width: 14.1px;
  height: 12.5px;
  object-fit: contain;
`;

export const TrashIcon = styled.img`
  width: 10.8px;
  height: 12.3px;
  object-fit: contain;
`;

export const RefreshIcon = styled.img`
  margin-top: 5px;
  width: 18px;
  height: 18px;
  object-fit: contain;
`;

export const ContentRefresh = styled.div`
  cursor: pointer;
`;

export const OptionIconContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
