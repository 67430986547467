import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../Button";
import { CashierAccount } from "../../../utils/DataType";
import {
  DialogTitleContainer,
  ResumeTitle,
  DataRow,
  DataLabel,
  DataValue,
} from "./styles";

type Props = {
  open: boolean;
  handleClose: () => void;
  account?: CashierAccount;
};

const CashierAccountDetail = ({ open, handleClose, account }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <DialogTitleContainer>
          <ResumeTitle className="dialog-title">Resumen de cuenta</ResumeTitle>
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        {account && (
          <>
            <DataRow>
              <DataLabel>Banco</DataLabel>
              <DataValue>{account.bank}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Tipo de cuenta</DataLabel>
              <DataValue>{account.accountType}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Nº de cuenta</DataLabel>
              <DataValue>{account.accountNumber}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Titular de cuenta</DataLabel>
              <DataValue>{account.accountHolder}</DataValue>
            </DataRow>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <DialogTitleContainer>
          <Button buttonText={"OK"} onClick={() => handleClose()} />
        </DialogTitleContainer>
      </DialogActions>
    </Dialog>
  );
};

export default CashierAccountDetail;
