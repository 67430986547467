import React from "react";
import { TextField, makeStyles, Theme, createStyles } from "@material-ui/core";

type Props = {
  id: string;
  styles?: string;
  type: string;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;
  errorMessage?: string;
  error?: boolean;
  maxLength?: number;
  minLength?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: any) => void;
  maxDate?: string;
  minDate?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: 245,
    },
    textFieldSmall: {
      width: 155,
    },
  })
);

const InputLight = ({
  id,
  styles,
  type,
  disabled = false,
  defaultValue,
  onFocus,
  onBlur,
  value,
  onChange,
  errorMessage,
  error,
  maxLength,
  minLength,
  maxDate,
  minDate,
}: Props) => {
  const classes = useStyles();

  const style = styles === "small" ? classes.textFieldSmall : classes.textField;

  return (
    <>
      <TextField
        data-testid="inputLight-container"
        id={id}
        name="InputLight"
        variant="outlined"
        className={style}
        size="small"
        type={type}
        autoComplete="off"
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        helperText={errorMessage}
        error={error}
        InputProps={{
          inputProps: {
            max: maxDate,
            min: minDate,
            maxLength: maxLength,
            minLength: minLength,
            style: {
              height: 15,
              fontSize: 14,
            },
          },
        }}
      />
    </>
  );
};

export default InputLight;
