import moment from "moment";
import {
  CASHIER_TYPES,
  STATUS_TYPES,
  CELUPAGO_USER_ROLE,
  OPERATION_STATUS,
  CLIENT_TYPE,
  ACCOUNT_TYPES_LONG,
  ACCOUNT_TYPES,
  MONTHS,
  WEEK,
} from "./ConstHelper";

export const latinFormat = (value: number) => {
  const formatter = new Intl.NumberFormat("es-VE", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(value).substr(4);
};

export const numberFormat = (value: number | string) => {
  const regex = /([^0-9])+/gi;
  const stringValue = value.toString().split(",");
  const valueToConvert = stringValue[0].replace(regex, "").replace(",", ".");
  const valueConverted = Number(valueToConvert);
  return Number.isNaN(valueConverted) ? 0 : valueConverted;
};

export const getCashierRowData = (cashier: any) => {
  return {
    cashierId: cashier.celupagos_id,
    cashierName: cashier.name + " " + cashier.last_name,
    createdAt: moment(cashier.created_at).format("DD/MM/YYYY"),
    createdBy: cashier.created_by.email,
    cashierType: CASHIER_TYPES.filter(
      (type) => type.value === cashier.cashier_type
    )[0].label,
    status: STATUS_TYPES.filter(
      (status) => status.value === cashier.cashier_status
    )[0].label,
    id: cashier.id,
  };
};

export const getCashier = (cashier: any) => {
  return {
    birthday: cashier.birthday,
    cashierStatus: STATUS_TYPES.filter(
      (status) => status.value === cashier.cashier_status
    )[0].label,
    cashierType: CASHIER_TYPES.filter(
      (type) => type.value === cashier.cashier_type
    )[0].label,
    celupagoId: cashier.celupagos_id,
    contractEnd: cashier.contract_end,
    contractStart: cashier.contract_start,
    createdAt: cashier.created_at,
    dailyLimit: cashier.daily_limit,
    document: cashier.document,
    email: cashier.email,
    guarantee: cashier.guarantee,
    id: cashier.id,
    name: cashier.name,
    lastName: cashier.last_name,
    phoneNumber: cashier.phone_number,
    phonePrefix: cashier.phone_prefix,
    rate: cashier.rate,
    createdBy: {
      email: cashier.created_by.email,
      id: cashier.created_by.id,
      name: cashier.created_by.name + " " + cashier.created_by.last_name,
    },
  };
};

export const getCashierEdit = (cashier: any) => {
  return {
    cashierStatus: STATUS_TYPES.filter(
      (status) => status.value === cashier.cashier_status
    )[0].value,
    cashierType: CASHIER_TYPES.filter(
      (type) => type.value === cashier.cashier_type
    )[0].value,
    celupagoId: cashier.celupagos_id,
    contractEnd: cashier.contract_end,
    contractStart: cashier.contract_start,
    dailyLimit: cashier.daily_limit,
    document: cashier.document,
    email: cashier.email,
    guarantee: cashier.guarantee,
    id: cashier.id,
    name: cashier.name,
    lastName: cashier.last_name,
    phoneNumber: cashier.phone_number,
    phonePrefix: cashier.phone_prefix,
    rate: cashier.rate,
    country: cashier.country.name,
    docType: cashier.docType.code,
  };
};

export const getCelupagoUserRowData = (celupagoUser: any) => {
  return {
    userRole: CELUPAGO_USER_ROLE.filter(
      (status) => status.value === celupagoUser.role
    )[0].label,
    userName: celupagoUser.name + " " + celupagoUser.last_name,
    createdAt: moment(celupagoUser.created_at).format("DD/MM/YYYY"),
    createdBy: celupagoUser.created_by ? celupagoUser.created_by.email : "N/A",
    status: STATUS_TYPES.filter(
      (status) => status.value === celupagoUser.user_status
    )[0].label,
    id: celupagoUser.id,
  };
};

export const getCelupagoUser = (celupagoUser: any) => {
  return {
    createdAt: celupagoUser.created_at,
    email: celupagoUser.email,
    id: celupagoUser.id,
    name: celupagoUser.name,
    lastName: celupagoUser.last_name,
    role: celupagoUser.role,
    userStatus: celupagoUser.user_status,
    createdBy: celupagoUser.created_by
      ? {
          email: celupagoUser.created_by.email,
          id: celupagoUser.created_by.id,
          name:
            celupagoUser.created_by.name +
            " " +
            celupagoUser.created_by.last_name,
        }
      : null,
  };
};

export const getCountryRowData = (country: any) => {
  return {
    name: country.name,
    rate: country.rate,
    saving: country.saving,
    maxAmount: country.max_amount ? country.max_amount + " USD" : "0 USD",
    minAmount: country.min_amount ? country.min_amount + " USD" : "0 USD",
    status: STATUS_TYPES.filter(
      (status) => status.value === country.country_status
    )[0].label,
    id: country.id,
  };
};

export const getCountry = (country: any) => {
  return {
    callingCode: country.calling_code,
    countryStatus: country.country_status,
    createdAt: country.created_at ? country.created_at : "",
    currency: country.currency,
    maxAmount: country.max_amount,
    minAmount: country.min_amount,
    name: country.name,
    rate: country.rate,
    saving: country.saving,
    iso: country.iso,
    id: country.id,
    manualRateEnable: country.exchange_rate_enable,
    manualRate: country.exchange_rate,
    manualUsdRate: country.exchange_rate_usd_destiny,
  };
};

export const getDocTypeRowData = (docType: any) => {
  return {
    docType: docType.code,
    description: docType.name,
    country: docType.country.name,
    status: STATUS_TYPES.filter(
      (status) => status.value === docType.doc_type_status
    )[0].label,
    id: docType.id,
  };
};

export const getDocType = (docType: any) => {
  return {
    code: docType.code,
    docType: docType.code,
    description: docType.name,
    createdAt: docType.created_at ? docType.created_at : "",
    status: docType.doc_type_status,
    id: docType.id,
    name: docType.name,
    country: {
      id: docType.country.id,
      name: docType.country.name,
      iso: docType.country.iso,
    },
  };
};

export const getDocTypeFilter = (country: any) => {
  return {
    id: country.id,
    value: country.iso,
    label: country.name,
  };
};

export const getOperation = (operation: any) => {
  return {
    bankReference: operation.bank_reference,
    celupagoReference: operation.celupago_reference,
    confirmationDate: operation.confirmation_date
      ? moment(operation.confirmation_date).format("DD/MM/YYYY")
      : "",
    cryptoAmount: operation.crypto_amount,
    destinationCryptoAmount: operation.destination_crypto_amount,
    destinationAmount: latinFormat(operation.destination_amount),
    destinationCurrency: operation.destination_currency,
    emissionDate: moment(operation.emission_date).format("DD/MM/YYYY hh:mm A"),
    exchangeType: operation.exchange_type,
    id: operation.id,
    originAmount: latinFormat(operation.origin_amount),
    originCurrency: operation.origin_currency,
    rate: operation.used_rate,
    saving: operation.used_saving,
    cashier: {
      id: operation.cashier.id,
      name: operation.cashier.name,
      lastName: operation.cashier.last_name,
      email: operation.cashier.email,
      cashierStatus: operation.cashier.cashier_status,
      cashierType: operation.cashier.cashier_type,
      cashierId: operation.cashier.celupagos_id,
    },
    cashierAccount: {
      accountHolder: operation.cashier_account.account_holder,
      accountNumber: operation.cashier_account.account_number,
      id: operation.cashier_account.id,
      bankName: operation.cashier_account.bank.entity_name,
      bankId: operation.cashier_account.bank.id,
      country: operation.cashier_account.bank.country.name,
    },
    clientAccount: {
      accountHolder: operation.client_account.account_holder,
      accountNumber: operation.client_account.account_number,
      accountType:
        operation.client_account.account_type &&
        ACCOUNT_TYPES.filter(
          (type) => type.value === operation.client_account.account_type
        )[0].acronym,
      phoneNumber: operation.client_account.phone_number,
      id: operation.client_account.id,
      bankName: operation.client_account.bank.entity_name,
      bankId: operation.client_account.bank.id,
      country: operation.client_account.bank.country.name,
      docType: operation.client_account.doc_type,
      document: operation.client_account.document,
    },
    crypto: operation.crypto
      ? {
          code: operation.crypto.code,
          id: operation.crypto.id,
        }
      : null,
    destinationCrypto: operation.destination_crypto
      ? {
          code: operation.destination_crypto.code,
          id: operation.destination_crypto.id,
        }
      : null,
    status: {
      code: operation.status.code,
      id: operation.status.id,
      status: OPERATION_STATUS.filter(
        (status) => status.value === operation.status.code
      )[0].label,
    },
    user: {
      id: operation.user.id,
      name: operation.user.name,
      lastName: operation.user.last_name,
      email: operation.user.email,
    },
  };
};

export const getOperationRowData = (operation: any) => {
  return {
    cashierId: operation.cashier.celupagos_id,
    transactionNumber: operation.celupago_reference,
    date: moment(operation.emission_date).format("DD/MM/YYYY"),
    amountOrigin:
      latinFormat(operation.origin_amount) + " " + operation.origin_currency,
    amountDestiny:
      latinFormat(operation.destination_amount) +
      " " +
      operation.destination_currency,
    cryptoOrigin:
      operation.crypto_amount +
      " " +
      (operation.crypto ? operation.crypto.code : ""),
    cryptoDestiny:
      operation.destination_crypto_amount +
      " " +
      (operation.destination_crypto ? operation.destination_crypto.code : ""),
    status: OPERATION_STATUS.filter(
      (status) => status.value === operation.status.code
    )[0].label,
    id: operation.id,
  };
};

export const getOperationReportRow = (operation: any) => {
  return [
    `C ${operation.cashier.celupagos_id}`,
    moment(operation.emission_date).format("DD/MM/YYYY"),
    moment(operation.emission_date).format("hh:mm A"),
    `TX ${operation.celupago_reference}`,
    `${operation.user.docType.code}-${operation.user.document}`,
    operation.user.email,
    `${operation.client_account.doc_type}-${operation.client_account.document}`,
    operation.client_account.email,
    operation.cashier_account.bank.country.name,
    operation.client_account.bank.country.name,
    operation.origin_amount,
    operation.destination_amount,
    operation.crypto
      ? `${operation.crypto.code}-${operation.crypto_amount}`
      : "N/A",
    operation.destination_crypto
      ? `${operation.destination_crypto.code}-${operation.destination_crypto_amount}`
      : "N/A",
    operation.status.name,
  ];
};

export const getOperationReport = (
  operations: any,
  countries: any,
  cryptos: any,
  cryptoCoinsSold: any
) => {
  let reportData: any = [];
  const byCountry = countries.map((country: any) => {
    let totalOriginAmount = 0;
    let totalDestinationAmount = 0;
    let originCurrency = "";
    let destinationCurrency = "";
    const dataRows = operations
      .filter((op: any) => op.cashier_account.bank.country.name === country)
      .map((operation: any) => {
        totalOriginAmount = totalOriginAmount + Number(operation.origin_amount);
        totalDestinationAmount =
          totalDestinationAmount + Number(operation.destination_amount);
        originCurrency = operation.origin_currency;
        destinationCurrency = operation.destination_currency;
        return getOperationReportRow(operation);
      });
    const cryptoRowsOrigin = cryptos.map((coin: any) => {
      let totalAmount = 0;
      operations
        .filter(
          (op: any) =>
            op.cashier_account.bank.country.name === country &&
            op.crypto &&
            op.crypto.code === coin
        )
        .map((operation: any) => {
          totalAmount = totalAmount + Number(operation.crypto_amount);
          return null;
        });
      return ["TOTAL CANTIDAD CRIPTOMONEDA ORIGEN", `${coin} ${totalAmount}`];
    });

    const cryptoRowsDestination = cryptos.map((coin: any) => {
      let totalAmount = 0;
      operations
        .filter(
          (op: any) =>
            op.cashier_account.bank.country.name === country &&
            op.destination_crypto &&
            op.destination_crypto.code === coin
        )
        .map((operation: any) => {
          totalAmount =
            totalAmount + Number(operation.destination_crypto_amount);
          return null;
        });
      return ["TOTAL CANTIDAD CRIPTOMONEDA DESTINO", `${coin} ${totalAmount}`];
    });

    return [
      [`PAÍS ${country}`],
      [],
      [
        "ID_CAJERO",
        "FECHA_TX",
        "HORA_TX",
        "ID_TX",
        "TIPO_NRO_DOCUMENTO_ID_REMITENTE",
        "EMAIL_REMITENTE",
        "TIPO_NRO_DOCUMENTO_ID_BEF",
        "EMAIL_BEF",
        "PAIS_ORIG",
        "PAIS_DEST",
        "MONTO_ORIG",
        "MONTO_DEST",
        "TIPO_CANT_CRYPTO_ORIG",
        "TIPO_CANT_CRYPTO_DEST",
        "ESTATUS",
      ],
      ...dataRows,
      [],
      ["TOTAL OPERACIONES", dataRows.length],
      [
        "TOTAL MONTO ENVIADO",
        `${originCurrency} ${latinFormat(totalOriginAmount)}`,
      ],
      [
        "TOTAL MONTO RECIBIDO",
        `${destinationCurrency} ${latinFormat(totalDestinationAmount)}`,
      ],
      ...cryptoRowsOrigin,
      ...cryptoRowsDestination,
      [],
    ];
  });

  byCountry.forEach((country: any) => {
    reportData.push(...country);
  });

  return [...reportData, [], ["TOTAL GENERAL OPERACIONES", operations.length]];
};

export const getOperationChartMonth = (operation: any) => {
  return {
    value: MONTHS.filter(
      (month) => month.value === moment(operation.emission_date).format("M")
    )[0].label,
    id: MONTHS.filter(
      (month) => month.value === moment(operation.emission_date).format("M")
    )[0].id,
  };
};

export const getOperationChartWeekday = (operation: any) => {
  return {
    value: WEEK.filter(
      (week) => week.value === moment(operation.emission_date).format("e")
    )[0].label,
    id: WEEK.filter(
      (week) => week.value === moment(operation.emission_date).format("e")
    )[0].id,
  };
};

export const getChartMonth = (data: any) => {
  return {
    value: MONTHS.filter(
      (month) => month.value === moment(data.created_at).format("M")
    )[0].label,
    id: MONTHS.filter(
      (month) => month.value === moment(data.created_at).format("M")
    )[0].id,
  };
};

export const getChartWeekday = (data: any) => {
  return {
    value: WEEK.filter(
      (week) => week.value === moment(data.created_at).format("e")
    )[0].label,
    id: WEEK.filter(
      (week) => week.value === moment(data.created_at).format("e")
    )[0].id,
  };
};

export const getChartOperationFormatted = (rejected: any, completed: any) => {
  if (rejected.length >= completed.length) {
    return rejected.map((rej: any) => {
      const amountCompleted = completed.filter(
        (com: any) => com.name === rej.name
      );
      return {
        name: rej.name,
        Aprobadas: amountCompleted.length !== 0 ? amountCompleted[0].value : 0,
        Rechazadas: rej.value,
      };
    });
  } else {
    return completed.map((com: any) => {
      const amountRejected = rejected.filter(
        (rej: any) => rej.name === com.name
      );
      return {
        name: com.name,
        Aprobadas: com.value,
        Rechazadas: amountRejected.length !== 0 ? amountRejected[0].value : 0,
      };
    });
  }
};

export const getChartCashiersFormatted = (cashiers: any) => {
  return cashiers.map((cashier: any) => {
    return {
      name: cashier.name,
      Cajeros: cashier.value,
    };
  });
};

export const getChartUsersFormatted = (users: any) => {
  return users.map((user: any) => {
    return {
      name: user.name,
      Usuarios: user.value,
    };
  });
};

export const sortAscId = (data: any) => {
  return data.sort((a: any, b: any) => {
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    return 0;
  });
};

export const countRepeated = (data: any) => {
  const arrayReduce = data.reduce((count: any, name: any) => {
    count[name.value] = (count[name.value] || 0) + 1;
    return count;
  }, {});

  const keys = Object.keys(arrayReduce);
  const values = Object.values(arrayReduce);

  return keys.map((key: any, index) => ({ name: key, value: values[index] }));
};

export const getBank = (bank: any) => {
  return {
    bankStatus: bank.bank_status,
    code: bank.code,
    commercialName: bank.commercial_name,
    createdAt: bank.created_at ? bank.created_at : "",
    digits: bank.digits,
    entityName: bank.entity_name,
    id: bank.id,
    country: {
      id: bank.country.id,
      name: bank.country.name,
      iso: bank.country.iso,
    },
  };
};

export const getBakRowData = (bank: any) => {
  return {
    country: bank.country.name,
    name: bank.entity_name,
    status: STATUS_TYPES.filter(
      (status) => status.value === bank.bank_status
    )[0].label,
    id: bank.id,
  };
};

export const getBankFilter = (country: any) => {
  return {
    id: country.id,
    value: country.iso,
    label: country.name,
  };
};

export const getClientRow = (client: any) => {
  return {
    name: client.name + " " + client.last_name,
    document: client.docType.code + "-" + client.document,
    country: client.country.name,
    type: CLIENT_TYPE.filter((type) => type.value === client.client_type)[0]
      .label,
    id: client.id,
    status: STATUS_TYPES.filter(
      (status) => status.value === client.user_status
    )[0].label,
  };
};

export const getClient = (client: any) => {
  return {
    birthday: client.birthday
      ? moment(client.birthday).format("DD/MM/YYYY")
      : "",
    clientType: client.client_type,
    document: client.document,
    email: client.email,
    gender: client.gender ? client.gender : "",
    id: client.id,
    lastName: client.last_name,
    name: client.name,
    phoneNumber: client.phone_number,
    phonePrefix: client.phone_prefix,
    country: {
      id: client.country.id,
      name: client.country.name,
      iso: client.country.iso,
    },
    docType: {
      id: client.docType.id,
      code: client.docType.code,
    },
    clientStatus: client.user_status,
  };
};

export const getClientsFilter = (country: any) => {
  return {
    id: country.id,
    value: country.iso,
    label: country.name,
  };
};

export const getNewlyCashier = (cashier: any) => {
  return {
    birthday: cashier.birthday,
    cashierStatus: STATUS_TYPES.filter(
      (status) => status.value === cashier.cashier_status
    )[0].label,
    cashierType: CASHIER_TYPES.filter(
      (type) => type.value === cashier.cashier_type
    )[0].label,
    celupagoId: cashier.celupagos_id,
    contractEnd: moment(cashier.contract_end).format("DD/MM/YYYY"),
    contractStart: moment(cashier.contract_start).format("DD/MM/YYYY"),
    createdAt: cashier.created_at,
    dailyLimit: cashier.daily_limit,
    document: cashier.document,
    email: cashier.email,
    guarantee: cashier.guarantee,
    id: cashier.id,
    name: cashier.name,
    lastName: cashier.last_name,
    phoneNumber: cashier.phone_number,
    phonePrefix: cashier.phone_prefix,
    rate: cashier.rate,
    country: {
      id: cashier.country.id,
      name: cashier.country.name,
      iso: cashier.country.iso,
    },
    docType: {
      id: cashier.docType.id,
      name: cashier.docType.name,
      code: cashier.docType.code,
    },
  };
};

export const getBankToDropDown = (bank: any) => {
  return {
    id: bank.id,
    value: bank.id,
    label: bank.commercial_name,
  };
};

export const getCryptoToDropDown = (crypto: any) => {
  return {
    id: crypto.id,
    value: crypto.id,
    label: crypto.name,
  };
};

export const getCashierAccount = (account: any) => {
  return {
    accountNumber: account.account_number,
    accountType: ACCOUNT_TYPES_LONG.filter(
      (type) => type.value === account.account_type
    )[0].label,
    bank: account.bank.commercial_name,
    country: account.bank.country.name,
    iso: account.bank.country.iso,
    currency: account.bank.country.currency,
    cashierId: account.cashier.celupagos_id,
    accountHolder: account.account_holder,
    id: account.id,
  };
};
