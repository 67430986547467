import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  justify-content: space-between;
`;

export const ContainerProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const LogoProfile = styled.img`
  width: 53.9px;
  height: 53.9px;
  object-fit: contain;
  margin-left: 20px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  ${(props) =>
    props.subTitle &&
    css`
      font-size: 14px;
    `};
  ${(props) =>
    props.light &&
    css`
      font-weight: normal;
    `};
  ${(props) =>
    props.helpPassword &&
    css`
      font-size: 11px;
      color: #aaaaaa;
    `};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  justify-content: flex-start;
  align-items: center;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 45%;
  height: 60px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
`;

export const LogoEdit = styled.img`
  position: absolute;
  margin-top: 10px;
  width: 14.1px;
  height: 12.5px;
  right: 20.5%;
  margin-top: 10px;
  cursor: pointer;
  object-fit: contain;
`;

export const LogoCheckCircle = styled.img`
  width: 11.6px;
  height: 11.6px;
  object-fit: contain;
  margin-right: 5px;
  margin-left: 5px;
`;

export const HelpContainer = styled.div`
  flex-direction: row;
  width: 240px;
`;
