import { CLIENT_ACTIONS } from "../actions/ClientActions";
import { ClientRow, Client, ClientCountryFilter } from "../../utils/DataType";

export type clientStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  clients: [] as Client[],
  clientRows: [] as ClientRow[],
  countryOption: [] as ClientCountryFilter[],
  loading: false,
  successOnEditAction: false,
  error: "",
  client: {} as Client,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CLIENT_ACTIONS.FETCH_CLIENT_LIST_START:
      return { ...state, loading: true };
    case CLIENT_ACTIONS.FETCH_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.payload.clients,
        clientRows: action.payload.clientRows,
        error: "",
        countryOption: action.payload.countryOption,
      };
    case CLIENT_ACTIONS.FETCH_CLIENT_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CLIENT_ACTIONS.FETCH_CLIENT_BY_ID_START:
      return { ...state, loading: true };
    case CLIENT_ACTIONS.FETCH_CLIENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        client: action.payload.client,
      };
    case CLIENT_ACTIONS.FETCH_CLIENT_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CLIENT_ACTIONS.CLIENT_EDIT_ACTION_START:
      return { ...state, loading: true };
    case CLIENT_ACTIONS.CLIENT_EDIT_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        successOnEditAction: true,
      };
    case CLIENT_ACTIONS.CLIENT_EDIT_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CLIENT_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: action.payload };
    case CLIENT_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case CLIENT_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
