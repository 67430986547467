import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../../common/Button";
import InputLight from "../../../common/InputLight";
import Modal from "../../../common/Modal";
import Dropdown from "../../../common/Dropdown";
import BackButton from "../../../common/BackButton";
import Loader from "../../../common/Loader";
import { AvailableCountry } from "../../../../utils/DataType";
import { validateNames, validateBlanks } from "../../../../utils/Validators";
import {
  cleanDocTypeState,
  createNewDocType,
} from "../../../../ducks/actions/DocTypesActions";
import { getAvailableCountries } from "../../../../ducks/actions/CountryActions";
import { globalStateType } from "../../../../ducks/reducers";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  success: boolean;
  loading: boolean;
  error: string;
  availableCountries: AvailableCountry[];
  cleanDocTypeState: () => void;
  newDocumentPress: () => void;
  getAvailableCountries: () => void;
  createNewDocType: (name: string, code: string, country: number) => void;
};

const NewDocument = ({
  success,
  loading,
  availableCountries,
  newDocumentPress,
  getAvailableCountries,
  cleanDocTypeState,
  createNewDocType,
  error,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState({ value: "", error: false });
  const [code, setCode] = useState({ value: "", error: false });

  useEffect(() => {
    getAvailableCountries();
  }, [getAvailableCountries]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setDescription({ ...description, value });
    }
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    if (!validateBlanks(value)) {
      if (validateNames(value)) setCode({ ...code, value });
    }
  };

  const handleDescriptionOnBlur = () => {
    if (description.value) setDescription({ ...description, error: false });
    else setDescription({ ...description, error: true });
  };

  const handleCodeOnBlur = () => {
    if (code.value) setCode({ ...code, error: false });
    else setCode({ ...code, error: true });
  };

  const enableButton = () => {
    if (
      country &&
      code.value &&
      description.value &&
      !code.error &&
      !description.error
    )
      return false;
    else return true;
  };

  const hideModal = () => {
    setVisibleModal(false);
    cleanDocTypeState();
    newDocumentPress();
  };
  const handleOnButtonClick = () => {
    createNewDocType(description.value, code.value, Number(country));
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={newDocumentPress} />
      </BackButtonContainer>
      <Container info>
        <Title>Nuevo tipo documento de identidad</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Descripción tipo de documento</Title>
            <InputLight
              id="descriptionDocument"
              type="text"
              value={description.value}
              onChange={handleDescriptionChange}
              error={description.error}
              onBlur={handleDescriptionOnBlur}
              errorMessage={
                description.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={30}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <Dropdown
              id={"country"}
              options={availableCountries}
              value={country}
              onChange={handleCountryChange}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Siglas documento</Title>
            <InputLight
              id="documentAcronym"
              type="text"
              value={code.value}
              onChange={handleCodeChange}
              error={code.error}
              onBlur={handleCodeOnBlur}
              errorMessage={code.error ? "El campo no puede estar vacío" : ""}
              maxLength={5}
              minLength={3}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Registro exitoso!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.docType.loading,
    success: state.docType.successOnFormAction,
    availableCountries: state.country.availableCountries,
    error: state.docType.error,
  };
};

export default connect(mapStateTopProps, {
  getAvailableCountries,
  cleanDocTypeState,
  createNewDocType,
})(NewDocument);
