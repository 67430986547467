import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../../../common/Table";
import Loader from "../../../common/Loader";
import AddButton from "../../../common/AddButton";
import OptionFilter from "../../../common/OptionFilter";
import SearchButton from "../../../common/SearchButton";
import Modal from "../../../common/Modal";
import Edit from "../../../../assets/icons/edit-purple.png";
import Trash from "../../../../assets/icons/trash-can.png";
import {
  STATUS_TYPES,
  DOC_TYPE_TABLE_COLUMNS,
} from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import {
  DocTypeRow,
  DocType,
  DocTypeCountryFilter,
} from "../../../../utils/DataType";
import {
  getAllDocTypes,
  disableDocType,
} from "../../../../ducks/actions/DocTypesActions";
import {
  Container,
  Title,
  TableContainer,
  AddButtonContainer,
  FilterContainer,
  EditIcon,
  TrashIcon,
} from "../styles";

type Props = {
  loading: boolean;
  error: string;
  docTypeRows: DocTypeRow[];
  docTypes: DocType[];
  countryOption: DocTypeCountryFilter[];
  newDocumentPress: () => void;
  editDocumentPress: () => void;
  selectedDocument: (document: DocType) => void;
  getAllDocTypes: (status?: string, country?: string) => void;
  disableDocType: (id: number) => void;
};

const DocumentList = ({
  loading,
  error,
  docTypeRows,
  newDocumentPress,
  editDocumentPress,
  selectedDocument,
  getAllDocTypes,
  docTypes,
  countryOption,
  disableDocType,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [errorModal, setVisibleErrorModal] = useState(false);
  const [registerToDisable, setRegisterToDisable] = useState(0);
  const [docTypeStatus, setDocTypeStatus] = useState({
    value: "",
    label: "",
    id: 0,
  });
  const [docCountryStatus, setDocCountryStatus] = useState({
    value: "",
    label: "",
    id: 0,
  });

  useEffect(() => {
    getAllDocTypes("ACTIVE");
  }, [getAllDocTypes]);

  useEffect(() => {
    if (error) {
      setVisibleErrorModal(true);
    }
  }, [error]);

  const filteredSearch = () => {
    if (docTypeStatus.value || docCountryStatus.value) {
      getAllDocTypes(docTypeStatus.value, docCountryStatus.value);
    } else {
      getAllDocTypes();
    }
  };

  const handleEditClick = (docType: DocTypeRow) => {
    const docToEdit = docTypes.filter((doc) => docType.id === doc.id);
    selectedDocument(docToEdit[0]);
    editDocumentPress();
  };

  const handleTrashClick = (docType: DocType) => {
    setVisibleModal(true);
    setRegisterToDisable(docType.id);
  };

  const hideModal = () => {
    setVisibleModal(false);
    setRegisterToDisable(0);
  };
  const confirmModalAction = () => {
    disableDocType(registerToDisable);
    setVisibleModal(false);
  };
  const hideErrorModal = () => {
    setVisibleErrorModal(false);
    setRegisterToDisable(0);
  };

  const renderLoader = () => loading && <Loader />;

  const optionComponents = [<EditIcon src={Edit} />, <TrashIcon src={Trash} />];

  return (
    <Container tableView>
      {renderLoader()}
      <Container info>
        <Title>Tipos de documento</Title>
      </Container>
      <TableContainer>
        <AddButtonContainer>
          <AddButton buttonText="Nuevo" onClick={newDocumentPress} />
        </AddButtonContainer>
        <FilterContainer>
          <OptionFilter
            value={docTypeStatus}
            handleFilterChange={(option) => {
              setDocTypeStatus(option);
            }}
            label={"Estatus"}
            options={STATUS_TYPES}
            clearFilter={() =>
              setDocTypeStatus({ value: "", label: "", id: 0 })
            }
          />
          <OptionFilter
            value={docCountryStatus}
            handleFilterChange={(option) => {
              setDocCountryStatus(option);
            }}
            label={"País"}
            options={countryOption}
            clearFilter={() =>
              setDocCountryStatus({ value: "", label: "", id: 0 })
            }
          />
          <SearchButton onClick={filteredSearch} />
        </FilterContainer>
        <Table
          tableColumns={DOC_TYPE_TABLE_COLUMNS}
          dataRows={docTypeRows}
          optionComponents={optionComponents}
          onOptionOneClick={handleEditClick}
          onOptionTwoClick={handleTrashClick}
        />
      </TableContainer>
      <Modal
        show={visibleModal}
        title={"¿Estás seguro que deseas hacer la desactivación?"}
        body={""}
        twoOption
        confirmationOption={confirmModalAction}
        cancelOption={hideModal}
      />
      <Modal
        show={errorModal}
        title={""}
        body={"Ha ocurrido un error, inténtalo nuevamente"}
        handleClose={hideErrorModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.docType.loading,
    error: state.docType.error,
    docTypes: state.docType.docTypes,
    docTypeRows: state.docType.docTypeRows,
    countryOption: state.docType.countryOption,
  };
};

export default connect(mapStateTopProps, { getAllDocTypes, disableDocType })(
  DocumentList
);
