import { combineReducers } from "redux";
import UserReducer, { userStateType } from "./UserReducer";
import CashierReducer, { cashierStateType } from "./CashierReducer";
import CelupagoUserReducer, {
  celupagoUserStateType,
} from "./CelupagoUserReducer";
import CountryReducer, { countryStateType } from "./CountryReducer";
import DocTypesReducer, { docTypeStateType } from "./DocTypesReducer";
import OperationReducer, { operationStateType } from "./OperationReducer";
import BankReducer, { bankStateType } from "./BankReducer";
import ClientReducer, { clientStateType } from "./ClientReducer";
import DashboardReducer, { dashboardStateType } from "./DashboardReducer";

export type globalStateType = {
  user: userStateType;
  cashier: cashierStateType;
  celupagoUser: celupagoUserStateType;
  country: countryStateType;
  docType: docTypeStateType;
  operation: operationStateType;
  bank: bankStateType;
  client: clientStateType;
  dashboard: dashboardStateType;
};

export default combineReducers<globalStateType>({
  user: UserReducer,
  cashier: CashierReducer,
  celupagoUser: CelupagoUserReducer,
  country: CountryReducer,
  docType: DocTypesReducer,
  operation: OperationReducer,
  bank: BankReducer,
  client: ClientReducer,
  dashboard: DashboardReducer,
});
