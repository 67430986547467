import React, { useState } from "react";
import NewDocument from "./NewDocument";
import DocumentList from "./DocumentList";
import EditDocument from "./EditDocument";

const Document = () => {
  const [newDocument, handleNewDocumentPress] = useState(false);
  const [editDocumentPress, handleEditDocumentPress] = useState(false);
  const [selectedDocument, handleSelectedDocument] = useState();

  return (
    <>
      {!newDocument ? (
        !editDocumentPress ? (
          <DocumentList
            newDocumentPress={() => handleNewDocumentPress(!newDocument)}
            editDocumentPress={() =>
              handleEditDocumentPress(!editDocumentPress)
            }
            selectedDocument={(document) => handleSelectedDocument(document)}
          />
        ) : (
          <EditDocument
            selectedDocument={selectedDocument}
            editDocumentPress={() =>
              handleEditDocumentPress(!editDocumentPress)
            }
          />
        )
      ) : (
        <NewDocument
          newDocumentPress={() => handleNewDocumentPress(!newDocument)}
        />
      )}
    </>
  );
};

export default Document;
