import React, { ReactElement } from "react";
import Back from "../../../assets/icons/back-arrow.png";
import { Container, BackIcon, ButtonText } from "./styles";

type Props = {
  onClick?: () => void;
  buttonText?: string;
  wide?: boolean;
};

const BackButton = ({ onClick, buttonText, wide }: Props): ReactElement => {
  return (
    <Container buttonText={buttonText} onClick={onClick} wide={wide}>
      <BackIcon src={Back} />
      {buttonText && <ButtonText>{buttonText}</ButtonText>}
    </Container>
  );
};

export default BackButton;
