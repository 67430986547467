import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../../common/Button";
import InputLight from "../../../common/InputLight";
import Modal from "../../../common/Modal";
import Dropdown from "../../../common/Dropdown";
import BackButton from "../../../common/BackButton";
import Loader from "../../../common/Loader";
import { validateNames } from "../../../../utils/Validators";
import { AvailableCountry } from "../../../../utils/DataType";
import { globalStateType } from "../../../../ducks/reducers";
import {
  createNewBank,
  cleanBankState,
} from "../../../../ducks/actions/BankActions";
import { getAvailableCountries } from "../../../../ducks/actions/CountryActions";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  success: boolean;
  loading: boolean;
  error: string;
  availableCountries: AvailableCountry[];
  cleanBankState: () => void;
  newBankPress: () => void;
  getAvailableCountries: () => void;
  createNewBank: (
    entity_name: string,
    commercial_name: string,
    code: string,
    digits: number,
    country: number
  ) => void;
};

const NewBank = ({
  success,
  loading,
  newBankPress,
  createNewBank,
  cleanBankState,
  getAvailableCountries,
  availableCountries,
  error,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [country, setCountry] = useState("");
  const [bank, setBank] = useState({ value: "", error: false });
  const [commercialName, setCommercialName] = useState({
    value: "",
    error: false,
  });
  const [bankCode, setBankCode] = useState({ value: "", error: false });

  useEffect(() => {
    getAvailableCountries();
  }, [getAvailableCountries]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
  };

  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setBank({ ...bank, value });
    }
  };

  const handleBankCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setBankCode({ ...bankCode, value });
    }
  };

  const handleCommercialNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.toUpperCase();
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setCommercialName({ ...commercialName, value });
    }
  };
  const handleBankOnBlur = () => {
    if (bank.value) setBank({ ...bank, error: false });
    else setBank({ ...bank, error: true });
  };
  const handleBankCodeOnBlur = () => {
    if (bankCode.value) setBankCode({ ...bankCode, error: false });
    else setBankCode({ ...bankCode, error: true });
  };
  const handleCommercialNameOnBlur = () => {
    if (commercialName.value)
      setCommercialName({ ...commercialName, error: false });
    else setCommercialName({ ...commercialName, error: true });
  };

  const enableButton = () => {
    if (
      country &&
      bank.value &&
      commercialName.value &&
      bankCode.value &&
      !bank.error &&
      !commercialName.error &&
      !bankCode.error
    )
      return false;
    else return true;
  };

  const hideModal = () => {
    setVisibleModal(false);
    cleanBankState();
    newBankPress();
  };
  const handleOnButtonClick = () => {
    createNewBank(
      bank.value,
      commercialName.value,
      bankCode.value,
      20,
      Number(country)
    );
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={newBankPress} />
      </BackButtonContainer>
      <Container info>
        <Title>Nuevo banco</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre de banco</Title>
            <InputLight
              id="bankName"
              type="text"
              value={bank.value}
              onChange={handleBankChange}
              error={bank.error}
              onBlur={handleBankOnBlur}
              errorMessage={bank.error ? "El campo no puede estar vacío" : ""}
              maxLength={15}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre comercial</Title>
            <InputLight
              id="tradeName"
              type="text"
              value={commercialName.value}
              onChange={handleCommercialNameChange}
              error={commercialName.error}
              onBlur={handleCommercialNameOnBlur}
              errorMessage={
                commercialName.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={15}
              minLength={3}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Siglas banco</Title>
            <InputLight
              id="bankAcronym"
              type="text"
              value={bankCode.value}
              onChange={handleBankCodeChange}
              error={bankCode.error}
              onBlur={handleBankCodeOnBlur}
              errorMessage={
                bankCode.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={15}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <Dropdown
              id={"country"}
              options={availableCountries}
              value={country}
              onChange={handleCountryChange}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Registro exitoso!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.bank.loading,
    success: state.bank.successOnFormAction,
    availableCountries: state.country.availableCountries,
    error: state.bank.error,
  };
};

export default connect(mapStateTopProps, {
  createNewBank,
  cleanBankState,
  getAvailableCountries,
})(NewBank);
