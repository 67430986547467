import React, { ReactElement } from "react";
import { ButtonContainer, ButtonComponent } from "./styles";

type Props = {
  disabled?: boolean;
  twoInRow?: boolean;
  buttonText: string;
  secondary?: boolean;
  onClick?: () => void;
};

const Button = ({
  disabled = false,
  twoInRow = false,
  secondary = false,
  buttonText,
  onClick,
}: Props): ReactElement => {
  return (
    <ButtonContainer twoInRow={twoInRow}>
      <ButtonComponent
        type="button"
        onClick={onClick}
        disabled={disabled}
        twoInRow={twoInRow}
        secondary={secondary}
      >
        {buttonText}
      </ButtonComponent>
    </ButtonContainer>
  );
};

export default Button;
