import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AccountCashier from "../AccountCashier";
import Loader from "../../../common/Loader";
import Card from "../../../common/Card";
import AddButton from "../../../common/AddButton";
import Modal from "../../../common/Modal";
import CashierAccountDetail from "../../../common/CashierAccountDetail";
import { COUNTRY_OPTIONS } from "../../../../utils/ConstHelper";
import { CashierAccount } from "../../../../utils/DataType";
import { globalStateType } from "../../../../ducks/reducers";
import {
  getCashierAccounts,
  disableCashierAccount,
} from "../../../../ducks/actions/CashierActions";
import { Container, Title, MainContainer, ButtonSection } from "../styles";

type Props = {
  selectedCashier: number;
  loading: boolean;
  success: boolean;
  cashierAccounts: CashierAccount[];
  getCashierAccounts: (id: number) => void;
  disableCashierAccount: (id: number) => void;
};

const EditAccountCashier = ({
  selectedCashier,
  loading,
  cashierAccounts,
  getCashierAccounts,
  disableCashierAccount,
}: Props) => {
  const [currentComponent, setCurrentComponent] = useState(0);

  const [visibleModal, setVisibleModal] = useState(false);
  const [registerToDisable, setRegisterToDisable] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(
    undefined as undefined | CashierAccount
  );
  useEffect(() => {
    getCashierAccounts(selectedCashier);
  }, [currentComponent, selectedCashier, getCashierAccounts]);

  const handleOnButtonClick = () => {
    setCurrentComponent(1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDetailClick = (accountId: number) => {
    const currentAccount = cashierAccounts.filter(
      (option) => option.id === accountId
    );

    setCurrentAccount(currentAccount[0]);
    setOpen(true);
  };

  const handleTrashClick = (accountId: number) => {
    setVisibleModal(true);
    setRegisterToDisable(accountId);
  };

  const confirmModalAction = () => {
    disableCashierAccount(registerToDisable);
    setVisibleModal(false);
  };

  const hideModal = () => {
    setVisibleModal(false);
    setRegisterToDisable(0);
  };

  const renderCard = () =>
    cashierAccounts.map((account) => {
      const country = COUNTRY_OPTIONS.filter((c) => c.iso === account.iso);

      return (
        <Card
          key={account.id}
          flag={country[0].flag}
          name={account.bank}
          account={account.accountNumber}
          type={account.accountType}
          currency={account.currency}
          id={account.id}
          onClickEye={handleDetailClick}
          onClickTrash={handleTrashClick}
        />
      );
    });

  const AccountList = () => (
    <Container>
      {loading && <Loader />}
      <CashierAccountDetail
        open={open}
        handleClose={handleClose}
        account={currentAccount}
      />
      <Container info>
        <Title bold>Cuenta bancaria</Title>
        <Container info>
          <Container info>
            <Title bold>{`ID cajero: ${
              cashierAccounts[0] && cashierAccounts[0].cashierId
                ? cashierAccounts[0].cashierId
                : "00000" + selectedCashier
            }`}</Title>
          </Container>
        </Container>
      </Container>
      <MainContainer card>
        {renderCard()}
        <ButtonSection alignRight>
          <AddButton
            dark
            buttonText="Agregar nueva cuenta bancaria"
            onClick={handleOnButtonClick}
          />
        </ButtonSection>
      </MainContainer>
      <Modal
        show={visibleModal}
        title={"¿Estás seguro que deseas hacer la desactivación?"}
        body={""}
        twoOption
        confirmationOption={confirmModalAction}
        cancelOption={hideModal}
      />
    </Container>
  );

  const components = [
    <AccountList />,
    <AccountCashier
      newCashierPress={() => setCurrentComponent(0)}
      cashierNewAccount={selectedCashier}
    />,
  ];

  return components[currentComponent];
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.cashier.loading,
    success: state.cashier.successOnFormAction,
    cashierAccounts: state.cashier.cashierAccounts,
  };
};

export default connect(mapStateTopProps, {
  getCashierAccounts,
  disableCashierAccount,
})(EditAccountCashier);
