import React from "react";
import { Container, Separator, Message } from "./styles";

type Props = {
  message?: string;
};

const NoResult = ({ message }: Props) => (
  <Container>
    <Separator>
      <Message>{message || "No hay resultados"}</Message>
    </Separator>
  </Container>
);

export default NoResult;
