import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../../../common/Table";
import Loader from "../../../common/Loader";
import AddButton from "../../../common/AddButton";
import DateFilter from "../../../common/DateFilter";
import OptionFilter from "../../../common/OptionFilter";
import SearchButton from "../../../common/SearchButton";
import Modal from "../../../common/Modal";
import Edit from "../../../../assets/icons/edit-purple.png";
import Trash from "../../../../assets/icons/trash-can.png";
import {
  CASHIER_TABLE_COLUMNS,
  STATUS_TYPES,
  CASHIER_TYPES,
} from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import {
  getAllCashiers,
  disableCashier,
} from "../../../../ducks/actions/CashierActions";
import { Cashier, CashierRow, CashierType } from "../../../../utils/DataType";
import {
  Container,
  Title,
  TableContainer,
  EditIcon,
  TrashIcon,
  AddButtonContainer,
  FilterContainer,
} from "../styles";

type Props = {
  loading: boolean;
  error: string;
  cashiers: Cashier[];
  cashierRows: CashierRow[];
  newCashierPress: () => void;
  editCashierPress: () => void;
  selectedCashier: (id: number) => void;
  getAllCashiers: (
    status?: string,
    from?: string,
    to?: string,
    cashierType?: string
  ) => void;
  disableCashier: (id: number) => void;
};

const CashierList = ({
  getAllCashiers,
  loading,
  cashierRows,
  cashiers,
  selectedCashier,
  newCashierPress,
  editCashierPress,
  error,
  disableCashier,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [errorModal, setVisibleErrorModal] = useState(false);
  const [registerToDisable, setRegisterToDisable] = useState(0);
  const [cashierStatus, setCashierStatus] = useState({
    value: "",
    label: "",
    id: 0,
  });
  const [cashierType, setCashierType] = useState({
    value: "",
    label: "",
    id: 0,
  });
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    getAllCashiers();
  }, [getAllCashiers]);

  useEffect(() => {
    if (error) {
      setVisibleErrorModal(true);
    }
  }, [error]);

  const handleFromDateChange = (date: any) => {
    if (toDate) {
      setFromDate(date <= toDate ? date : "");
    } else {
      setFromDate(date);
    }
  };

  const handleToDateChange = (date: any) => {
    if (fromDate) {
      setToDate(date >= fromDate ? date : "");
    } else {
      setToDate(date);
    }
  };

  const filteredSearch = () => {
    if (cashierStatus.value || fromDate || toDate || cashierType.value) {
      getAllCashiers(cashierStatus.value, fromDate, toDate, cashierType.value);
    } else {
      getAllCashiers();
    }
  };

  const handleEditClick = (cashier: CashierType) => {
    editCashierPress();
    selectedCashier(cashier.id);
  };

  const handleTrashClick = (cashier: Cashier) => {
    setVisibleModal(true);
    setRegisterToDisable(cashier.id);
  };

  const hideModal = () => {
    setVisibleModal(false);
    setRegisterToDisable(0);
  };
  const confirmModalAction = () => {
    disableCashier(registerToDisable);
    setVisibleModal(false);
  };

  const hideErrorModal = () => {
    setVisibleErrorModal(false);
    setRegisterToDisable(0);
  };
  const renderLoader = () => loading && <Loader />;

  const optionComponents = [<EditIcon src={Edit} />, <TrashIcon src={Trash} />];

  return (
    <Container tableView>
      {renderLoader()}
      <Container info>
        <Title>Cajeros</Title>
      </Container>
      <TableContainer>
        <AddButtonContainer>
          <AddButton buttonText="Nuevo" onClick={newCashierPress} />
        </AddButtonContainer>
        <FilterContainer>
          <OptionFilter
            value={cashierStatus}
            handleFilterChange={(option) => {
              setCashierStatus(option);
            }}
            label={"Estatus"}
            options={STATUS_TYPES}
            clearFilter={() =>
              setCashierStatus({ value: "", label: "", id: 0 })
            }
          />
          <DateFilter
            value={fromDate}
            label={"Desde"}
            clearFilter={() => setFromDate("")}
            handleDateChange={handleFromDateChange}
          />
          <DateFilter
            value={toDate}
            label={"Hasta"}
            clearFilter={() => setToDate("")}
            handleDateChange={handleToDateChange}
          />
          <OptionFilter
            value={cashierType}
            handleFilterChange={(option) => {
              setCashierType(option);
            }}
            label={"Tipo de cajero"}
            options={CASHIER_TYPES}
            clearFilter={() => setCashierType({ value: "", label: "", id: 0 })}
          />
          <SearchButton onClick={filteredSearch} />
        </FilterContainer>
        <Table
          tableColumns={CASHIER_TABLE_COLUMNS}
          dataRows={cashierRows}
          optionComponents={optionComponents}
          onOptionOneClick={handleEditClick}
          onOptionTwoClick={handleTrashClick}
        />
      </TableContainer>
      <Modal
        show={visibleModal}
        title={"¿Estás seguro que deseas hacer la desactivación?"}
        body={""}
        twoOption
        confirmationOption={confirmModalAction}
        cancelOption={hideModal}
      />
      <Modal
        show={errorModal}
        title={""}
        body={"Ha ocurrido un error, inténtalo nuevamente"}
        handleClose={hideErrorModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.cashier.loading,
    error: state.cashier.error,
    cashiers: state.cashier.cashiers,
    cashierRows: state.cashier.cashierRows,
  };
};

export default connect(mapStateTopProps, { getAllCashiers, disableCashier })(
  CashierList
);
