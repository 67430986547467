import { COUNTRY_ACTIONS } from "../actions/CountryActions";
import { Country, CountryRow, AvailableCountry } from "../../utils/DataType";

export type countryStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  countries: [] as Country[],
  countryRows: [] as CountryRow[],
  availableCountries: [] as AvailableCountry[],
  country: {} as Country,
  successOnFormAction: false,
  loading: false,
  error: "",
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case COUNTRY_ACTIONS.FETCH_COUNTRY_LIST_START:
      return { ...state, loading: true };
    case COUNTRY_ACTIONS.FETCH_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload.countries,
        error: "",
        countryRows: action.payload.countryRows,
      };
    case COUNTRY_ACTIONS.FETCH_COUNTRY_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case COUNTRY_ACTIONS.FETCH_ACTIVE_COUNTRIES_START:
      return { ...state, loading: true };
    case COUNTRY_ACTIONS.FETCH_ACTIVE_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        availableCountries: action.payload.availableCountries,
      };
    case COUNTRY_ACTIONS.FETCH_ACTIVE_COUNTRIES_FAIL:
      return { ...state, loading: false, error: action.payload };

    case COUNTRY_ACTIONS.FORM_ACTION_START:
      return { ...state, loading: true };
    case COUNTRY_ACTIONS.FORM_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        successOnFormAction: true,
      };
    case COUNTRY_ACTIONS.FORM_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case COUNTRY_ACTIONS.FETCH_COUNTRY_BY_ID_START:
      return { ...state, loading: true };
    case COUNTRY_ACTIONS.FETCH_COUNTRY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        country: action.payload.country,
      };
    case COUNTRY_ACTIONS.FETCH_COUNTRY_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };

    case COUNTRY_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: action.payload };
    case COUNTRY_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case COUNTRY_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
