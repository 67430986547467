import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../../common/Button";
import Dropdown from "../../../common/Dropdown";
import InputLight from "../../../common/InputLight";
import Modal from "../../../common/Modal";
import Loader from "../../../common/Loader";
import BackButton from "../../../common/BackButton";
import CheckPassword from "../../../common/CheckPassword";
import {
  TREASURER_TYPES,
  ROLES_CREATED_BY_SUPERUSER,
  CELUPAGO_USER_ROLE,
} from "../../../../utils/ConstHelper";
import {
  validateNames,
  validatePassword,
  validateEmail,
  validateBlanks,
} from "../../../../utils/Validators";
import { globalStateType } from "../../../../ducks/reducers";
import {
  createNewCelupagoUser,
  cleanCelupagoUserState,
} from "../../../../ducks/actions/CelupagoUsersActions";
import {
  Container,
  MainContainer,
  Title,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  currentUserRole: string;
  loading: boolean;
  success: boolean;
  error: string;
  newUserPress: () => void;
  cleanCelupagoUserState: () => void;
  createNewCelupagoUser: (
    role: string,
    name: string,
    lastName: string,
    email: string,
    password: string
  ) => void;
};

const NewUser = ({
  newUserPress,
  currentUserRole,
  createNewCelupagoUser,
  cleanCelupagoUserState,
  loading,
  success,
  error,
}: Props) => {
  const [showHelpPassword, setShowHelpPassword] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [role, setRole] = useState("");
  const [name, setName] = useState({ value: "", error: false });
  const [lastName, setLastName] = useState({ value: "", error: false });
  const [email, setEmail] = useState({ value: "", error: false });
  const [password, setPassword] = useState({ value: "", error: false });

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const hideModal = () => {
    setVisibleModal(false);
    cleanCelupagoUserState();
    newUserPress();
  };
  const handleOnButtonClick = () => {
    createNewCelupagoUser(
      role,
      name.value,
      lastName.value,
      email.value,
      password.value
    );
  };

  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setName({ ...name, value });
    }
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setLastName({ ...lastName, value });
    }
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    if (!validateBlanks(value)) {
      setEmail({ ...email, value });
      handleEmailOnBlur();
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!validateBlanks(value) && value.charAt(0) !== " ") {
      if (validatePassword(value))
        setPassword({ ...password, value, error: false });
      else setPassword({ ...password, value, error: true });
    }
  };

  const handleEmailOnBlur = () => {
    if (validateEmail(email.value))
      setEmail((email) => ({ ...email, error: false }));
    else setEmail((email) => ({ ...email, error: true }));
  };

  const handlePasswordOnBlur = () => {
    if (validatePassword(password.value))
      setPassword((password) => ({ ...password, error: false }));
    else setPassword((password) => ({ ...password, error: true }));
  };

  const handleNameOnBlur = () => {
    if (name.value && name.value.length >= 3)
      setName({ ...name, error: false });
    else setName({ ...name, error: true });
  };
  const handleLastNameOnBlur = () => {
    if (lastName.value && lastName.value.length >= 3)
      setLastName({ ...lastName, error: false });
    else setLastName({ ...lastName, error: true });
  };

  const renderLoader = () => loading && <Loader />;

  const enableButton = () => {
    if (
      role &&
      name.value &&
      lastName.value &&
      password.value &&
      email.value &&
      !name.error &&
      !lastName.error &&
      !email.error &&
      !password.error
    )
      return false;
    else return true;
  };

  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={newUserPress} />
      </BackButtonContainer>
      <Container info>
        <Title bold>Nuevo usuario</Title>
        <Container roleContainer>
          <Title light>Rol</Title>
          <Dropdown
            id={"role"}
            styles={"role"}
            options={
              currentUserRole === CELUPAGO_USER_ROLE[0].value
                ? ROLES_CREATED_BY_SUPERUSER
                : TREASURER_TYPES
            }
            value={role}
            onChange={handleRoleChange}
          />
        </Container>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre</Title>
            <InputLight
              id="name"
              type="text"
              value={name.value}
              onChange={handleNameChange}
              error={name.error}
              onBlur={handleNameOnBlur}
              errorMessage={
                name.error && name.value.length > 0 && name.value.length < 3
                  ? "El campo debe contener mínimo 3 caracteres"
                  : name.error && name.value.length === 0
                  ? "El campo no puede estar vacío"
                  : ""
              }
              maxLength={20}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Apellido</Title>
            <InputLight
              id="lastName"
              type="text"
              value={lastName.value}
              onChange={handleLastNameChange}
              error={lastName.error}
              onBlur={handleLastNameOnBlur}
              errorMessage={
                lastName.error &&
                lastName.value.length > 0 &&
                lastName.value.length < 3
                  ? "El campo debe contener mínimo 3 caracteres"
                  : lastName.error && lastName.value.length === 0
                  ? "El campo no puede estar vacío"
                  : ""
              }
              maxLength={20}
              minLength={3}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Correo electrónico</Title>
            <InputLight
              id="email"
              type="text"
              value={email.value}
              onChange={handleEmailChange}
              onBlur={handleEmailOnBlur}
              errorMessage={
                email.error && email.value.length > 0
                  ? "La dirección de correo no es válida"
                  : email.error && email.value.length === 0
                  ? "El campo no puede estar vacío"
                  : ""
              }
              error={email.error}
              maxLength={40}
              minLength={6}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Contraseña</Title>
            <InputLight
              id="password"
              type="text"
              value={password.value}
              onFocus={() => setShowHelpPassword(true)}
              onBlur={() => {
                handlePasswordOnBlur();
                setShowHelpPassword(false);
              }}
              onChange={handlePasswordChange}
              errorMessage={
                password.error && password.value.length > 0
                  ? "Contraseña inválida"
                  : password.error && password.value.length === 0
                  ? "El campo no puede estar vacío"
                  : ""
              }
              error={password.error}
              maxLength={8}
              minLength={8}
            />
          </ContainerData>
          {showHelpPassword && <CheckPassword password={password.value} />}
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Registro exitoso!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    currentUserRole: state.user.role,
    loading: state.celupagoUser.loading,
    success: state.celupagoUser.successOnFormAction,
    error: state.celupagoUser.error,
  };
};

export default connect(mapStateTopProps, {
  createNewCelupagoUser,
  cleanCelupagoUserState,
})(NewUser);
