import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../../../common/Table";
import Loader from "../../../common/Loader";
import AddButton from "../../../common/AddButton";
import DateFilter from "../../../common/DateFilter";
import OptionFilter from "../../../common/OptionFilter";
import SearchButton from "../../../common/SearchButton";
import Modal from "../../../common/Modal";
import Edit from "../../../../assets/icons/edit-purple.png";
import Trash from "../../../../assets/icons/trash-can.png";
import {
  STATUS_TYPES,
  USER_TABLE_COLUMNS,
} from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import {
  CelupagoUserRow,
  CelupagoUser,
  UserType,
} from "../../../../utils/DataType";
import {
  getAllCelupagoUsers,
  disableUser,
} from "../../../../ducks/actions/CelupagoUsersActions";
import {
  Container,
  Title,
  TableContainer,
  AddButtonContainer,
  FilterContainer,
  EditIcon,
  TrashIcon,
} from "../styles";

type Props = {
  loading: boolean;
  error: string;
  celupagoUserRows: CelupagoUserRow[];
  celupagoUsers: CelupagoUser[];
  newUserPress: () => void;
  editUserPress: () => void;
  selectedUser: (id: number) => void;
  getAllCelupagoUsers: (status?: string, from?: string, to?: string) => void;
  disableUser: (id: number) => void;
};

const UserList = ({
  loading,
  error,
  celupagoUserRows,
  newUserPress,
  editUserPress,
  getAllCelupagoUsers,
  selectedUser,
  disableUser,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [errorModal, setVisibleErrorModal] = useState(false);
  const [registerToDisable, setRegisterToDisable] = useState(0);
  const [userStatus, setUserStatus] = useState({ value: "", label: "", id: 0 });
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    getAllCelupagoUsers();
  }, [getAllCelupagoUsers]);

  useEffect(() => {
    if (error) {
      setVisibleErrorModal(true);
    }
  }, [error]);

  const handleEditClick = (user: CelupagoUser) => {
    editUserPress();
    selectedUser(user.id);
  };

  const handleTrashClick = (user: UserType) => {
    setVisibleModal(true);
    setRegisterToDisable(user.id);
  };

  const hideModal = () => {
    setVisibleModal(false);
    setRegisterToDisable(0);
  };
  const confirmModalAction = () => {
    disableUser(registerToDisable);
    setVisibleModal(false);
  };

  const hideErrorModal = () => {
    setVisibleErrorModal(false);
    setRegisterToDisable(0);
  };

  const handleFromDateChange = (date: any) => {
    if (toDate) {
      setFromDate(date <= toDate ? date : "");
    } else {
      setFromDate(date);
    }
  };

  const handleToDateChange = (date: any) => {
    if (fromDate) {
      setToDate(date >= fromDate ? date : "");
    } else {
      setToDate(date);
    }
  };

  const filteredSearch = () => {
    if (userStatus.value || fromDate || toDate) {
      getAllCelupagoUsers(userStatus.value, fromDate, toDate);
    } else {
      getAllCelupagoUsers();
    }
  };

  const renderLoader = () => loading && <Loader />;

  const optionComponents = [<EditIcon src={Edit} />, <TrashIcon src={Trash} />];

  return (
    <Container tableView>
      {renderLoader()}
      <Container info>
        <Title>Usuarios</Title>
      </Container>
      <TableContainer>
        <AddButtonContainer>
          <AddButton buttonText="Nuevo" onClick={newUserPress} />
        </AddButtonContainer>
        <FilterContainer>
          <OptionFilter
            value={userStatus}
            handleFilterChange={(option) => {
              setUserStatus(option);
            }}
            label={"Estatus"}
            options={STATUS_TYPES}
            clearFilter={() => setUserStatus({ value: "", label: "", id: 0 })}
          />
          <DateFilter
            value={fromDate}
            label={"Desde"}
            clearFilter={() => setFromDate("")}
            handleDateChange={handleFromDateChange}
          />
          <DateFilter
            value={toDate}
            label={"Hasta"}
            clearFilter={() => setToDate("")}
            handleDateChange={handleToDateChange}
          />

          <SearchButton onClick={filteredSearch} />
        </FilterContainer>
        <Table
          tableColumns={USER_TABLE_COLUMNS}
          dataRows={celupagoUserRows}
          optionComponents={optionComponents}
          onOptionOneClick={handleEditClick}
          onOptionTwoClick={handleTrashClick}
        />
      </TableContainer>
      <Modal
        show={visibleModal}
        title={"¿Estás seguro que deseas hacer la desactivación?"}
        body={""}
        twoOption
        confirmationOption={confirmModalAction}
        cancelOption={hideModal}
      />
      <Modal
        show={errorModal}
        title={""}
        body={"Ha ocurrido un error, inténtalo nuevamente"}
        handleClose={hideErrorModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.celupagoUser.loading,
    error: state.celupagoUser.error,
    celupagoUserRows: state.celupagoUser.celupagoUserRows,
    celupagoUsers: state.celupagoUser.celupagoUsers,
  };
};

export default connect(mapStateTopProps, { getAllCelupagoUsers, disableUser })(
  UserList
);
