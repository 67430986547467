import React from "react";
import {
  validateAlphanumeric,
  validateNumeric,
  validateCharacterSpecial,
} from "../../../utils/Validators";
import UnCheckCircleIcon from "../../../assets/icons/unCheck-circle.png";
import CheckCircleIcon from "../../../assets/icons/check-circle.png";
import { ContainerData, LogoCheckCircle, HelpContainer, Title } from "./styles";

type Props = { password: string; profile?: boolean };

const CheckPassword = ({ password, profile }: Props) => {
  const validAlphanumeric = validateAlphanumeric(password);
  const validNumeric = validateNumeric(password);
  const validCharacterSpecial = validateCharacterSpecial(password);
  const validLength = password.length === 8;
  return (
    <ContainerData profile={profile} data-testid="checkPassword-container">
      <HelpContainer>
        <LogoCheckCircle
          src={
            validAlphanumeric && validNumeric
              ? CheckCircleIcon
              : UnCheckCircleIcon
          }
        />
        <Title>Alfanuméricos</Title>
      </HelpContainer>
      <HelpContainer>
        <LogoCheckCircle
          src={validCharacterSpecial ? CheckCircleIcon : UnCheckCircleIcon}
        />
        <Title>Carácter especial . , / * # -</Title>
      </HelpContainer>
      <HelpContainer>
        <LogoCheckCircle
          src={validLength ? CheckCircleIcon : UnCheckCircleIcon}
        />
        <Title>Longitud 8 caracteres</Title>
      </HelpContainer>
    </ContainerData>
  );
};

export default CheckPassword;
