import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { globalStateType } from "../../../ducks/reducers";
import Container from "../../common/Container";
import Input from "../../common/Input";
import Loader from "../../common/Loader";
import Button from "../../common/Button";
import LogoImg from "../../../assets/img/logo.png";
import CheckMark from "../../../assets/icons/check-circle-yellow.png";
import UnCheckMark from "../../../assets/icons/unCheck-circle.png";
import { login } from "../../../ducks/actions/UserActions";
import {
  Header,
  Logo,
  TitleContainer,
  Title,
  InputSection,
  RememberSection,
  RememberText,
  Check,
  ButtonSection,
  ErrorContainer,
  ErrorText,
} from "./styles";

type Props = {
  login: (email: string, password: string, navigateTo: any) => void;
  loading: boolean;
  error: string;
};

const Login = ({ loading, login, error }: Props) => {
  const history = useHistory();

  const rememberedEmail = localStorage.getItem("email");
  const [email, changeEmail] = useState(rememberedEmail ? rememberedEmail : "");
  const [password, changePassword] = useState("");
  const [visiblePassword, setPasswordVisible] = useState(false);
  const [checkRememberMe, changeRememberMeValue] = useState(
    rememberedEmail ? true : false
  );

  const navigateTo = () => {
    history.replace("/home");
  };

  const handleLogin = () => {
    login(email, password, navigateTo);
  };

  const rememberUserEmail = () => {
    if (checkRememberMe) {
      changeRememberMeValue(false);
      localStorage.removeItem("email");
    } else if (email.length > 3) {
      changeRememberMeValue(true);
      localStorage.setItem("email", email);
    } else {
    }
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container darkTheme>
      <Header>
        <Logo src={LogoImg} />
      </Header>
      {renderLoader()}
      <TitleContainer>
        <Title>Inicio de sesión</Title>
      </TitleContainer>
      <InputSection>
        <Input
          type="text"
          maxLength={40}
          minLength={6}
          value={email}
          onChangeValue={(event) =>
            changeEmail(event.target.value.toLowerCase())
          }
          placeholder="Email"
        />
        <Input
          security
          type="password"
          maxLength={8}
          minLength={8}
          value={password}
          visible={visiblePassword}
          onChangeValue={(event) => changePassword(event.target.value)}
          onClick={() => setPasswordVisible(!visiblePassword)}
          placeholder="Contraseña"
        />
      </InputSection>
      <RememberSection>
        <Check
          src={checkRememberMe ? CheckMark : UnCheckMark}
          onClick={rememberUserEmail}
        />
        <RememberText>Recuérdame</RememberText>
      </RememberSection>
      <ErrorContainer>
        <ErrorText>{error}</ErrorText>
      </ErrorContainer>
      <ButtonSection>
        <Button
          buttonText="INGRESAR"
          disabled={!(email && password && password.length === 8)}
          onClick={handleLogin}
        />
      </ButtonSection>
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return { loading: state.user.loading, error: state.user.error };
};

export default connect(mapStateTopProps, { login })(Login);
