import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 40px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.buttonText &&
    css`
      width: 70px;
    `};

  ${(props) =>
    props.wide &&
    css`
      margin-left: 40px;
    `};
`;

export const BackIcon = styled.img`
  width: 12.7px;
  height: 12.7px;
  object-fit: contain;
`;

export const ButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  color: ${Theme.colors.colorSecondary};
`;
