import React, { ReactElement, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Refresh from "../../../assets/icons/refresh.png";
import NoResult from "../NoResults";
import { OptionIconContainer, RefreshIcon, ContentRefresh } from "./styles";

type Props = {
  tableColumns: { title: string }[];
  dataRows: any;
  optionComponents: any;
  onOptionOneClick?: (row: any) => void;
  onOptionTwoClick?: (row: any) => void;
  refresh?: () => void;
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    marginTop: 20,
    backgroundColor: "#b2aeae",
    color: "#000000",
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
  },
  body: {
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    width: "100%",
  },
});

const TableList = ({
  tableColumns,
  dataRows,
  optionComponents,
  onOptionOneClick,
  onOptionTwoClick,
  refresh,
}: Props): ReactElement => {
  const [page, setPage] = useState(0);
  const classes = useStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const formatedRows = dataRows.map((row: any) => {
    return Object.entries(row);
  });

  return (
    <>
      {dataRows.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <StyledTableCell key={`${column.title}`} align="center">
                    {column.title}
                  </StyledTableCell>
                ))}
                {refresh ? (
                  <StyledTableCell key={"refresh"} align="center">
                    <ContentRefresh onClick={refresh}>
                      <RefreshIcon src={Refresh} />
                    </ContentRefresh>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center"> </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {formatedRows
                .slice(page * 6, page * 6 + 6)
                .map((row: any, rowIndex: number) => (
                  <StyledTableRow key={rowIndex + 1}>
                    {row
                      .filter((entry: any) => entry[0] !== "id")
                      .map((dataRow: any, dataIndex: number) => (
                        <StyledTableCell
                          align="center"
                          key={`${rowIndex + 1},${dataIndex + 1}`}
                        >
                          {dataRow[1]}
                        </StyledTableCell>
                      ))}
                    <StyledTableCell align="center">
                      <OptionIconContainer>
                        {optionComponents.map(
                          (component: any, cIdx: number) => (
                            <div
                              key={`${rowIndex + 1},${rowIndex + 1},${
                                cIdx + 1
                              }`}
                              onClick={() =>
                                cIdx === 0 && onOptionOneClick
                                  ? onOptionOneClick(Object.fromEntries(row))
                                  : cIdx === 1 && onOptionTwoClick
                                  ? onOptionTwoClick(Object.fromEntries(row))
                                  : null
                              }
                            >
                              {component}
                            </div>
                          )
                        )}
                      </OptionIconContainer>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={formatedRows.length}
            rowsPerPage={6}
            page={page}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : "más de" + to}`
            }
            onChangePage={handleChangePage}
          />
        </TableContainer>
      ) : (
        <NoResult />
      )}
    </>
  );
};

export default TableList;
