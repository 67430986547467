import React, { ReactElement, useState } from "react";
import Clear from "../../../assets/icons/close-x.png";
import UnCheck from "../../../assets/icons/rectangle.png";
import Check from "../../../assets/icons/checked-rectangle.png";
import {
  MainFilterContainer,
  LabelContainer,
  LabelTextContainer,
  Label,
  ClearContainer,
  ClearImage,
  OptionListContainer,
  OptionRowContainer,
  OptionLabelContainer,
  OptionLabel,
  CheckIconContainer,
  CheckedIcon,
} from "./styles";

type Props = {
  value?: any;
  handleFilterChange: (value: any) => void;
  clearFilter: () => void;
  label: string;
  options: any;
};

const OptionFilter = ({
  value,
  handleFilterChange,
  clearFilter,
  label,
  options,
}: Props): ReactElement => {
  const [showOptions, displayOptions] = useState(false);
  return (
    <MainFilterContainer>
      <LabelContainer>
        <LabelTextContainer onClick={() => displayOptions(!showOptions)}>
          <Label>{(value && value.label) || label}</Label>
        </LabelTextContainer>
        <ClearContainer>
          {value && value.value && (
            <ClearImage src={Clear} alt="x" onClick={clearFilter} />
          )}
        </ClearContainer>
      </LabelContainer>
      {showOptions && (
        <OptionListContainer>
          {options.map((option: any, index: number) => (
            <OptionRowContainer
              key={index}
              onClick={() => {
                handleFilterChange(option);
                displayOptions(!showOptions);
              }}
            >
              <OptionLabelContainer>
                <OptionLabel>{option.label}</OptionLabel>
              </OptionLabelContainer>
              <CheckIconContainer>
                <CheckedIcon
                  src={value && value.label === option.label ? Check : UnCheck}
                  alt="check"
                />
              </CheckIconContainer>
            </OptionRowContainer>
          ))}
        </OptionListContainer>
      )}
    </MainFilterContainer>
  );
};

export default OptionFilter;
