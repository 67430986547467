import moment from "moment";
import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";
import { dispatchUnauthorizedError } from "./UserActions";
import {
  getCelupagoUserRowData,
  getCelupagoUser,
} from "../../utils/Formatters";

const base = "CELUPAGO_USER_ACTIONS_";
const requestManager = new RequestManager();

export const CELUPAGO_USER_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",

  FETCH_CELUPAGO_USER_LIST_START: base + "FETCH_CELUPAGO_USER_LIST_START",
  FETCH_CELUPAGO_USER_ACTIONS_SUCCESS:
    base + "FETCH_CELUPAGO_USER_ACTIONS_SUCCESS",
  FETCH_CELUPAGO_USER_ACTIONS_FAIL: base + "FETCH_CELUPAGO_USER_ACTIONS_FAIL",

  FETCH_CELUPAGO_USER_BY_ID_START: base + "FETCH_CELUPAGO_USER_BY_ID_START",
  FETCH_CELUPAGO_USER_BY_ID_SUCCESS: base + "FETCH_CELUPAGO_USER_BY_ID_SUCCESS",
  FETCH_CELUPAGO_USER_BY_ID_FAIL: base + "FETCH_CELUPAGO_USER_BY_ID_FAIL",

  CELUPAGO_USER_EDIT_ACTION_START: base + "CELUPAGO_USER_EDIT_ACTION_START",
  CELUPAGO_USER_EDIT_ACTION_SUCCESS: base + "CELUPAGO_USER_EDIT_ACTION_SUCCESS",
  CELUPAGO_USER_EDIT_ACTION_FAIL: base + "CELUPAGO_USER_EDIT_ACTION_FAIL",

  FORM_ACTION_START: base + "FORM_ACTION_START",
  FORM_ACTION_SUCCESS: base + "FORM_ACTION_SUCCESS",
  FORM_ACTION_FAIL: base + "FORM_ACTION_FAIL",
};

export const getAllCelupagoUsers = (
  status?: string,
  from?: string,
  to?: string
) => {
  return (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_LIST_START });
    let url = `/celupago-user?`;
    if (status) {
      url = url + `&status=${status}`;
    }

    if (from && !to) {
      const maxDate = moment().format().split("T")[0];
      url = url + `&from=${from}&to=${maxDate}`;
    } else {
      if (from) {
        url = url + `&from=${from}`;
      }
      if (to) {
        url = url + `&to=${to}`;
      }
    }
    requestManager
      .get(url, {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        const celupagoUsersUnformatted = response.data;
        const celupagoUsers = celupagoUsersUnformatted.map(
          (celupagoUser: any) => {
            return getCelupagoUser(celupagoUser);
          }
        );
        const celupagoUserRows = celupagoUsersUnformatted.map(
          (celupagoUser: any) => {
            return getCelupagoUserRowData(celupagoUser);
          }
        );

        dispatch({
          type: CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_ACTIONS_SUCCESS,
          payload: { celupagoUsers, celupagoUserRows },
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        if (errorMessage.error === "UNAUTHORIZED") {
          dispatch(dispatchUnauthorizedError());
        } else {
          dispatch({
            type: CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_ACTIONS_FAIL,
            payload: { errorMessage: errorMessage.message },
          });
        }
      });
  };
};

export const createNewCelupagoUser = (
  role: string,
  name: string,
  lastName: string,
  email: string,
  password: string
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { id } = state.user;
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: CELUPAGO_USER_ACTIONS.FORM_ACTION_START });
    const data = {
      role,
      name,
      lastName,
      email,
      password,
      createdBy: id,
    };
    try {
      await requestManager.post(`celupago-user/create`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch({ type: CELUPAGO_USER_ACTIONS.FORM_ACTION_SUCCESS });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CELUPAGO_USER_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const cleanCelupagoUserState = () => {
  return {
    type: CELUPAGO_USER_ACTIONS.CLEAN_STATE,
  };
};

export const disableUser = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: CELUPAGO_USER_ACTIONS.FORM_ACTION_START });

    const data = {
      status: "INACTIVE",
    };
    try {
      await requestManager.patch(`celupago-user/${id}/status`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch(getAllCelupagoUsers());
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CELUPAGO_USER_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getCelupagoUserId = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_BY_ID_START });
    let url = `/celupago-user/${id}`;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });

      const celupagoUserFormatted = getCelupagoUser(response.data);

      dispatch({
        type: CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_BY_ID_SUCCESS,
        payload: { celupagoUser: celupagoUserFormatted },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_BY_ID_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const editCelupagoUser = (
  id: number,
  newCelupagoUserType: string,
  newCelupagoUserStatus: string
) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CELUPAGO_USER_ACTIONS.CELUPAGO_USER_EDIT_ACTION_START });

    const data = {
      role: newCelupagoUserType,
      userStatus: newCelupagoUserStatus,
    };
    try {
      await requestManager.put(`/celupago-user/${id}/update`, data, {
        Authorization: `Bearer ${accessToken}`,
      });

      dispatch({
        type: CELUPAGO_USER_ACTIONS.CELUPAGO_USER_EDIT_ACTION_SUCCESS,
        payload: {},
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CELUPAGO_USER_ACTIONS.CELUPAGO_USER_EDIT_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};
