import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { ChartContainer } from "./styles";

type Props = {
  options: [] | any;
  data: [] | any;
  title: string;
};

const Rechart = ({ options, data, title }: Props) => {
  const renderLine = (options: any) => {
    return options.map((option: any) => (
      <Line
        key={option.key}
        type="monotone"
        dataKey={option.key}
        stroke={option.color}
      />
    ));
  };
  return (
    <ChartContainer>
      {/* <Title>{title}</Title> */}
      <LineChart
        width={480}
        height={250}
        data={data}
        margin={{ top: 15, right: 5, left: -20, bottom: 15 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {renderLine(options)}
      </LineChart>
    </ChartContainer>
  );
};

export default Rechart;
