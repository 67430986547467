export const Theme = {
  colors: {
    colorPrimary: "#1d1d1d",
    colorSecondary: "#7234e9", //Purple
    colorLight: "#ebff00",
    backgroundDisable: "#dddddd", //Gray
    gray2: "#E4E4E4",
    gray3: "#818181",
    colorDisabled: "#ffffff", //white
    filterBackground: "rgba(209, 206, 206, 0.3)",
    filterLabel: "#262734",
    filterOptionLabel: "#23232d",
    filterRow: "#d1cece",
    black: "black",
  },
  fonts: {
    fontPrimary: "Exo 2",
    fontSecondary: "sans-serif",
  },
};
