import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../../common/Button";
import Dropdown from "../../../common/Dropdown";
import InputLight from "../../../common/InputLight";
import Loader from "../../../common/Loader";
import Modal from "../../../common/Modal";
import { ACCOUNT_TYPES } from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import {
  AvailableCountry,
  NewlyCashier,
  BankOption,
} from "../../../../utils/DataType";
import { getAllBanksByCountry } from "../../../../ducks/actions/BankActions";
import {
  createCashierBankAccount,
  cleanCashierState,
  getCashierToAddAccount,
} from "../../../../ducks/actions/CashierActions";
import {
  validateNames,
  validateNumber,
  validateEmail,
} from "../../../../utils/Validators";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  ContainerDropdown,
} from "../styles";

type Props = {
  loading: boolean;
  availableCountries: AvailableCountry[];
  cashier: NewlyCashier;
  success: boolean;
  error: string;
  banksAvailable: BankOption[];
  cashierNewAccount?: number;
  newCashierPress: () => void;
  cleanCashierState: () => void;
  getCashierToAddAccount: (id: number) => void;
  getAllBanksByCountry: (status?: string, country?: string) => void;
  createCashierBankAccount: (
    account_holder: string,
    document: string,
    doc_type: string,
    email: string,
    account_number: string,
    bank: number,
    account_type: string
  ) => void;
};

const AccountCashier = ({
  loading,
  cashier,
  availableCountries,
  success,
  error,
  banksAvailable,
  newCashierPress,
  cleanCashierState,
  getAllBanksByCountry,
  createCashierBankAccount,
  cashierNewAccount,
  getCashierToAddAccount,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [docType, setDocType] = useState("");
  const [bank, setBank] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountHolder, setAccountHolder] = useState({
    value: "",
    error: false,
  });
  const [email, setEmail] = useState({ value: "", error: false });
  const [document, setDocument] = useState({ value: "", error: false });
  const [accountNumber, setAccountNumber] = useState({
    value: "",
    error: false,
  });

  const isoCode = cashier && cashier.country && cashier.country.iso;

  useEffect(() => {
    if (cashierNewAccount) {
      getCashierToAddAccount(cashierNewAccount);
    } else {
      if (isoCode) getAllBanksByCountry("ACTIVE", isoCode);
    }
  }, [
    cashierNewAccount,
    getAllBanksByCountry,
    getCashierToAddAccount,
    isoCode,
  ]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const handleDocTypeChange = (event: any) => {
    setDocType(event.target.value);
  };

  const handleBankChange = (event: any) => {
    setBank(event.target.value);
  };

  const handleAccountTypeChange = (event: any) => {
    setAccountType(event.target.value);
  };

  const handleAccountHolderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNames(value)) setAccountHolder({ ...accountHolder, value });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail({ ...email, value });
    handleEmailOnBlur();
  };

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (validateNumber(value)) {
      setDocument({ ...document, value });
    } else {
      if (value.length === 0) setDocument({ ...document, value });
    }
  };
  const handleAccountNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNumber(value)) {
      setAccountNumber({ ...accountNumber, value });
    } else {
      if (value.length === 0) setAccountNumber({ ...accountNumber, value });
    }
  };

  const handleAccountHolderOnBlur = () => {
    if (accountHolder.value)
      setAccountHolder({ ...accountHolder, error: false });
    else setAccountHolder({ ...accountHolder, error: true });
  };

  const handleEmailOnBlur = () => {
    if (validateEmail(email.value))
      setEmail((email) => ({ ...email, error: false }));
    else setEmail((email) => ({ ...email, error: true }));
  };

  const handleDocumentOnBlur = () => {
    if (document.value) setDocument({ ...document, error: false });
    else setDocument({ ...document, error: true });
  };

  const handleAccountNumberOnBlur = () => {
    if (accountNumber.value)
      setAccountNumber({ ...accountNumber, error: false });
    else setAccountNumber({ ...accountNumber, error: true });
  };

  const getDocType = (doc: string) => {
    const cashierCountry = availableCountries.filter(
      (co) => co.id === cashier.country.id
    );
    const docToReturn = cashierCountry[0].docTypes.filter(
      (d) => (d.value = Number(doc))
    );
    return docToReturn[0].label;
  };

  const hideModal = () => {
    setVisibleModal(false);
    cleanCashierState();
    newCashierPress();
  };
  const handleOnButtonClick = () => {
    createCashierBankAccount(
      accountHolder.value,
      document.value,
      getDocType(docType),
      email.value,
      accountNumber.value,
      Number(bank),
      accountType
    );
  };

  const enableButton = () => {
    if (
      bank &&
      accountType &&
      docType &&
      accountHolder.value &&
      email.value &&
      document.value &&
      accountNumber.value &&
      !accountHolder.error &&
      !email.error &&
      !document.error &&
      !accountNumber.error
    )
      return false;
    else return true;
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container>
      {renderLoader()}
      <Container info>
        <Title bold>Datos de cuenta bancaria</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Titular de la cuenta</Title>
            <InputLight
              id="name"
              type="text"
              value={accountHolder.value}
              onChange={handleAccountHolderChange}
              error={accountHolder.error}
              onBlur={handleAccountHolderOnBlur}
              errorMessage={
                accountHolder.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={20}
              minLength={5}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Correo electrónico</Title>
            <InputLight
              id="email"
              type="text"
              value={email.value}
              onChange={handleEmailChange}
              error={email.error}
              onBlur={handleEmailOnBlur}
              errorMessage={
                email.error ? "La dirección de correo no es válida" : ""
              }
              maxLength={40}
              minLength={6}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Documento de identidad</Title>
            <ContainerDropdown>
              <Dropdown
                id={"documentType"}
                styles={"small"}
                options={
                  availableCountries && cashier && cashier.country
                    ? availableCountries.find(
                        (option) => option.id === cashier.country.id
                      )?.docTypes
                    : []
                }
                value={docType}
                onChange={handleDocTypeChange}
              />
              <InputLight
                id="document"
                type="text"
                styles={"small"}
                value={document.value}
                onChange={handleDocumentChange}
                error={document.error}
                onBlur={handleDocumentOnBlur}
                errorMessage={
                  document.error ? "El campo no puede estar vacío" : ""
                }
                maxLength={15}
                minLength={8}
              />
            </ContainerDropdown>
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Banco</Title>
            <Dropdown
              id={"bank"}
              options={banksAvailable.length > 0 ? banksAvailable : []}
              value={bank}
              onChange={handleBankChange}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tipo de cuenta</Title>
            <Dropdown
              id={"accountType"}
              options={ACCOUNT_TYPES}
              value={accountType}
              onChange={handleAccountTypeChange}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nº de cuenta</Title>
            <InputLight
              id="accountNumber"
              type="text"
              value={accountNumber.value}
              onChange={handleAccountNumberChange}
              error={accountNumber.error}
              onBlur={handleAccountNumberOnBlur}
              errorMessage={
                accountNumber.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={20}
              minLength={13}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Registro exitoso!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    cashier: state.cashier.newlyCashier,
    loading: state.cashier.loading,
    error: state.cashier.error,
    availableCountries: state.country.availableCountries,
    success: state.cashier.successOnFormAction,
    banksAvailable: state.bank.banksAvailable,
  };
};

export default connect(mapStateTopProps, {
  getAllBanksByCountry,
  createCashierBankAccount,
  cleanCashierState,
  getCashierToAddAccount,
})(AccountCashier);
