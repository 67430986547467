import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90%;
  width: 95%;
  ${(props) =>
    props.tableView &&
    css`
      justify-content: flex-start;
      align-items: center;
      height: auto;
    `};
  ${(props) =>
    props.info &&
    css`
      justify-content: flex-start;
      align-items: flex-start;
      height: auto;
    `};
  ${(props) =>
    props.roleContainer &&
    css`
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 10px;
      margin-top: 10px;
      height: auto;
    `};

  ${(props) =>
    props.idCelupagos &&
    css`
      width: 80%;
      margin-left: 0px;
      margin-top: 0px;
    `};
`;

export const Title = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold;
  ${(props) =>
    props.light &&
    css`
      font-weight: normal;
    `};
  ${(props) =>
    props.subTitle &&
    css`
      font-size: 14px;
    `};
`;

export const PurpleTitle = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  f font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  margin: 10px;
  color:${Theme.colors.colorSecondary};
  ${(props) =>
    props.subTitle &&
    css`
      font-size: 12px;
    `};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  ${(props) =>
    props.card &&
    css`
      justify-content: space-between;
      flex-wrap: wrap;
    `};
`;

export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  ${(props) =>
    props.input &&
    css`
      flex-direction: row;
      min-height: 45px;
      align-items: flex-start;
      justify-content: space-between;
      width: 85%;
    `};
  ${(props) =>
    props.infoLine &&
    css`
      flex-direction: row;
      min-height: 45px;
      align-items: center;
      justify-content: flex-start;
      width: 85%;
    `};
`;

export const ContainerDropdown = styled.div`
  display: flex;
  width: 245px;
  justify-content: space-between;
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.alignRight &&
    css`
      align-items: flex-end;
    `};
`;

export const TableContainer = styled.div`
  width: 97%;
  display: flex;
  flex-direction: column;
`;
export const EditIcon = styled.img`
  width: 14.1px;
  height: 12.5px;
  object-fit: contain;
  cursor: pointer;
`;

export const TrashIcon = styled.img`
  width: 10.8px;
  height: 12.3px;
  object-fit: contain;
  cursor: pointer;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
export const BackButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;
