import React, { ReactElement } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "../components/auth-views/Home";
import Login from "../components/public-views/LogIn";
import "./Main.css";

type Props = {};

const history = createBrowserHistory();

const getAuthView = (Component: any) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    return Component;
  } else {
    return <Redirect to="/" />;
  }
};

const getUnAuthView = (Component: any) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    return <Redirect to="/home" />;
  } else {
    return Component;
  }
};

const AppRouter = (): ReactElement => {
  return (
    <Router history={history}>
      <React.Fragment>
        <div className="container">
          <Switch>
            <Route exact path="/" render={() => getUnAuthView(<Login />)} />
            <Route exact path="/home" render={() => getAuthView(<Home />)} />
            <Route
              exact
              path="/operations"
              render={() => getAuthView(<Home component={7} />)}
            />
          </Switch>
        </div>
      </React.Fragment>
    </Router>
  );
};

export default AppRouter;
