import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const DialogTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResumeTitle = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${Theme.colors.filterOptionLabel};
`;

export const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 570px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
`;

export const DataLabel = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: ${Theme.colors.filterOptionLabel};
  margin-left: 45px;
  ${(props) =>
    props.highlightColor &&
    css`
      color: ${Theme.colors.colorSecondary};
    `};
`;

export const DataValue = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: ${Theme.colors.filterOptionLabel};
  margin-right: 60px;
  ${(props) =>
    props.highlightColor &&
    css`
      color: ${Theme.colors.colorSecondary};
    `};
`;
