import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LogoImg from "../../../assets/icons/user-circle-purple.png";
import EditIcon from "../../../assets/icons/edit-purple.png";
import EyeOffIcon from "../../../assets/icons/eye.png";
import EyeOnIcon from "../../../assets/icons/eye-slash.png";
import Button from "../../common/Button";
import InputLight from "../../common/InputLight";
import CheckPassword from "../../common/CheckPassword";
import Loader from "../../common/Loader";
import Modal from "../../common/Modal";
import { validatePassword } from "../../../utils/Validators";
import { globalStateType } from "../../../ducks/reducers";
import {
  passwordUpdate,
  clearSuccess,
} from "../../../ducks/actions/UserActions";
import {
  Container,
  ContainerProfile,
  LogoProfile,
  ContainerInfo,
  Title,
  MainContainer,
  DataContainer,
  ButtonSection,
  LogoEdit,
} from "./styles";

type Props = {
  email: string;
  name: string;
  lastName: string;
  loading: boolean;
  success: boolean;
  error: string;
  passwordUpdate: (newPassword: string, password: string) => void;
  clearSuccess: () => void;
};

const Profile = ({
  name,
  lastName,
  loading,
  email,
  passwordUpdate,
  success,
  error,
  clearSuccess,
}: Props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(
    false
  );
  const [showHelpPassword, setShowHelpPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState({
    value: "",
    error: false,
  });
  const [newPassword, setNewPassword] = useState({ value: "", error: false });
  const [repeatPassword, setRepeatPassword] = useState({
    value: "",
    error: false,
  });

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const handleCurrentPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validatePassword(value))
      setCurrentPassword({ ...currentPassword, value, error: false });
    else setCurrentPassword({ ...currentPassword, value, error: true });
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validatePassword(value))
      setNewPassword({ ...newPassword, value, error: false });
    else setNewPassword({ ...newPassword, value, error: true });
  };

  const handleRepeatPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validatePassword(value))
      setRepeatPassword({ ...repeatPassword, value, error: false });
    else setRepeatPassword({ ...repeatPassword, value, error: true });
  };

  const handleCurrentPasswordOnBlur = () => {
    if (validatePassword(currentPassword.value))
      setCurrentPassword((currentPassword) => ({
        ...currentPassword,
        error: false,
      }));
    else
      setCurrentPassword((currentPassword) => ({
        ...currentPassword,
        error: true,
      }));
  };

  const handleNewPasswordOnBlur = () => {
    if (validatePassword(newPassword.value))
      setNewPassword((newPassword) => ({
        ...newPassword,
        error: false,
      }));
    else
      setNewPassword((newPassword) => ({
        ...newPassword,
        error: true,
      }));
  };

  const handleRepeatPasswordOnBlur = () => {
    if (
      validatePassword(repeatPassword.value) &&
      repeatPassword.value === newPassword.value
    )
      setRepeatPassword((repeatPassword) => ({
        ...repeatPassword,
        error: false,
      }));
    else
      setRepeatPassword((repeatPassword) => ({
        ...repeatPassword,
        error: true,
      }));
  };

  const handleOnButtonClick = () => {
    passwordUpdate(newPassword.value, currentPassword.value);
  };

  const handleEditPassword = () => {
    setCurrentPassword({
      value: "",
      error: false,
    });
    setNewPassword({
      value: "",
      error: false,
    });
    setRepeatPassword({
      value: "",
      error: false,
    });
    setShowEdit(!showEdit);
  };

  const hideModal = () => {
    setVisibleModal(false);
    setShowPassword(false);
    setShowEdit(false);
    setShowPasswordConfirmation(false);
    setShowHelpPassword(false);
    setCurrentPassword({
      value: "",
      error: false,
    });
    setNewPassword({
      value: "",
      error: false,
    });
    setRepeatPassword({
      value: "",
      error: false,
    });
    clearSuccess();
  };

  const renderLoader = () => loading && <Loader />;

  const enableButton = () => {
    if (
      currentPassword.value &&
      newPassword.value &&
      repeatPassword.value &&
      !currentPassword.error &&
      !newPassword.error &&
      !repeatPassword.error
    )
      return false;
    else return true;
  };

  return (
    <Container>
      {renderLoader()}
      <ContainerProfile>
        <LogoProfile src={LogoImg} />
        <ContainerInfo>
          <Title>{name}</Title>
          <Title subTitle light>
            Editar mi perfil
          </Title>
        </ContainerInfo>
      </ContainerProfile>
      <MainContainer>
        <DataContainer>
          <Title subTitle>Nombres y apellidos</Title>
          <InputLight
            id="name"
            type="text"
            disabled
            defaultValue={`${name} ${lastName}`}
          />
        </DataContainer>
        <DataContainer>
          <Title subTitle>Correo electrónico</Title>
          <InputLight id="email" type="text" disabled defaultValue={email} />
        </DataContainer>
        <DataContainer>
          <Title subTitle>Contraseña</Title>
          <InputLight
            id="password"
            type="password"
            maxLength={8}
            minLength={8}
            disabled
            defaultValue={"********"}
          />
          <LogoEdit src={EditIcon} onClick={handleEditPassword} />
        </DataContainer>

        {showEdit && (
          <>
            <DataContainer>
              <Title subTitle>Contraseña actual</Title>
              <InputLight
                id="password"
                type={showCurrentPassword ? "text" : "password"}
                maxLength={8}
                minLength={8}
                value={currentPassword.value}
                onBlur={handleCurrentPasswordOnBlur}
                onChange={handleCurrentPasswordChange}
                errorMessage={
                  currentPassword.error ? "Contraseña inválida" : ""
                }
                error={currentPassword.error}
              />
              <LogoEdit
                src={showCurrentPassword ? EyeOnIcon : EyeOffIcon}
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              />
            </DataContainer>
            <DataContainer>
              <Title subTitle>Nueva contraseña</Title>
              <InputLight
                id="newPassword"
                type={showPassword ? "text" : "password"}
                value={newPassword.value}
                onFocus={() => setShowHelpPassword(true)}
                onBlur={() => {
                  handleNewPasswordOnBlur();
                  setShowHelpPassword(false);
                }}
                onChange={handleNewPasswordChange}
                errorMessage={newPassword.error ? "Contraseña inválida" : ""}
                error={newPassword.error}
                maxLength={8}
                minLength={8}
              />
              <LogoEdit
                src={showPassword ? EyeOnIcon : EyeOffIcon}
                onClick={() => setShowPassword(!showPassword)}
              />
            </DataContainer>
            {showHelpPassword && (
              <CheckPassword profile password={newPassword.value} />
            )}
            <DataContainer>
              <Title subTitle>Confirmar contraseña </Title>
              <InputLight
                id="confirmPassword"
                type={showPasswordConfirmation ? "text" : "password"}
                maxLength={8}
                minLength={8}
                value={repeatPassword.value}
                onBlur={handleRepeatPasswordOnBlur}
                onChange={handleRepeatPasswordChange}
                errorMessage={repeatPassword.error ? "Contraseña inválida" : ""}
                error={repeatPassword.error}
              />
              <LogoEdit
                src={showPasswordConfirmation ? EyeOnIcon : EyeOffIcon}
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
              />
            </DataContainer>
          </>
        )}
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          disabled={enableButton()}
          onClick={handleOnButtonClick}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    name: state.user.name,
    email: state.user.email,
    lastName: state.user.lastName,
    loading: state.user.loading,
    success: state.user.successOnFormAction,
    error: state.user.error,
  };
};

export default connect(mapStateTopProps, { passwordUpdate, clearSuccess })(
  Profile
);
