import { DASHBOARD_ACTIONS } from "../actions/DashboardActions";

export type dashboardStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  dataOperations: [] as any,
  dataCashiers: [] as any,
  dataUsers: [] as any,
  loading: false,
  error: "",
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DASHBOARD_ACTIONS.FETCH_DASHBOARD_START:
      return { ...state, loading: true };
    case DASHBOARD_ACTIONS.FETCH_DASHBOARD_OPERATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        dataOperations: action.payload.data,
      };

    case DASHBOARD_ACTIONS.FETCH_DASHBOARD_CASHIERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        dataCashiers: action.payload.data,
      };

    case DASHBOARD_ACTIONS.FETCH_DASHBOARD_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        dataUsers: action.payload.data,
      };
    case DASHBOARD_ACTIONS.FETCH_DASHBOARD_FAIL:
      return { ...state, loading: false, error: action.payload };

    case DASHBOARD_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: action.payload };
    case DASHBOARD_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case DASHBOARD_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
