import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LogoImg from "../../../assets/icons/user-circle.png";
import LogoConfig from "../../../assets/icons/user-config.png";
import LogoUser from "../../../assets/icons/user-circle-light.png";
import LogoDashboard from "../../../assets/icons/dashboard.png";
import Chevron from "../../../assets/icons/chevron-light.png";
import RadioOn from "../../../assets/icons/RadiobuttonOn-light.png";
import RadioOff from "../../../assets/icons/Radiobutton-light.png";
import { CELUPAGO_USER_ROLE } from "../../../utils/ConstHelper";
import { globalStateType } from "../../../ducks/reducers";
import {
  Container,
  ContainerInfo,
  LogoProfile,
  ContainerProfile,
  Title,
  ContainerOptions,
  ContainerOption,
  LogoOption,
} from "./styles";

type Props = {
  role: string;
  name: string;
  component?: number;
  setCurrentComponent: (component: number) => void;
};

const Sidebar = ({ role, name, setCurrentComponent, component }: Props) => {
  const [showOption, setShowOption] = useState(false);
  const [selectRadioUsers, setSelectRadioUsers] = useState(false);
  const [selectRadioCashiers, setSelectRadioCashiers] = useState(false);
  const [selectRadioCountries, setSelectRadioCountries] = useState(false);
  const [selectRadioBanks, setSelectRadioBanks] = useState(false);
  const [selectRadioDocuments, setSelectRadioDocuments] = useState(false);
  const [selectRadioOperations, setSelectRadioOperations] = useState(false);
  const [selectRadioClients, setSelectRadioClients] = useState(false);
  let userRole = CELUPAGO_USER_ROLE.find((option) => option.value === role)
    ?.label;

  useEffect(() => {
    if (component === 0) {
      offRadioButtons();
    } else if (component === 7) {
      setSelectRadioOperations(true);
    }
  }, [component]);

  const offRadioButtons = () => {
    setSelectRadioOperations(false);
    setSelectRadioCashiers(false);
    setSelectRadioUsers(false);
    setSelectRadioCountries(false);
    setSelectRadioBanks(false);
    setSelectRadioDocuments(false);
    setSelectRadioClients(false);
  };

  const renderNavComponent = () => {
    return (
      <React.Fragment>
        {(role === CELUPAGO_USER_ROLE[0].value ||
          role === CELUPAGO_USER_ROLE[1].value) && (
          <ContainerOption
            isOption
            onClick={() => {
              offRadioButtons();
              setSelectRadioUsers(true);
              setCurrentComponent(2);
            }}
          >
            <LogoOption
              userProfile
              isOption
              src={selectRadioUsers ? RadioOn : RadioOff}
            />
            <Title isOption>Usuarios</Title>
          </ContainerOption>
        )}
        {(role === CELUPAGO_USER_ROLE[2].value ||
          role === CELUPAGO_USER_ROLE[1].value) && (
          <ContainerOption
            isOption
            onClick={() => {
              offRadioButtons();
              setSelectRadioCashiers(true);
              setCurrentComponent(3);
            }}
          >
            <LogoOption
              userProfile
              isOption
              src={selectRadioCashiers ? RadioOn : RadioOff}
            />
            <Title isOption>Cajeros</Title>
          </ContainerOption>
        )}
        {(role === CELUPAGO_USER_ROLE[2].value ||
          role === CELUPAGO_USER_ROLE[1].value) && (
          <ContainerOption
            isOption
            onClick={() => {
              offRadioButtons();
              setSelectRadioCountries(true);
              setCurrentComponent(4);
            }}
          >
            <LogoOption
              userProfile
              isOption
              src={selectRadioCountries ? RadioOn : RadioOff}
            />
            <Title isOption>Países</Title>
          </ContainerOption>
        )}
        {role === CELUPAGO_USER_ROLE[1].value && (
          <ContainerOption
            isOption
            onClick={() => {
              offRadioButtons();
              setSelectRadioBanks(true);
              setCurrentComponent(5);
            }}
          >
            <LogoOption
              userProfile
              isOption
              src={selectRadioBanks ? RadioOn : RadioOff}
            />
            <Title isOption>Bancos</Title>
          </ContainerOption>
        )}
        {role === CELUPAGO_USER_ROLE[1].value && (
          <ContainerOption
            isOption
            onClick={() => {
              offRadioButtons();
              setSelectRadioDocuments(true);
              setCurrentComponent(6);
            }}
          >
            <LogoOption
              userProfile
              isOption
              src={selectRadioDocuments ? RadioOn : RadioOff}
            />
            <Title isOption>Tipos de documento</Title>
          </ContainerOption>
        )}
        {role !== CELUPAGO_USER_ROLE[0].value && (
          <ContainerOption
            isOption
            onClick={() => {
              offRadioButtons();
              setSelectRadioOperations(true);
              setCurrentComponent(7);
            }}
          >
            <LogoOption
              userProfile
              isOption
              src={selectRadioOperations ? RadioOn : RadioOff}
            />
            <Title isOption>Operaciones</Title>
          </ContainerOption>
        )}
        {role === CELUPAGO_USER_ROLE[1].value && (
          <ContainerOption
            isOption
            onClick={() => {
              offRadioButtons();
              setSelectRadioClients(true);
              setCurrentComponent(8);
            }}
          >
            <LogoOption
              userProfile
              isOption
              src={selectRadioClients ? RadioOn : RadioOff}
            />
            <Title isOption>Clientes</Title>
          </ContainerOption>
        )}
      </React.Fragment>
    );
  };

  return (
    <Container>
      <ContainerProfile>
        <LogoProfile src={LogoImg} />
        <ContainerInfo>
          <Title>{name}</Title>
          <Title subTitle>{userRole}</Title>
        </ContainerInfo>
      </ContainerProfile>
      <ContainerOptions>
        <ContainerOption
          onClick={() => {
            setCurrentComponent(0);
            offRadioButtons();
          }}
        >
          <LogoOption src={LogoDashboard} />
          <Title>Dashboard</Title>
        </ContainerOption>
        <ContainerOption onClick={() => setShowOption(!showOption)}>
          <LogoOption src={LogoConfig} />
          <Title>Configurar</Title>
          <LogoOption src={Chevron} chevron />
        </ContainerOption>
        {showOption && renderNavComponent()}
        <ContainerOption
          onClick={() => {
            setCurrentComponent(1);
            offRadioButtons();
          }}
        >
          <LogoOption userProfile src={LogoUser} />
          <Title>Mi perfil</Title>
        </ContainerOption>
      </ContainerOptions>
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    name: state.user.name,
    role: state.user.role,
  };
};

export default connect(mapStateTopProps, {})(Sidebar);
