import { CASHIER_ACTIONS } from "../actions/CashierActions";
import {
  Cashier,
  CashierRow,
  NewlyCashier,
  CashierEdit,
  CashierAccount,
} from "../../utils/DataType";

export type cashierStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  cashiers: [] as Cashier[],
  cashierRows: [] as CashierRow[],
  newlyCashier: {} as NewlyCashier,
  cashier: {} as CashierEdit,
  cashierAccounts: [] as CashierAccount[],
  loading: false,
  successOnFormAction: false,
  error: "",
  amountTransaction: 0,
  amountTransactionComplete: 0,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CASHIER_ACTIONS.FETCH_CASHIER_LIST_START:
      return { ...state, loading: true };
    case CASHIER_ACTIONS.FETCH_CASHIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cashiers: action.payload.cashiers,
        cashierRows: action.payload.cashierRows,
        error: "",
      };
    case CASHIER_ACTIONS.FETCH_CASHIER_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CASHIER_ACTIONS.FORM_ACTION_START:
      return { ...state, loading: true };
    case CASHIER_ACTIONS.FORM_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successOnFormAction: action.payload.successOnFormAction,
        newlyCashier: action.payload.newlyCashier,
        error: "",
      };
    case CASHIER_ACTIONS.FORM_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_START:
      return { ...state, loading: true };
    case CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        cashier: action.payload.cashier,
        error: "",
      };
    case CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CASHIER_ACTIONS.FETCH_CASHIER_ACCOUNTS:
      return {
        ...state,
        loading: false,
        cashierAccounts: action.payload,
      };

    case CASHIER_ACTIONS.SET_INVOICED_AMOUNT:
      return {
        ...state,
        loading: false,
        amountTransaction: action.payload.amountTransaction,
        amountTransactionComplete: action.payload.amountTransactionComplete,
      };

    case CASHIER_ACTIONS.CLEAN_SUCCESS_STATE:
      return { ...state, successOnFormAction: false };
    case CASHIER_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: action.payload };
    case CASHIER_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case CASHIER_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
