import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BackButton from "../../../common/BackButton";
import InputLight from "../../../common/InputLight";
import Dropdown from "../../../common/Dropdown";
import Button from "../../../common/Button";
import Loader from "../../../common/Loader";
import Modal from "../../../common/Modal";
import { globalStateType } from "../../../../ducks/reducers";
import {
  getBankId,
  editBank,
  cleanBankState,
} from "../../../../ducks/actions/BankActions";
import { Bank } from "../../../../utils/DataType";
import { validateNames } from "../../../../utils/Validators";
import { STATUS_TYPES } from "../../../../utils/ConstHelper";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  bank: Bank;
  selectedBank: number;
  loading: boolean;
  success: boolean;
  error: string;
  editBankPress: () => void;
  getBankId: (id: number) => void;
  cleanBankState: () => void;
  editBank: (
    id: number,
    newCode: string,
    newStatus: string,
    newCommercialName: string
  ) => void;
};

const EditBank = ({
  editBankPress,
  selectedBank,
  getBankId,
  bank,
  editBank,
  cleanBankState,
  loading,
  success,
  error,
}: Props) => {
  const { entityName, commercialName, code, bankStatus } = bank;
  const countryName = bank.country ? bank.country.name : "";
  const [visibleModal, setVisibleModal] = useState(false);

  const [newCommercialName, setNewCommercialName] = useState({
    value: "",
    error: false,
  });

  const [newCode, setNewCode] = useState({
    value: "",
    error: false,
  });

  const [newStatus, setNewStatus] = useState("");

  useEffect(() => {
    getBankId(selectedBank);
  }, [getBankId, selectedBank]);

  useEffect(() => {
    setNewCommercialName((newCommercialName) => ({
      ...newCommercialName,
      value: commercialName,
    }));
    setNewCode((newCode) => ({ ...newCode, value: code }));
    setNewStatus(bankStatus);
  }, [commercialName, bankStatus, code]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const hideModal = () => {
    setVisibleModal(false);
    editBankPress();
    cleanBankState();
  };

  const handleNewStatusChange = (event: any) => {
    setNewStatus(event.target.value);
  };

  const handleNewCommercialNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.toUpperCase();
    if (validateNames(value))
      setNewCommercialName({ ...newCommercialName, value });
  };

  const handleNewCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    if (validateNames(value)) setNewCode({ ...newCode, value });
  };

  const handleNewCodeOnBlur = () => {
    if (newCode.value) setNewCode({ ...newCode, error: false });
    else setNewCode({ ...newCode, error: true });
  };

  const handleNewCommercialNameOnBlur = () => {
    if (newCommercialName.value)
      setNewCommercialName({ ...newCommercialName, error: false });
    else setNewCommercialName({ ...newCommercialName, error: true });
  };

  const handleOnButtonClick = () => {
    editBank(selectedBank, newCode.value, newStatus, newCommercialName.value);
  };

  const enableButton = () => {
    if (
      (code !== newCode.value ||
        bankStatus !== newStatus ||
        commercialName !== newCommercialName.value) &&
      !newCode.error &&
      !newCommercialName.error
    )
      return false;
    else return true;
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={editBankPress} />
      </BackButtonContainer>
      <Container info>
        <Title>Editar banco</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre de banco</Title>
            <InputLight
              id="bankName"
              type="text"
              disabled
              value={entityName || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre comercial</Title>
            <InputLight
              id="tradeName"
              type="text"
              maxLength={30}
              minLength={3}
              defaultValue={commercialName}
              value={newCommercialName.value || ""}
              onChange={handleNewCommercialNameChange}
              error={newCommercialName.error}
              onBlur={handleNewCommercialNameOnBlur}
              errorMessage={
                newCommercialName.error ? "El campo no puede estar vacío" : ""
              }
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Siglas banco</Title>
            <InputLight
              id="bankAcronym"
              type="text"
              maxLength={30}
              minLength={3}
              defaultValue={code}
              value={newCode.value || ""}
              onChange={handleNewCodeChange}
              error={newCode.error}
              onBlur={handleNewCodeOnBlur}
              errorMessage={
                newCode.error ? "El campo no puede estar vacío" : ""
              }
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <InputLight
              id="countryBank"
              type="text"
              disabled
              value={countryName || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Estatus</Title>
            <Dropdown
              id={"status"}
              defaultValue={bankStatus}
              options={STATUS_TYPES}
              value={newStatus || ""}
              onChange={handleNewStatusChange}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    bank: state.bank.bank,
    loading: state.bank.loading,
    success: state.bank.successOnEditAction,
    error: state.bank.error,
  };
};

export default connect(mapStateTopProps, {
  getBankId,
  editBank,
  cleanBankState,
})(EditBank);
