import React, { ReactElement } from "react";
import { MainContainer } from "./styles";

type Props = {
  darkTheme?: boolean;
  children?: any;
  flexDirection?: string;
};

const Container = ({
  darkTheme = false,
  flexDirection = "column",
  children,
}: Props): ReactElement => {
  return (
    <MainContainer darkTheme={darkTheme} flexDirection={flexDirection}>
      {children}
    </MainContainer>
  );
};

export default Container;
