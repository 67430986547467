import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Table from "../../../common/Table";
import Loader from "../../../common/Loader";
import OptionFilter from "../../../common/OptionFilter";
import DateFilter from "../../../common/DateFilter";
import SearchButton from "../../../common/SearchButton";
import TransactionDetail from "../../../common/TransactionDetail";
import Modal from "../../../common/Modal";
import ReportButton from "../../../common/ReportButton";
import Edit from "../../../../assets/icons/edit-purple.png";
import Eye from "../../../../assets/icons/eye-regular-black.png";
import {
  OPERATION_STATUS,
  OPERATION_TABLE,
} from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import { Operation, OperationRow } from "../../../../utils/DataType";
import { getAllOperations } from "../../../../ducks/actions/OperationActions";
import {
  Container,
  Title,
  TableContainer,
  FilterContainer,
  EditIcon,
  EyeIcon,
  DownloadButtonContainer,
  FilterArea,
} from "../styles";

type Props = {
  loading: boolean;
  error: string;
  operations: Operation[];
  operationRow: OperationRow[];
  reportData: any;
  editOperationPress: () => void;
  selectedOperation: (operation: Operation) => void;
  getAllOperations: (status?: string, from?: string, to?: string) => void;
};

const OperationList = ({
  loading,
  getAllOperations,
  operationRow,
  editOperationPress,
  selectedOperation,
  operations,
  error,
  reportData,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [operationStatus, setOperationStatus] = useState({
    value: "",
    label: "",
    id: 0,
  });
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [open, setOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(
    undefined as undefined | Operation
  );

  useEffect(() => {
    const defaultDate = moment().format("YYYY-MM-DD");
    getAllOperations("", defaultDate, "");
  }, [getAllOperations]);

  useEffect(() => {
    if (error) {
      setVisibleModal(true);
    }
  }, [error]);

  const handleFromDateChange = (date: any) => {
    if (toDate) {
      setFromDate(date <= toDate ? date : "");
    } else {
      setFromDate(date);
    }
  };

  const handleToDateChange = (date: any) => {
    if (fromDate) {
      setToDate(date >= fromDate ? date : "");
    } else {
      setToDate(date);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const filteredSearch = () => {
    if (operationStatus.value || fromDate || toDate) {
      getAllOperations(operationStatus.value, fromDate, toDate);
    } else {
      getAllOperations();
    }
  };

  const handleEditClick = (operation: OperationRow) => {
    const currentOperation = operations.filter(
      (option) => option.id === operation.id
    );
    setCurrentTransaction(currentOperation[0]);
    selectedOperation(currentOperation[0]);
    editOperationPress();
  };

  const handleDetailClick = (operation: OperationRow) => {
    const currentOperation = operations.filter(
      (option) => option.id === operation.id
    );

    setCurrentTransaction(currentOperation[0]);
    setOpen(true);
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  const renderLoader = () => loading && <Loader />;

  const optionComponents = [<EyeIcon src={Eye} />, <EditIcon src={Edit} />];

  return (
    <Container tableView>
      {renderLoader()}
      <TransactionDetail
        open={open}
        handleClose={handleClose}
        transaction={currentTransaction}
      />
      <Container info>
        <Title>Operaciones</Title>
      </Container>
      <TableContainer>
        <FilterContainer>
          <FilterArea>
            <OptionFilter
              value={operationStatus}
              handleFilterChange={(option) => {
                setOperationStatus(option);
              }}
              label={"Estatus"}
              options={OPERATION_STATUS}
              clearFilter={() =>
                setOperationStatus({
                  value: "",
                  label: "",
                  id: 0,
                })
              }
            />
            <DateFilter
              value={fromDate}
              label={"Desde"}
              clearFilter={() => setFromDate("")}
              handleDateChange={handleFromDateChange}
            />
            <DateFilter
              value={toDate}
              label={"Hasta"}
              clearFilter={() => setToDate("")}
              handleDateChange={handleToDateChange}
            />
            <SearchButton onClick={filteredSearch} />
          </FilterArea>
          <DownloadButtonContainer>
            <ReportButton
              dataToDownload={reportData}
              filename={"Reporte_tx_ekiienvios.xls"}
              disabled={operationRow.length > 0 ? false : true}
            />
          </DownloadButtonContainer>
        </FilterContainer>
        <Table
          tableColumns={OPERATION_TABLE}
          dataRows={operationRow}
          optionComponents={optionComponents}
          onOptionOneClick={handleDetailClick}
          onOptionTwoClick={handleEditClick}
          refresh={() => getAllOperations()}
        />
      </TableContainer>
      <Modal
        show={visibleModal}
        title={""}
        body={"Ha ocurrido un error, inténtalo nuevamente"}
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.operation.loading,
    error: state.operation.error,
    operations: state.operation.operations,
    operationRow: state.operation.operationRow,
    reportData: state.operation.reportData,
  };
};

export default connect(mapStateTopProps, { getAllOperations })(OperationList);
