import styled from "styled-components";
import { Theme } from "../../../utils/theme";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  max-width: 300px;
  min-width: 280px;
  margin-bottom: 20px;
`;

export const InputComponent = styled.input`
  background-color: rgba(227, 227, 227, 0.35);
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 2px 2px 0px 0px;
  color: white;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #bababa;
  }
  :-ms-input-placeholder {
    color: #bababa;
  }
`;

export const EyeIcon = styled.img`
  width: 20px;
  height: 14px;
  position: fixed;
  margin-left: 120px;
  z-index: 0;
`;

export const Label = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #ffffff;
  align-self: flex-start;
  margin-bottom: 5px;
`;
