import styled from "styled-components";
import { Theme } from "../../../utils/theme";

export const MainFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7.5px;
  margin-right: 7.5px;
`;

export const LabelContainer = styled.div`
  width: 184px;
  height: 50px;
  border-radius: 3px;
  background-color: ${Theme.colors.filterBackground};
  display: flex;
  align-items: center;
  cursor: pointer;

  justify-content: space-between;
`;

export const LabelTextContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  text-align: center;
  color: ${Theme.colors.filterLabel};
  margin-left: 5px;
`;

export const ClearContainer = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ClearImage = styled.img`
  width: 12.5px;
  height: 12.5px;
  margin-right: 10px;
  object-fit: contain;
`;

export const OptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 55px;
  width: 184px;
  z-index: 100000;
`;

export const OptionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Theme.colors.filterRow};
  align-items: center;
  width: 184px;
  height: 25px;
  cursor: pointer;
`;

export const OptionLabelContainer = styled.div`
  display: flex;
  height: 100%;
  width: 80%;
  align-items: center;
`;

export const OptionLabel = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  text-align: left;
  color: ${Theme.colors.filterOptionLabel};
  margin-left: 5px;
`;

export const CheckIconContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
`;

export const CheckedIcon = styled.img`
  width: 12.5px;
  height: 12.5px;
  object-fit: contain;
`;
