import moment from "moment";
import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";
import { dispatchUnauthorizedError } from "./UserActions";
import {
  getOperation,
  getOperationRowData,
  getCryptoToDropDown,
  getOperationReport,
} from "../../utils/Formatters";
import { Operation, OperationRow } from "../../utils/DataType";
const base = "OPERATION_ACTIONS_";
const requestManager = new RequestManager();

export const OPERATION_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",

  FETCH_OPERATION_LIST_START: base + "FETCH_OPERATION_LIST_START",
  FETCH_OPERATION_LIST_SUCCESS: base + "FETCH_OPERATION_LIST_SUCCESS",
  FETCH_OPERATION_LIST_FAIL: base + "FETCH_OPERATION_LIST_FAIL",

  FORM_ACTION_START: base + "FORM_ACTION_START",
  FORM_ACTION_SUCCESS: base + "FORM_ACTION_SUCCESS",
  FORM_ACTION_FAIL: base + "FORM_ACTION_FAIL",
};

export const getAllOperations = (
  status?: string,
  from?: string,
  to?: string
) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: OPERATION_ACTIONS.FETCH_OPERATION_LIST_START });
    let url = `/transaction/celupago?limit=500`;
    if (status) {
      url = url + `&status=${status}`;
    }
    if (from && !to) {
      const maxDate = moment().format().split("T")[0];
      url = url + `&from=${from}&to=${maxDate}`;
    } else {
      if (from) {
        url = url + `&from=${from}`;
      }
      if (to) {
        url = url + `&to=${to}`;
      }
    }

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });
      const operationUnformatted = response.data;
      let operations: Operation[] = [];
      let operationRow: OperationRow[] = [];
      let allCountries: any[] = [];
      let allCryptos: any[] = [];
      let allCryptosSold: any[] = [];
      operationUnformatted.map((operation: any) => {
        operations.push(getOperation(operation));
        operationRow.push(getOperationRowData(operation));
        allCountries.push(operation.cashier_account.bank.country.name);
        if (operation.crypto && operation.crypto.code)
          allCryptos.push(operation.crypto.code);
        if (operation.destination_crypto && operation.destination_crypto.code)
          allCryptosSold.push(operation.destination_crypto.code);
        return null;
      });
      const countries = Array.from(new Set(allCountries));
      const cryptoCoins = Array.from(new Set(allCryptos));
      const cryptoCoinsSold = Array.from(new Set(allCryptosSold));

      const reportData = getOperationReport(
        operationUnformatted,
        countries,
        cryptoCoins,
        cryptoCoinsSold
      );
      const cryptoActive = await requestManager.get(
        `/crypto-active?status=ACTIVE`,
        {}
      );
      const cryptos = cryptoActive.data.map((crypto: any) =>
        getCryptoToDropDown(crypto)
      );

      dispatch({
        type: OPERATION_ACTIONS.FETCH_OPERATION_LIST_SUCCESS,
        payload: { operations, operationRow, cryptos, reportData },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: OPERATION_ACTIONS.FETCH_OPERATION_LIST_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const updateStatusOperation = (status: string, id: number) => {
  return async (dispatch: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const data = { status };
    dispatch({ type: OPERATION_ACTIONS.FORM_ACTION_START });
    let url = `/transaction/${id}/status`;

    try {
      await requestManager.patch(url, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch({
        type: OPERATION_ACTIONS.FORM_ACTION_SUCCESS,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: OPERATION_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const updateCryptoOperation = (
  id: number,
  status: string,
  crypto?: string,
  cryptoAmount?: number,
  destinationCrypto?: string,
  destinationCryptoAmount?: number
) => {
  return async (dispatch: any) => {
    dispatch({ type: OPERATION_ACTIONS.FORM_ACTION_START });
    const accessToken = localStorage.getItem("accessToken");
    const data = {
      cryptoCurrency: Number(crypto),
      crypto_amount: cryptoAmount,
      destinationCryptoCurrency: Number(destinationCrypto),
      destination_crypto_amount: destinationCryptoAmount,
      status,
    };

    try {
      await requestManager.patch(`/transaction/${id}/crypto`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch({
        type: OPERATION_ACTIONS.FORM_ACTION_SUCCESS,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: OPERATION_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const clearOperationState = () => {
  return {
    type: OPERATION_ACTIONS.CLEAN_STATE,
  };
};
