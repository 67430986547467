import React from "react";
import { connect } from "react-redux";
import Button from "../../../common/Button";
import InputLight from "../../../common/InputLight";
import { NewlyCashier } from "../../../../utils/DataType";
import { globalStateType } from "../../../../ducks/reducers";
import { cleanSuccessState } from "../../../../ducks/actions/CashierActions";
import {
  Container,
  MainContainer,
  ContainerData,
  ButtonSection,
  Title,
} from "../styles";

type Props = {
  cashier: NewlyCashier;
  stepsNavigation: () => void;
  cleanSuccessState: () => void;
};

const SummaryCashier = ({
  cashier,
  stepsNavigation,
  cleanSuccessState,
}: Props) => {
  const handleOnButtonClick = () => {
    cleanSuccessState();
    stepsNavigation();
  };
  return (
    <Container>
      <Container info>
        <Title bold> Resumen cajero</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>ID Celupagos </Title>
            <InputLight
              id="idCelupagos"
              type="text"
              disabled
              defaultValue={cashier.celupagoId}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tipo de cajero </Title>
            <InputLight
              id="cashierType"
              type="text"
              disabled
              defaultValue={cashier.cashierType}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre </Title>
            <InputLight
              id="name"
              type="text"
              disabled
              defaultValue={cashier.name}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Apellido </Title>
            <InputLight
              id="lastName"
              type="text"
              disabled
              defaultValue={cashier.lastName}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Correo electrónico</Title>
            <InputLight
              id="email"
              type="text"
              disabled
              defaultValue={cashier.email}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <InputLight
              id="country"
              type="text"
              disabled
              defaultValue={cashier.country.name}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nº teléfono</Title>
            <InputLight
              id="phone"
              type="text"
              disabled
              defaultValue={`${cashier.phonePrefix} ${cashier.phoneNumber}`}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Documento de identidad </Title>
            <InputLight
              id="document"
              type="text"
              disabled
              defaultValue={`${cashier.docType.code}- ${cashier.document}`}
            />
          </ContainerData>

          <ContainerData input>
            <Title subTitle>Inicio contrato </Title>
            <InputLight
              id="dateStart"
              type="text"
              disabled
              defaultValue={cashier.contractStart}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Fin contrato</Title>
            <InputLight
              id="dateEnd"
              type="text"
              disabled
              defaultValue={cashier.contractEnd}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto límite diario</Title>
            <InputLight
              id="dailyAmount"
              type="text"
              disabled
              defaultValue={cashier.dailyLimit}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto garantía</Title>
            <InputLight
              id="guarantee"
              type="text"
              disabled
              defaultValue={cashier.guarantee}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tasa de cambio </Title>
            <InputLight
              id="rate"
              type="text"
              disabled
              defaultValue={cashier.rate}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button buttonText="CONTINUAR" onClick={handleOnButtonClick} />
      </ButtonSection>
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    cashier: state.cashier.newlyCashier,
  };
};

export default connect(mapStateTopProps, { cleanSuccessState })(SummaryCashier);
