import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BackButton from "../../../common/BackButton";
import Dropdown from "../../../common/Dropdown";
import InputLight from "../../../common/InputLight";
import Button from "../../../common/Button";
import Loader from "../../../common/Loader";
import Modal from "../../../common/Modal";
import {
  getCelupagoUserId,
  editCelupagoUser,
  cleanCelupagoUserState,
} from "../../../../ducks/actions/CelupagoUsersActions";
import { globalStateType } from "../../../../ducks/reducers";
import {
  STATUS_TYPES,
  CELUPAGO_USER_ROLE,
} from "../../../../utils/ConstHelper";
import { CelupagoUser } from "../../../../utils/DataType";
import {
  Container,
  MainContainer,
  Title,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  selectedUser: number;
  celupagoUser: CelupagoUser;
  loading: boolean;
  success: boolean;
  error: string;
  getCelupagoUserId: (id: number) => void;
  cleanCelupagoUserState: () => void;
  editUserPress: () => void;
  editCelupagoUser: (
    id: number,
    newCelupagoUserType: string,
    newCelupagoUserStatus: string
  ) => void;
};

const EditUser = ({
  celupagoUser,
  selectedUser,
  loading,
  success,
  editCelupagoUser,
  editUserPress,
  getCelupagoUserId,
  cleanCelupagoUserState,
  error,
}: Props) => {
  const { email, lastName, name, role, userStatus } = celupagoUser;
  const [visibleModal, setVisibleModal] = useState(false);

  const [newRole, setNewRole] = useState();

  const [newStatus, setNewStatus] = useState();

  useEffect(() => {
    getCelupagoUserId(selectedUser);
  }, [getCelupagoUserId, selectedUser]);

  useEffect(() => {
    setNewRole(role);
    setNewStatus(userStatus);
  }, [userStatus, role]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const handleNewRoleChange = (event: any) => {
    setNewRole(event.target.value);
  };

  const handleNewStatusChange = (event: any) => {
    setNewStatus(event.target.value);
  };

  const hideModal = () => {
    setVisibleModal(false);
    editUserPress();
    cleanCelupagoUserState();
  };

  const handleOnButtonClick = () => {
    editCelupagoUser(selectedUser, newRole, newStatus);
  };

  const enableButton = () => {
    if (userStatus !== newStatus || role !== newRole) return false;
    return true;
  };

  const renderLoader = () => loading && <Loader />;
  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={editUserPress} />
      </BackButtonContainer>
      <Container info>
        <Title bold>Editar usuario</Title>
        <Container roleContainer>
          <Title light>Rol</Title>
          <Dropdown
            id={"role"}
            styles={"role"}
            defaultValue={role}
            value={newRole || ""}
            options={CELUPAGO_USER_ROLE}
            onChange={handleNewRoleChange}
          />
        </Container>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre</Title>
            <InputLight id="name" type="text" disabled value={name || ""} />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Apellido</Title>
            <InputLight
              id="lastName"
              type="text"
              disabled
              value={lastName || ""}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Correo electrónico</Title>
            <InputLight id="email" type="text" disabled value={email || ""} />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Estatus</Title>
            <Dropdown
              id={"status"}
              defaultValue={userStatus}
              value={newStatus || ""}
              options={STATUS_TYPES}
              onChange={handleNewStatusChange}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.celupagoUser.loading,
    success: state.celupagoUser.successOnEditAction,
    celupagoUser: state.celupagoUser.celupagoUser,
    error: state.celupagoUser.error,
  };
};

export default connect(mapStateTopProps, {
  getCelupagoUserId,
  editCelupagoUser,
  cleanCelupagoUserState,
})(EditUser);
