import { BANK_ACTIONS } from "../actions/BankActions";
import {
  BankRow,
  Bank,
  BankCountryFilter,
  BankOption,
} from "../../utils/DataType";

export type bankStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  banks: [] as Bank[],
  bankRows: [] as BankRow[],
  countryOption: [] as BankCountryFilter[],
  banksAvailable: [] as BankOption[],
  successOnEditAction: false,
  successOnFormAction: false,
  loading: false,
  error: "",
  bank: {} as Bank,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case BANK_ACTIONS.FETCH_BANK_LIST_START:
      return { ...state, loading: true };
    case BANK_ACTIONS.FETCH_BANK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        banks: action.payload.banks,
        bankRows: action.payload.bankRows,
        countryOption: action.payload.countryOption,
      };
    case BANK_ACTIONS.FETCH_BANK_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case BANK_ACTIONS.FETCH_BANK_BY_COUNTRY_START:
      return { ...state, loading: true };
    case BANK_ACTIONS.FETCH_BANK_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        banksAvailable: action.payload.banksAvailable,
      };
    case BANK_ACTIONS.FETCH_BANK_BY_COUNTRY_FAIL:
      return { ...state, loading: false, error: action.payload };

    case BANK_ACTIONS.FORM_ACTION_START:
      return { ...state, loading: true };
    case BANK_ACTIONS.FORM_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        successOnFormAction: true,
      };
    case BANK_ACTIONS.FORM_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case BANK_ACTIONS.FETCH_BANK_BY_ID_START:
      return { ...state, loading: true };
    case BANK_ACTIONS.FETCH_BANK_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        bank: action.payload.bank,
      };
    case BANK_ACTIONS.FETCH_BANK_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };

    case BANK_ACTIONS.BANK_EDIT_ACTION_START:
      return { ...state, loading: true };
    case BANK_ACTIONS.BANK_EDIT_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successOnEditAction: true,
      };
    case BANK_ACTIONS.BANK_EDIT_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case BANK_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: action.payload };
    case BANK_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case BANK_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
