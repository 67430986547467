import React, { useState } from "react";
import NewCountry from "./NewCountry";
import CountryList from "./CountryList";
import EditCountry from "./EditCountry";

const Country = () => {
  const [newCountry, handleNewCountryPress] = useState(false);
  const [editCountryPress, handleEditCountryPress] = useState(false);
  const [selectedCountry, handleSelectedCountry] = useState();

  return (
    <>
      {!newCountry ? (
        !editCountryPress ? (
          <CountryList
            newCountryPress={() => handleNewCountryPress(!newCountry)}
            editCountryPress={() => handleEditCountryPress(!editCountryPress)}
            selectedCountry={(country) => handleSelectedCountry(country)}
          />
        ) : (
          <EditCountry
            selectedCountry={selectedCountry}
            editCountryPress={() => handleEditCountryPress(!editCountryPress)}
          />
        )
      ) : (
        <NewCountry
          newCountryPress={() => handleNewCountryPress(!newCountry)}
        />
      )}
    </>
  );
};

export default Country;
