import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const DownloadIcon = styled.img`
  width: 13.3px;
  height: 13.3px;
  object-fit: contain;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  color: ${Theme.colors.colorDisabled};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 150px;
  height: 100%;
`;

export const ButtonComponent = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: none;
  outline-style: none;
  width: 145px;
  height: 35px;
  border-radius: 3px;
  cursor: pointer;
  background-color: ${Theme.colors.colorSecondary};
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${Theme.colors.gray2};
      color: ${Theme.colors.gray3};
      cursor: default;
    `};
`;
