import styled from "styled-components";
import { Theme } from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Separator = styled.div`
  margin-top: 20px;
  width: 100%;
  border-top: solid 2px rgb(209, 206, 206);
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

export const Message = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  margin-top: 20px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.21;
  letter-spacing: 0.14px;
  text-align: left;
  color: ${Theme.colors.colorSecondary};
`;
