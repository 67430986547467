import React from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./ducks/reducers";
import AppRouter from "./router";

const defaultProps = {};
type Props = {} & typeof defaultProps;

const App = () => {
  const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(ReduxThunk))
  );

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

export default App;
