import React, { useState } from "react";
import NewBank from "./NewBank";
import BankList from "./BankList";
import EditBank from "./EditBank";
const Bank = () => {
  const [newBank, handleNewBankPress] = useState(false);
  const [editBankPress, handleEditBankPress] = useState(false);
  const [selectedBank, handleSelectedBank] = useState();

  return (
    <>
      {!newBank ? (
        !editBankPress ? (
          <BankList
            newBankPress={() => handleNewBankPress(!newBank)}
            editBankPress={() => handleEditBankPress(!editBankPress)}
            selectedBank={(bank) => handleSelectedBank(bank)}
          />
        ) : (
          <EditBank
            selectedBank={selectedBank}
            editBankPress={() => handleEditBankPress(!editBankPress)}
          />
        )
      ) : (
        <NewBank newBankPress={() => handleNewBankPress(!newBank)} />
      )}
    </>
  );
};

export default Bank;
