import moment from "moment";
import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";
import { getAllBanksByCountry } from "./BankActions";
import { getAvailableCountries } from "./CountryActions";
import { dispatchUnauthorizedError } from "./UserActions";
import {
  getCashier,
  getCashierRowData,
  getNewlyCashier,
  getCashierEdit,
  getCashierAccount,
} from "../../utils/Formatters";
const base = "CASHIER_ACTIONS_";
const requestManager = new RequestManager();

export const CASHIER_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  CLEAN_SUCCESS_STATE: base + "CLEAN_SUCCESS_STATE",
  DO_NOTHING: base + "DO_NOTHING",

  FETCH_CASHIER_LIST_START: base + "FETCH_CASHIER_LIST_START",
  FETCH_CASHIER_LIST_SUCCESS: base + "FETCH_CASHIER_LIST_SUCCESS",
  FETCH_CASHIER_LIST_FAIL: base + "FETCH_CASHIER_LIST_FAIL",

  FETCH_CASHIER_BY_ID_START: base + "FETCH_CASHIER_BY_ID_START",
  FETCH_CASHIER_BY_ID_SUCCESS: base + "FETCH_CASHIER_BY_ID_SUCCESS",
  FETCH_CASHIER_BY_ID_FAIL: base + "FETCH_CASHIER_BY_ID_FAIL",

  FORM_ACTION_START: base + "FORM_ACTION_START",
  FORM_ACTION_SUCCESS: base + "FORM_ACTION_SUCCESS",
  FORM_ACTION_FAIL: base + "FORM_ACTION_FAIL",

  FETCH_CASHIER_ACCOUNTS: base + "FETCH_CASHIER_ACCOUNTS",

  SET_INVOICED_AMOUNT: base + "SET_INVOICED_AMOUNT",
};

export const getAllCashiers = (
  status?: string,
  from?: string,
  to?: string,
  cashierType?: string
) => {
  return (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_LIST_START });
    let url = `/cashier?`;
    if (status) {
      url = url + `&status=${status}`;
    }
    if (cashierType) {
      url = url + `&type=${cashierType}`;
    }
    if (from && !to) {
      const maxDate = moment().format().split("T")[0];
      url = url + `&from=${from}&to=${maxDate}`;
    } else {
      if (from) {
        url = url + `&from=${from}`;
      }
      if (to) {
        url = url + `&to=${to}`;
      }
    }

    requestManager
      .get(url, {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        const cashiersUnformatted = response.data;
        const cashiers = cashiersUnformatted.map((cashier: any) => {
          return getCashier(cashier);
        });
        const cashierRows = cashiersUnformatted.map((cashier: any) => {
          return getCashierRowData(cashier);
        });
        dispatch({
          type: CASHIER_ACTIONS.FETCH_CASHIER_LIST_SUCCESS,
          payload: { cashiers, cashierRows },
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        if (errorMessage.error === "UNAUTHORIZED") {
          dispatch(dispatchUnauthorizedError());
        } else {
          dispatch({
            type: CASHIER_ACTIONS.FETCH_CASHIER_LIST_FAIL,
            payload: { errorMessage: errorMessage.message },
          });
        }
      });
  };
};

export const createNewCashier = (
  name: string,
  lastName: string,
  email: string,
  password: string,
  rate: number,
  document: string,
  phoneNumber: string,
  phonePrefix: string,
  cashierType: string,
  contractStart: string,
  contractEnd: string,
  country: number,
  docType: number,
  dailyLimit: number,
  guarantee: number
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { id } = state.user;
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: CASHIER_ACTIONS.FORM_ACTION_START });
    let data: any = {
      name,
      lastName,
      email,
      password,
      document,
      phoneNumber,
      phonePrefix,
      cashierType,
      contractStart: moment(contractStart).format("MM/DD/YYYY"),
      contractEnd: moment(contractEnd).format("MM/DD/YYYY"),
      country,
      docType,
      createdBy: id,
      dailyLimit,
      guarantee,
    };
    if (rate !== 0) {
      data.rate = rate;
    }
    try {
      const response = await requestManager.post(`cashier/create`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      const newlyCashier = getNewlyCashier(response.data);
      dispatch({
        type: CASHIER_ACTIONS.FORM_ACTION_SUCCESS,
        payload: { newlyCashier, successOnFormAction: true },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const createCashierBankAccount = (
  account_holder: string,
  document: string,
  doc_type: string,
  email: string,
  account_number: string,
  bank: number,
  account_type: string
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { id } = state.cashier.newlyCashier;
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: CASHIER_ACTIONS.FORM_ACTION_START });
    const data = {
      account_holder,
      document,
      doc_type,
      email,
      account_number,
      cashier: id,
      bank,
      account_type,
    };

    try {
      await requestManager.post(`cashier-bank-account`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch({
        type: CASHIER_ACTIONS.FORM_ACTION_SUCCESS,
        payload: {
          newlyCashier: state.cashier.newlyCashier,
          successOnFormAction: true,
        },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const cleanCashierState = () => {
  return {
    type: CASHIER_ACTIONS.CLEAN_STATE,
  };
};

export const cleanSuccessState = () => {
  return {
    type: CASHIER_ACTIONS.CLEAN_SUCCESS_STATE,
  };
};

export const disableCashier = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: CASHIER_ACTIONS.FORM_ACTION_START });

    const data = {
      status: "INACTIVE",
    };
    try {
      await requestManager.patch(`cashier/${id}/status`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch(getAllCashiers());
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getCashierId = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_START });
    let url = `/cashier/${id}/`;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });
      const cashierFormatted = getCashierEdit(response.data);
      dispatch({
        type: CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_SUCCESS,
        payload: { cashier: cashierFormatted },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const editCashier = (
  id: number,
  newRole: string,
  newContractEnd: string,
  newGuarantee: string,
  newRate: string,
  newDailyLimit: string,
  newStatus: string
) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CASHIER_ACTIONS.FORM_ACTION_START });

    const data = {
      rate: newRate,
      daily_limit: newDailyLimit,
      guarantee: newGuarantee,
      contract_end: newContractEnd,
      cashier_status: newStatus,
      cashier_type: newRole,
    };
    try {
      await requestManager.put(`/cashier/${id}/update`, data, {
        Authorization: `Bearer ${accessToken}`,
      });

      dispatch({
        type: CASHIER_ACTIONS.FORM_ACTION_SUCCESS,
        payload: { newlyCashier: {}, successOnFormAction: true },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getCashierAccounts = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CASHIER_ACTIONS.FORM_ACTION_START });
    let url = `/cashier-bank-account/${id}/byCashier?status=ACTIVE`;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });

      const cashierAccounts = response.data.map((account: any) =>
        getCashierAccount(account)
      );
      dispatch({
        type: CASHIER_ACTIONS.FETCH_CASHIER_ACCOUNTS,
        payload: cashierAccounts,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FORM_ACTION_FAIL,
          payload: errorMessage.message,
        });
      }
    }
  };
};

export const disableCashierAccount = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CASHIER_ACTIONS.FORM_ACTION_START });
    let url = `/cashier-bank-account/${id}/status`;

    const data = { status: "INACTIVE" };
    try {
      await requestManager.patch(url, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch(getCashierAccounts(id));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FORM_ACTION_FAIL,
          payload: errorMessage.message,
        });
      }
    }
  };
};

export const getCashierToAddAccount = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_START });
    let url = `/cashier/${id}/`;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });

      const newlyCashier = getNewlyCashier(response.data);
      await dispatch(getAvailableCountries());
      await dispatch(getAllBanksByCountry("ACTIVE", newlyCashier.country.iso));
      dispatch({
        type: CASHIER_ACTIONS.FORM_ACTION_SUCCESS,
        payload: { newlyCashier, successOnFormAction: false },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getCashierInvoicedAmount = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_START });
    let url = `/transaction/${id}/invoicedAmount`;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });

      dispatch({
        type: CASHIER_ACTIONS.SET_INVOICED_AMOUNT,
        payload: {
          amountTransaction: response.data.amountTransaction,
          amountTransactionComplete: response.data.amountTransactionComplete,
        },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CASHIER_ACTIONS.FETCH_CASHIER_BY_ID_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};
