import { CELUPAGO_USER_ACTIONS } from "../actions/CelupagoUsersActions";
import { CelupagoUserRow, CelupagoUser } from "../../utils/DataType";

export type celupagoUserStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  celupagoUsers: [] as CelupagoUser[],
  celupagoUserRows: [] as CelupagoUserRow[],
  celupagoUser: {} as CelupagoUser,
  loading: false,
  successOnFormAction: false,
  successOnEditAction: false,
  error: "",
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_LIST_START:
      return { ...state, loading: true };
    case CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_ACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        celupagoUsers: action.payload.celupagoUsers,
        celupagoUserRows: action.payload.celupagoUserRows,
      };
    case CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_ACTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CELUPAGO_USER_ACTIONS.FORM_ACTION_START:
      return { ...state, loading: true };
    case CELUPAGO_USER_ACTIONS.FORM_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        successOnFormAction: true,
      };
    case CELUPAGO_USER_ACTIONS.FORM_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_BY_ID_START:
      return { ...state, loading: true };
    case CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        celupagoUser: action.payload.celupagoUser,
      };
    case CELUPAGO_USER_ACTIONS.FETCH_CELUPAGO_USER_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CELUPAGO_USER_ACTIONS.CELUPAGO_USER_EDIT_ACTION_START:
      return { ...state, loading: true };
    case CELUPAGO_USER_ACTIONS.CELUPAGO_USER_EDIT_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        successOnEditAction: true,
      };
    case CELUPAGO_USER_ACTIONS.CELUPAGO_USER_EDIT_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CELUPAGO_USER_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: action.payload };
    case CELUPAGO_USER_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case CELUPAGO_USER_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
