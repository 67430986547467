import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Clear from "../../../assets/icons/close-x.png";
import {
  DateFilterContainer,
  ClearButtonContainer,
  ClearImage,
} from "./styles";

type Props = {
  value?: string;
  label: string;
  handleDateChange: (date: any) => void;
  clearFilter: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "red",
      borderWidth: 0,
      width: 170,
    },
    textField: {
      width: 170,
      fontSize: 12,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      "& input::-webkit-clear-button": {
        display: "none",
      },
    },
  })
);

const DateFilter = ({ value, label, handleDateChange, clearFilter }: Props) => {
  const classes = useStyles();
  const maxDate = moment().format().split("T")[0];
  const firefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

  return (
    <DateFilterContainer>
      <TextField
        id="date"
        type="date"
        label={label}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        value={value}
        InputProps={{
          disableUnderline: true,
          inputProps: { max: maxDate },
        }}
        onChange={(event) => handleDateChange(event.target.value)}
      />
      <ClearButtonContainer firefox={firefox}>
        {value && (
          <ClearImage src={Clear} alt="x" onClick={() => clearFilter()} />
        )}
      </ClearButtonContainer>
    </DateFilterContainer>
  );
};

export default DateFilter;
