import styled from "styled-components";
import { Theme } from "../../../utils/theme";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 90px;
  margin-top: 50px;
`;

export const Logo = styled.img`
  width: 120.6px;
  height: 87.3px;
  object-fit: contain;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 70px;
`;

export const Title = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.3px;
  color: #ffffff;
`;

export const InputSection = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const RememberSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

export const RememberText = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: ${Theme.colors.colorLight};
  margin-left: 10px;
`;

export const Check = styled.img`
  width: 17px;
  height: 18px;
  margin-left: -200px;
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  margin-bottom: 10px;

`;

export const ErrorText = styled.p`
  margin-top: 20px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #fd2d00;
  text-align: center;
`;
