import styled from "styled-components";
import { Theme } from "../../../utils/theme";

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  font-weight: bold;
`;
export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`;
