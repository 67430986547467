import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BackButton from "../../../common/BackButton";
import InputLight from "../../../common/InputLight";
import Dropdown from "../../../common/Dropdown";
import Button from "../../../common/Button";
import Loader from "../../../common/Loader";
import Modal from "../../../common/Modal";
import { STATUS_TYPES } from "../../../../utils/ConstHelper";
import { DocType } from "../../../../utils/DataType";
import { globalStateType } from "../../../../ducks/reducers";
import {
  cleanDocTypeState,
  editDocType,
} from "../../../../ducks/actions/DocTypesActions";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  selectedDocument: DocType;
  loading: boolean;
  success: boolean;
  error: string;
  editDocumentPress: () => void;
  cleanDocTypeState: () => void;
  editDocType: (id: number, newDocTypeStatus: string) => void;
};

const EditDocument = ({
  loading,
  success,
  cleanDocTypeState,
  editDocType,
  editDocumentPress,
  selectedDocument,
  error,
}: Props) => {
  const { description, docType, status, country, id } = selectedDocument;
  const [visibleModal, setVisibleModal] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  useEffect(() => {
    setNewStatus(status);
  }, [status]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const handleNewStatusChange = (event: any) => {
    setNewStatus(event.target.value);
  };

  const hideModal = () => {
    setVisibleModal(false);
    cleanDocTypeState();
    editDocumentPress();
  };

  const handleOnButtonClick = () => {
    editDocType(id, newStatus);
  };

  const enableButton = () => {
    if (status !== newStatus) return false;
    return true;
  };
  const renderLoader = () => loading && <Loader />;
  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={editDocumentPress} />
      </BackButtonContainer>
      <Container info>
        <Title>Editar tipo documento de identidad</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Descripción tipo de documento</Title>
            <InputLight
              id="descriptionDocument"
              type="text"
              disabled
              value={description || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <InputLight
              id="country"
              type="text"
              disabled
              value={country.name || ""}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Siglas documento</Title>
            <InputLight
              id="documentAcronym"
              type="text"
              disabled
              value={docType || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Estatus</Title>
            <Dropdown
              id={"status"}
              defaultValue={status}
              options={STATUS_TYPES}
              value={newStatus || ""}
              onChange={handleNewStatusChange}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};
const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.docType.loading,
    success: state.docType.successOnEditAction,
    error: state.docType.error,
  };
};

export default connect(mapStateTopProps, {
  cleanDocTypeState,
  editDocType,
})(EditDocument);
