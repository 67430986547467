import React, { useState } from "react";
import ClientList from "./ClientList";
import EditClient from "./EditClient";

const Client = () => {
  const [editClientPress, handleEditClientPress] = useState(false);
  const [selectedClient, handleSelectedClient] = useState();

  return (
    <>
      {!editClientPress ? (
        <ClientList
          editClientPress={() => handleEditClientPress(!editClientPress)}
          selectedClient={(client) => handleSelectedClient(client)}
        />
      ) : (
        <EditClient
          selectedClient={selectedClient}
          editClientPress={() => handleEditClientPress(!editClientPress)}
        />
      )}
    </>
  );
};

export default Client;
