import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";
import { dispatchUnauthorizedError } from "./UserActions";
import { getCountryRowData, getCountry } from "../../utils/Formatters";

const base = "COUNTRY_ACTIONS_";
const requestManager = new RequestManager();

export const COUNTRY_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",

  FETCH_COUNTRY_LIST_START: base + "FETCH_COUNTRY_LIST_START",
  FETCH_COUNTRY_LIST_SUCCESS: base + "FETCH_COUNTRY_LIST_SUCCESS",
  FETCH_COUNTRY_LIST_FAIL: base + "FETCH_COUNTRY_LIST_FAIL",

  FETCH_ACTIVE_COUNTRIES_START: base + "FETCH_ACTIVE_COUNTRIES_START",
  FETCH_ACTIVE_COUNTRIES_SUCCESS: base + "FETCH_ACTIVE_COUNTRIES_SUCCESS",
  FETCH_ACTIVE_COUNTRIES_FAIL: base + "FETCH_ACTIVE_COUNTRIES_FAIL",

  FETCH_COUNTRY_BY_ID_START: base + "FETCH_COUNTRY_BY_ID_START",
  FETCH_COUNTRY_BY_ID_SUCCESS: base + "FETCH_COUNTRY_BY_ID_SUCCESS",
  FETCH_COUNTRY_BY_ID_FAIL: base + "FETCH_COUNTRY_BY_ID_FAIL",

  FORM_ACTION_START: base + "FORM_ACTION_START",
  FORM_ACTION_SUCCESS: base + "FORM_ACTION_SUCCESS",
  FORM_ACTION_FAIL: base + "FORM_ACTION_FAIL",
};

export const getAllCountries = (status?: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: COUNTRY_ACTIONS.FETCH_COUNTRY_LIST_START });
    let url = `/country?`;
    if (status) {
      url = url + `&status=${status}`;
    }

    try {
      const response = await requestManager.get(url, {});
      const countriesUnformatted = response.data;
      const countries = countriesUnformatted.map((country: any) => {
        return getCountry(country);
      });
      const countryRows = countriesUnformatted.map((country: any) => {
        return getCountryRowData(country);
      });
      dispatch({
        type: COUNTRY_ACTIONS.FETCH_COUNTRY_LIST_SUCCESS,
        payload: { countries, countryRows },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: COUNTRY_ACTIONS.FETCH_COUNTRY_LIST_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getAvailableCountries = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: COUNTRY_ACTIONS.FETCH_ACTIVE_COUNTRIES_START });
    let url = `/country?status=ACTIVE`;
    try {
      const response = await requestManager.get(url, {});
      const activeCountries = response.data;

      const availableCountries = activeCountries.map((country: any) => {
        const docTypes = country.docTypes
          .filter((docType: any) => docType.doc_type_status === "ACTIVE")
          .map((doc: any) => {
            return { id: doc.id, value: doc.id, label: doc.code };
          });
        return {
          id: country.id,
          value: country.id,
          label: country.name,
          callingCode: {
            id: country.calling_code,
            value: country.calling_code,
            label: country.calling_code,
          },
          docTypes,
        };
      });

      dispatch({
        type: COUNTRY_ACTIONS.FETCH_ACTIVE_COUNTRIES_SUCCESS,
        payload: { availableCountries },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: COUNTRY_ACTIONS.FETCH_ACTIVE_COUNTRIES_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const createNewCountry = (
  name: string,
  iso: string,
  callingCode: string,
  currency: string,
  maxAmount: number,
  minAmount: number,
  rate: number,
  saving: number
) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: COUNTRY_ACTIONS.FORM_ACTION_START });
    const data = {
      name,
      iso,
      callingCode,
      currency,
      maxAmount,
      minAmount,
      rate,
      saving,
    };
    try {
      await requestManager.post(`country/create`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch({ type: COUNTRY_ACTIONS.FORM_ACTION_SUCCESS });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: COUNTRY_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const cleanCountryState = () => {
  return {
    type: COUNTRY_ACTIONS.CLEAN_STATE,
  };
};

export const disableCountry = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: COUNTRY_ACTIONS.FORM_ACTION_START });

    const data = {
      countryStatus: "INACTIVE",
    };
    try {
      await requestManager.patch(`country/${id}/status`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch(getAllCountries("ACTIVE"));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: COUNTRY_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getCountryId = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: COUNTRY_ACTIONS.FETCH_COUNTRY_BY_ID_START });
    let url = `/country/${id}/`;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });
      console.log(response.data);
      const countryFormatted = getCountry(response.data);

      dispatch({
        type: COUNTRY_ACTIONS.FETCH_COUNTRY_BY_ID_SUCCESS,
        payload: { country: countryFormatted },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: COUNTRY_ACTIONS.FETCH_COUNTRY_BY_ID_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const editCountry = (
  id: number,
  newMinAmount: string,
  newMaxAmount: string,
  newRate: string,
  newSaving: string,
  newStatus: string,
  newExchangeRate: string,
  newExchangeRateUsd: string,
  newManualRateEnable: string
) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: COUNTRY_ACTIONS.FORM_ACTION_START });

    const data = {
      rate: newRate,
      max_amount: newMaxAmount,
      min_amount: newMinAmount,
      saving: newSaving,
      country_status: newStatus,
      exchange_rate: newExchangeRate,
      exchange_rate_enable: newManualRateEnable,
      exchange_rate_usd_destiny: newExchangeRateUsd,
    };
    try {
      await requestManager.put(`/country/${id}/update`, data, {
        Authorization: `Bearer ${accessToken}`,
      });

      dispatch({
        type: COUNTRY_ACTIONS.FORM_ACTION_SUCCESS,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: COUNTRY_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};
