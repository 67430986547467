import React, { useState } from "react";
import CashierContainer from "./CashierContainer";
import CashierList from "./CashierList";
import CashierTab from "./CashierTab";

const Cashier = () => {
  const [newCashier, handleNewCashierPress] = useState(false);
  const [editCashier, handleEditCashierPress] = useState(false);
  const [selectedCashier, handleSelectedCashier] = useState();

  return (
    <>
      {!newCashier ? (
        !editCashier ? (
          <CashierList
            newCashierPress={() => handleNewCashierPress(!newCashier)}
            editCashierPress={() => handleEditCashierPress(!editCashier)}
            selectedCashier={(cashier) => handleSelectedCashier(cashier)}
          />
        ) : (
          <CashierTab
            selectedCashier={selectedCashier}
            editCashierPress={() => handleEditCashierPress(!editCashier)}
          />
        )
      ) : (
        <CashierContainer
          newCashierPress={() => handleNewCashierPress(!newCashier)}
        />
      )}
    </>
  );
};

export default Cashier;
