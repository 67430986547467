import React, { useState } from "react";
import OperationList from "./OperationList";
import EditOperation from "./EditOperation";
import { Operation } from "../../../utils/DataType";

const Operations = () => {
  const [editOperationPress, handleEditOperationPress] = useState(false);
  const [selectedOperation, handleSelectedOperation] = useState(
    undefined as undefined | Operation
  );

  return (
    <>
      {!editOperationPress ? (
        <OperationList
          editOperationPress={() =>
            handleEditOperationPress(!editOperationPress)
          }
          selectedOperation={(operation) => handleSelectedOperation(operation)}
        />
      ) : (
        selectedOperation && (
          <EditOperation
            selectedOperation={selectedOperation}
            editOperationPress={() =>
              handleEditOperationPress(!editOperationPress)
            }
          />
        )
      )}
    </>
  );
};

export default Operations;
