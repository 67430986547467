import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  background-color: ${Theme.colors.colorPrimary};
`;

export const ContainerProfile = styled.div`
  display: flex;
  height: 140px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid gray;
`;

export const LogoProfile = styled.img`
  width: 53.9px;
  height: 53.9px;
  object-fit: contain;
  margin-left: -25%;
`;

export const ContainerInfo = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  ${(props) =>
    props.isOption &&
    css`
      font-size: 18px;
    `};

  ${(props) =>
    props.subTitle &&
    css`
      opacity: 0.48;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.17;
      color: #d1cece;
    `};
`;

export const ContainerOptions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerOption = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Theme.colors.colorSecondary};
  }
  ${(props) =>
    props.isOption &&
    css`
      height: 35px;
    `};
`;

export const LogoOption = styled.img`
  width: 31px;
  height: 24.8px;
  object-fit: contain;
  margin-left: 20px;
  margin-right: 10px;
  ${(props) =>
    props.userProfile &&
    css`
      height: 31px;
    `};

  ${(props) =>
    props.chevron &&
    css`
      margin-left: 70px;
    `};
  ${(props) =>
    props.isOption &&
    css`
      margin-left: 30px;
      width: 24px;
      height: 24px;
    `};
`;
