import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../../../common/Table";
import Loader from "../../../common/Loader";
import OptionFilter from "../../../common/OptionFilter";
import SearchButton from "../../../common/SearchButton";
import Modal from "../../../common/Modal";
import Edit from "../../../../assets/icons/edit-purple.png";
import { CLIENT_TABLE, CLIENT_TYPE } from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import { getAllClients } from "../../../../ducks/actions/ClientActions";
import {
  ClientRow,
  ClientCountryFilter,
  Client,
} from "../../../../utils/DataType";
import {
  Container,
  Title,
  TableContainer,
  EditIcon,
  FilterContainer,
} from "../styles";

type Props = {
  loading: boolean;
  error: string;
  clients: Client[];
  clientRows: ClientRow[];
  countryOption: ClientCountryFilter[];
  editClientPress: () => void;
  selectedClient: (id: number) => void;
  getAllClients: (country?: string, cashierType?: string) => void;
};

const ClientList = ({
  getAllClients,
  loading,
  clientRows,
  countryOption,
  editClientPress,
  selectedClient,
  error,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [clientType, setClientType] = useState({
    value: "",
    label: "",
    id: 0,
  });
  const [clientCountry, setClientCountry] = useState({
    value: "",
    label: "",
    id: 0,
  });

  useEffect(() => {
    getAllClients();
  }, [getAllClients]);

  useEffect(() => {
    if (error) {
      setVisibleModal(true);
    }
  }, [error]);
  const filteredSearch = () => {
    if (clientCountry.value || clientType.value) {
      getAllClients(clientCountry.value, clientType.value);
    } else {
      getAllClients();
    }
  };

  const handleEditClick = (client: Client) => {
    editClientPress();
    selectedClient(client.id);
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  const renderLoader = () => loading && <Loader />;

  const optionComponents = [<EditIcon src={Edit} />];

  return (
    <Container tableView>
      {renderLoader()}
      <Container info>
        <Title>Clientes</Title>
      </Container>
      <TableContainer>
        <FilterContainer>
          <OptionFilter
            value={clientCountry}
            handleFilterChange={(option) => {
              setClientCountry(option);
            }}
            label={"País"}
            options={countryOption}
            clearFilter={() =>
              setClientCountry({
                value: "",
                label: "",
                id: 0,
              })
            }
          />
          <OptionFilter
            value={clientType}
            handleFilterChange={(option) => {
              setClientType(option);
            }}
            label={"Tipo de cliente"}
            options={CLIENT_TYPE}
            clearFilter={() =>
              setClientType({
                value: "",
                label: "",
                id: 0,
              })
            }
          />
          <SearchButton onClick={filteredSearch} />
        </FilterContainer>
        <Table
          tableColumns={CLIENT_TABLE}
          dataRows={clientRows}
          optionComponents={optionComponents}
          onOptionOneClick={handleEditClick}
        />
      </TableContainer>
      <Modal
        show={visibleModal}
        title={""}
        body={"Ha ocurrido un error, inténtalo nuevamente"}
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.client.loading,
    error: state.client.error,
    clients: state.client.clients,
    clientRows: state.client.clientRows,
    countryOption: state.client.countryOption,
  };
};

export default connect(mapStateTopProps, { getAllClients })(ClientList);
