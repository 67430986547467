import React, { ReactElement } from "react";
import Eye from "../../../assets/icons/eye.png";
import EyeSlashed from "../../../assets/icons/eye-slash-yellow.png";
import { InputContainer, EyeIcon, InputComponent, Label } from "./styles";

type Props = {
  visible?: boolean;
  security?: boolean;
  type: string;
  maxLength: number;
  minLength: number;
  value?: string;
  placeholder?: string;
  label?: string;
  disable?: boolean;
  onClick?: () => void;
  onChangeValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

const Input = ({
  visible,
  type,
  security = false,
  maxLength,
  minLength,
  placeholder = "",
  label = "",
  onClick,
  onChangeValue,
  onBlur,
  onFocus,
  value,
  disable,
}: Props): ReactElement => {
  return (
    <InputContainer>
      {security ? (
        <React.Fragment>
          {label && <Label>{label}</Label>}
          <InputComponent
            type={visible ? "text" : "password"}
            placeholder={placeholder}
            onChange={onChangeValue}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            minLength={minLength}
            maxLength={maxLength}
          />
          <EyeIcon
            src={visible ? EyeSlashed : Eye}
            alt="eye"
            onClick={onClick}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {label && <Label>{label}</Label>}
          <InputComponent
            disabled={disable}
            type={type}
            placeholder={placeholder}
            onChange={onChangeValue}
            onBlur={onBlur}
            value={value}
            minLength={minLength}
            maxLength={maxLength}
            autoComplete="new-password"
          />
        </React.Fragment>
      )}
    </InputContainer>
  );
};

export default Input;
