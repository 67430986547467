import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 5px;
  ${(props) =>
    props.profile &&
    css`
      width: 38%;
    `};
`;

export const HelpContainer = styled.div`
  flex-direction: row;
  width: 200px;
`;

export const LogoCheckCircle = styled.img`
  width: 11.6px;
  height: 11.6px;
  object-fit: contain;
  margin-right: 5px;
  margin-left: 5px;
`;

export const Title = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  font-weight: normal;
  font-size: 11px;
  color: #aaaaaa;
`;
