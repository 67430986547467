import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../Button";
import { Operation } from "../../../utils/DataType";
import {
  DialogTitleContainer,
  ResumeTitle,
  DataRow,
  DataLabel,
  DataValue,
} from "./styles";

type Props = {
  open: boolean;
  handleClose: () => void;
  transaction?: Operation;
};

const TransactionDetail = ({ open, handleClose, transaction }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <DialogTitleContainer>
          <ResumeTitle className="dialog-title">
            Resumen de transacción
          </ResumeTitle>
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        {transaction && (
          <>
            <DataRow>
              <DataLabel>Nº de Transacción</DataLabel>
              <DataValue>{transaction.celupagoReference}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Banco país origen</DataLabel>
              <DataValue>{transaction.cashierAccount.bankName}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Referencia Bancaria</DataLabel>
              <DataValue>{transaction.bankReference}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>ID cajero</DataLabel>
              <DataValue>{transaction.cashier.cashierId}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Nombre cajero</DataLabel>
              <DataValue>
                {transaction.cashier.name + " " + transaction.cashier.lastName}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Email cajero</DataLabel>
              <DataValue>{transaction.cashier.email}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Email Beneficiario</DataLabel>
              <DataValue>{transaction.user.email}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Beneficiario orígen</DataLabel>
              <DataValue>
                {transaction.user.name + " " + transaction.user.lastName}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Beneficiario destino</DataLabel>
              <DataValue>{transaction.clientAccount.accountHolder}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Fecha de emisión</DataLabel>
              <DataValue>{transaction.emissionDate}</DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Monto orígen</DataLabel>
              <DataValue>
                {transaction.originAmount} {transaction.originCurrency}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Monto destino</DataLabel>
              <DataValue>
                {transaction.destinationAmount}{" "}
                {transaction.destinationCurrency}
              </DataValue>
            </DataRow>

            <DataRow>
              <DataLabel highlightColor>Estatus</DataLabel>
              <DataValue highlightColor>
                {transaction.status.status.charAt(0).toUpperCase() +
                  transaction.status.status.slice(1).toLowerCase()}
              </DataValue>
            </DataRow>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <DialogTitleContainer>
          <Button buttonText={"OK"} onClick={() => handleClose()} />
        </DialogTitleContainer>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionDetail;
