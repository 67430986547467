import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  max-width: 350px;
  ${(props) =>
    props.twoInRow &&
    css`
      width: 35%;
      min-width: 150px;
    `};
`;

export const ButtonComponent = styled.button`
  display: flex;
  height: 50px;
  width: 250px;
  border-radius: 63px;
  object-fit: contain;
  border: none;
  outline-style: none;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  cursor: pointer;
  background-color: ${Theme.colors.colorSecondary};
  color: ${Theme.colors.colorDisabled};
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${Theme.colors.backgroundDisable};
      color: ${Theme.colors.colorDisabled};
    `};
  ${(props) =>
    props.twoInRow &&
    css`
      width: 130px;
    `};
  ${(props) =>
    props.secondary &&
    css`
      background-color: transparent;
      border: solid 3px ${Theme.colors.colorSecondary};
      color: ${Theme.colors.black};
    `};
`;
