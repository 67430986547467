import React, { useState } from "react";
import { TagContainer, TagTitle } from "./styles";
import { Container, BackButtonContainer } from "../styles";
import EditAccountCashier from "../EditAccountCashier";
import EditCashier from "../EditCashier";
import BackButton from "../../../common/BackButton";

type Props = {
  selectedCashier: number;
  editCashierPress: () => void;
};

const CashierTab = ({ selectedCashier, editCashierPress }: Props) => {
  const [editCashier, setEditCashier] = useState(true);
  const [editAccount, setEditAccount] = useState(false);

  const renderCurrentComponent = () => {
    if (editCashier) {
      return (
        <EditCashier
          selectedCashier={selectedCashier}
          editCashierPress={editCashierPress}
        />
      );
    } else {
      return <EditAccountCashier selectedCashier={selectedCashier} />;
    }
  };

  return (
    <Container>
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={editCashierPress} wide />
      </BackButtonContainer>
      <TagContainer>
        <TagContainer
          tab
          selectedTab={editCashier}
          onClick={() => {
            setEditCashier(true);
            setEditAccount(false);
          }}
        >
          <TagTitle>Datos del cajero</TagTitle>
        </TagContainer>
        <TagContainer
          tab
          selectedTab={editAccount}
          onClick={() => {
            setEditCashier(false);
            setEditAccount(true);
          }}
        >
          <TagTitle> Datos de cuenta bancaria</TagTitle>
        </TagContainer>
      </TagContainer>
      {renderCurrentComponent()}
    </Container>
  );
};

export default CashierTab;
