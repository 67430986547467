import React, { useState } from "react";
import NewCashier from "../NewCashier";
import SummaryCashier from "../SummaryCashier";
import AccountCashier from "../AccountCashier";
import Breadcrumbs from "../../../common/Breadcrumbs";
import BackButton from "../../../common/BackButton";
import { BackButtonContainer } from "../styles";

type Props = {
  newCashierPress: () => void;
};

const CashierContainer = ({ newCashierPress }: Props) => {
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState(0);

  const handleNextStepPress = () => {
    if (currentBreadcrumb >= 0 && currentBreadcrumb < 2) {
      setCurrentBreadcrumb(currentBreadcrumb + 1);
    }
  };

  const handleBackStepPress = () => {
    if (currentBreadcrumb > 0 && currentBreadcrumb <= 2) {
      setCurrentBreadcrumb(currentBreadcrumb - 1);
    }
  };

  const renderCurrentComponent = () => {
    const components = [
      <NewCashier
        stepsNavigation={handleNextStepPress}
        newCashierPress={newCashierPress}
      />,
      <SummaryCashier stepsNavigation={handleNextStepPress} />,
      <AccountCashier newCashierPress={newCashierPress} />,
    ];
    return components[currentBreadcrumb];
  };
  return (
    <>
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={newCashierPress} wide />
      </BackButtonContainer>
      <Breadcrumbs
        handleBackPress={handleBackStepPress}
        currentStep={currentBreadcrumb}
      />
      {renderCurrentComponent()}
    </>
  );
};

export default CashierContainer;
