import React from "react";
import Trash from "../../../assets/icons/trash-can.png";
import Eye from "../../../assets/icons/eye-regular-black.png";
import Money from "../../../assets/icons/money-wave.png";

import { CardContainer, Title, IconContainer } from "./styles";

type Props = {
  onClickEye: (id: number) => void;
  onClickTrash: (id: number) => void;
  name?: string;
  account?: string;
  type?: string;
  currency?: string;
  flag?: string;
  id: number;
};

const Card = ({
  name,
  account,
  type,
  currency,
  flag,
  onClickEye,
  onClickTrash,
  id,
}: Props) => {
  return (
    <CardContainer data-testid="card-container">
      <CardContainer header>
        <Title light>
          {name} <IconContainer flag src={flag} />
        </Title>
      </CardContainer>
      <CardContainer body>
        <Title>{account}</Title>
      </CardContainer>
      <CardContainer body>
        <Title>{type}</Title>
      </CardContainer>
      <CardContainer body>
        <IconContainer src={Money} />
        <Title>{currency}</Title>
      </CardContainer>
      <CardContainer body footer>
        <IconContainer pointer src={Eye} onClick={() => onClickEye(id)} />
        <IconContainer pointer src={Trash} onClick={() => onClickTrash(id)} />
      </CardContainer>
    </CardContainer>
  );
};

export default Card;
