import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Button from "../../../common/Button";
import Dropdown from "../../../common/Dropdown";
import InputLight from "../../../common/InputLight";
import Loader from "../../../common/Loader";
import CheckPassword from "../../../common/CheckPassword";
import { CASHIER_TYPES } from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import {
  createNewCashier,
  cleanCashierState,
} from "../../../../ducks/actions/CashierActions";
import { getAvailableCountries } from "../../../../ducks/actions/CountryActions";
import {
  validateNames,
  validatePassword,
  validateEmail,
  validateNumber,
  validateFloat,
  validateNumberPoint,
  validateBlanks,
} from "../../../../utils/Validators";
import { AvailableCountry } from "../../../../utils/DataType";
import {
  Container,
  MainContainer,
  Title,
  ContainerData,
  ButtonSection,
  ContainerDropdown,
} from "../styles";
import Modal from "../../../common/Modal";

type Props = {
  loading: boolean;
  success: boolean;
  error: string;
  availableCountries: AvailableCountry[];
  stepsNavigation: () => void;
  getAvailableCountries: () => void;
  newCashierPress: () => void;
  cleanCashierState: () => void;
  createNewCashier: (
    name: string,
    lastName: string,
    email: string,
    password: string,
    rate: number,
    document: string,
    phoneNumber: string,
    phonePrefix: string,
    cashierType: string,
    contractStart: string,
    contractEnd: string,
    country: number,
    docType: number,
    dailyLimit: number,
    guarantee: number
  ) => void;
};

const NewCashier = ({
  loading,
  success,
  availableCountries,
  stepsNavigation,
  createNewCashier,
  getAvailableCountries,
  error,
  newCashierPress,
  cleanCashierState,
}: Props) => {
  const [showHelpPassword, setShowHelpPassword] = useState(false);
  const [errorModal, setVisibleErrorModal] = useState(false);
  const [role, setRole] = useState("");
  const [country, setCountry] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [docType, setDocType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [name, setName] = useState({ value: "", error: false });
  const [lastName, setLastName] = useState({ value: "", error: false });
  const [email, setEmail] = useState({ value: "", error: false });
  const [password, setPassword] = useState({ value: "", error: false });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });
  const [document, setDocument] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });
  const [dailyLimit, setDailyLimit] = useState({ value: "", error: false });
  const [guarantee, setGuarantee] = useState({ value: "", error: false });
  const [rate, setRate] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  useEffect(() => {
    getAvailableCountries();
  }, [getAvailableCountries]);

  useEffect(() => {
    if (success) {
      stepsNavigation();
    }
    if (error) {
      setVisibleErrorModal(true);
    }
  }, [success, error, stepsNavigation]);

  const handleRoleChange = (event: any) => {
    setRate({ value: "", error: false, errorMessage: "" });
    setRole(event.target.value);
  };
  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
  };

  const handlePhonePrefixChange = (event: any) => {
    setPhonePrefix(event.target.value);
  };

  const handleDocTypeChange = (event: any) => {
    setDocType(event.target.value);
  };

  const handleStartDateChange = (event: any) => {
    const date = event.target.value;
    if (finishDate) {
      setStartDate(date <= finishDate ? date : "");
    } else {
      setStartDate(date);
    }
  };

  const handleFinishDateChange = (event: any) => {
    const date = event.target.value;
    if (startDate) {
      setFinishDate(date >= startDate ? date : "");
    } else {
      setFinishDate(date);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setName({ ...name, value });
    }
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setLastName({ ...lastName, value });
    }
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    if (!validateBlanks(value)) {
      setEmail({ ...email, value });
      handleEmailOnBlur();
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!validateBlanks(value)) {
      if (validatePassword(value))
        setPassword({ ...password, value, error: false });
      else setPassword({ ...password, value, error: true });
    }
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNumber(value)) setPhoneNumber({ ...phoneNumber, value });
    else {
      if (value.length === 0) setPhoneNumber({ ...phoneNumber, value });
    }
  };
  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (validateNumber(value)) setDocument({ ...document, value });
    else {
      if (value.length === 0) setDocument({ ...document, value });
    }
  };

  const handleDailyLimitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNumber(value)) setDailyLimit({ ...dailyLimit, value });
    else {
      if (value.length === 0) setDailyLimit({ ...dailyLimit, value });
    }
  };

  const handleGuaranteeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNumber(value)) setGuarantee({ ...guarantee, value });
    else {
      if (value.length === 0) setGuarantee({ ...guarantee, value });
    }
  };

  const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (validateNumberPoint(value)) setRate({ ...rate, value });
    else {
      if (value.length === 0) setRate({ ...rate, value });
    }
  };

  const handleRateOnBlur = () => {
    if (rate.value) {
      if (validateFloat(rate.value)) {
        setRate({ ...rate, error: false });
      } else
        setRate({
          ...rate,
          error: true,
          errorMessage: "Formato es inválido: 0.00",
        });
    } else
      setRate({
        ...rate,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleEmailOnBlur = () => {
    if (validateEmail(email.value))
      setEmail((email) => ({ ...email, error: false }));
    else setEmail((email) => ({ ...email, error: true }));
  };

  const handlePasswordOnBlur = () => {
    if (validatePassword(password.value))
      setPassword((password) => ({ ...password, error: false }));
    else setPassword((password) => ({ ...password, error: true }));
  };

  const handleNameOnBlur = () => {
    if (name.value && name.value.length >= 3)
      setName({ ...name, error: false });
    else setName({ ...name, error: true });
  };
  const handleLastNameOnBlur = () => {
    if (lastName.value && lastName.value.length >= 3)
      setLastName({ ...lastName, error: false });
    else setLastName({ ...lastName, error: true });
  };

  const handlePhoneNumberOnBlur = () => {
    if (phoneNumber.value) {
      if (phoneNumber.value.length > 7) {
        setPhoneNumber({ ...phoneNumber, error: false });
      } else
        setPhoneNumber({
          ...phoneNumber,
          error: true,
          errorMessage: "El campo debe contener mínimo 8 caracteres",
        });
    } else
      setPhoneNumber({
        ...phoneNumber,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleDocumentOnBlur = () => {
    if (document.value) {
      if (document.value.length > 7) {
        setDocument({ ...document, error: false });
      } else
        setDocument({
          ...document,
          error: true,
          errorMessage: "El campo debe contener mínimo 8 caracteres",
        });
    } else
      setDocument({
        ...document,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleDailyOnBlur = () => {
    if (dailyLimit.value) setDailyLimit({ ...dailyLimit, error: false });
    else setDailyLimit({ ...dailyLimit, error: true });
  };

  const handleGuaranteeOnBlur = () => {
    if (guarantee.value) setGuarantee({ ...guarantee, error: false });
    else setGuarantee({ ...guarantee, error: true });
  };
  const hideErrorModal = () => {
    setVisibleErrorModal(false);
    cleanCashierState();
    newCashierPress();
  };
  const enableButton = () => {
    if (
      role &&
      country &&
      phonePrefix &&
      docType &&
      startDate &&
      finishDate &&
      name.value &&
      lastName.value &&
      password.value &&
      email.value &&
      phoneNumber.value &&
      document.value &&
      dailyLimit.value &&
      guarantee.value &&
      !name.error &&
      !lastName.error &&
      !email.error &&
      !password.error &&
      !phoneNumber.error &&
      !document.error &&
      !dailyLimit.error &&
      !guarantee.error
    )
      if (role === CASHIER_TYPES[0].value && rate.value && !rate.error)
        return false;
      else {
        if (role !== CASHIER_TYPES[0].value) return false;
        else {
          return true;
        }
      }
    else return true;
  };

  const handleOnButtonClick = () => {
    createNewCashier(
      name.value,
      lastName.value,
      email.value,
      password.value,
      Number(rate.value),
      document.value,
      phoneNumber.value,
      phonePrefix,
      role,
      startDate,
      finishDate,
      Number(country),
      Number(docType),
      Number(dailyLimit.value),
      Number(guarantee.value)
    );
  };
  const renderLoader = () => loading && <Loader />;

  const minDate = moment().format().split("T")[0];

  console.log("availableCountries", availableCountries);

  return (
    <Container>
      {renderLoader()}
      <Container info>
        <Title bold>Nuevo cajero</Title>
        <Container roleContainer>
          <Title light>Rol</Title>
          <Dropdown
            id={"role"}
            styles={"role"}
            options={CASHIER_TYPES}
            value={role}
            onChange={handleRoleChange}
          />
        </Container>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre</Title>
            <InputLight
              id="name"
              type="text"
              value={name.value}
              onChange={handleNameChange}
              error={name.error}
              onBlur={handleNameOnBlur}
              errorMessage={
                name.error && name.value.length > 0 && name.value.length < 3
                  ? "El campo debe contener mínimo 3 caracteres"
                  : name.error && name.value.length === 0
                  ? "El campo no puede estar vacío"
                  : ""
              }
              maxLength={20}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Apellido</Title>
            <InputLight
              id="lastName"
              type="text"
              value={lastName.value}
              onChange={handleLastNameChange}
              error={lastName.error}
              onBlur={handleLastNameOnBlur}
              errorMessage={
                lastName.error &&
                lastName.value.length > 0 &&
                lastName.value.length < 3
                  ? "El campo debe contener mínimo 3 caracteres"
                  : lastName.error && lastName.value.length === 0
                  ? "El campo no puede estar vacío"
                  : ""
              }
              maxLength={20}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Correo electrónico</Title>
            <InputLight
              id="email"
              type="text"
              value={email.value}
              onChange={handleEmailChange}
              onBlur={handleEmailOnBlur}
              errorMessage={
                email.error && email.value.length > 0
                  ? "La dirección de correo no es válida"
                  : email.error && email.value.length === 0
                  ? "El campo no puede estar vacío"
                  : ""
              }
              error={email.error}
              maxLength={40}
              minLength={6}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <Dropdown
              id={"country"}
              options={availableCountries}
              value={country}
              onChange={handleCountryChange}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nº teléfono</Title>
            <ContainerDropdown>
              <Dropdown
                id={"callingCode"}
                styles={"small"}
                options={
                  country
                    ? [
                        availableCountries.find(
                          (option) => option.id === Number(country)
                        )?.callingCode,
                      ]
                    : []
                }
                value={phonePrefix}
                onChange={handlePhonePrefixChange}
              />
              <InputLight
                id="phone"
                type="text"
                styles={"small"}
                value={phoneNumber.value}
                onChange={handlePhoneNumberChange}
                error={phoneNumber.error}
                onBlur={handlePhoneNumberOnBlur}
                errorMessage={
                  phoneNumber.error && phoneNumber.value.length > 0
                    ? "El campo debe contener mínimo 8 caracteres"
                    : phoneNumber.error && phoneNumber.value.length === 0
                    ? "El campo no puede estar vacío"
                    : ""
                }
                maxLength={15}
                minLength={8}
              />
            </ContainerDropdown>
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Documento de identidad</Title>
            <ContainerDropdown>
              <Dropdown
                id={"documentType"}
                styles={"small"}
                options={
                  country
                    ? availableCountries.find(
                        (option) => option.id === Number(country)
                      )?.docTypes
                    : []
                }
                value={docType}
                onChange={handleDocTypeChange}
              />
              <InputLight
                id="document"
                type="text"
                styles={"small"}
                value={document.value}
                onChange={handleDocumentChange}
                error={document.error}
                onBlur={handleDocumentOnBlur}
                errorMessage={
                  document.error && document.value.length > 0
                    ? "El campo debe contener mínimo 8 caracteres"
                    : document.error && document.value.length === 0
                    ? "El campo no puede estar vacío"
                    : ""
                }
                maxLength={15}
                minLength={8}
              />
            </ContainerDropdown>
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Contraseña</Title>
            <InputLight
              id="password"
              type="text"
              onFocus={() => setShowHelpPassword(true)}
              onBlur={() => {
                handlePasswordOnBlur();
                setShowHelpPassword(false);
              }}
              value={password.value}
              onChange={handlePasswordChange}
              errorMessage={
                password.error && password.value.length > 0
                  ? "Contraseña inválida"
                  : password.error && password.value.length === 0
                  ? "El campo no puede estar vacío"
                  : ""
              }
              error={password.error}
              maxLength={8}
              minLength={8}
            />
          </ContainerData>
          {showHelpPassword && <CheckPassword password={password.value} />}
          <ContainerData input>
            <Title subTitle>Inicio contrato</Title>
            <InputLight
              id="dateStart"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              minDate={minDate}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Fin contrato</Title>
            <InputLight
              id="dateEnd"
              type="date"
              value={finishDate}
              onChange={handleFinishDateChange}
              minDate={minDate}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto límite diario</Title>
            <InputLight
              id="dailyLimit"
              type="text"
              value={dailyLimit.value}
              onChange={handleDailyLimitChange}
              error={dailyLimit.error}
              onBlur={handleDailyOnBlur}
              errorMessage={
                dailyLimit.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={11}
              minLength={2}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto garantía</Title>
            <InputLight
              id="guarantee"
              type="text"
              value={guarantee.value}
              onChange={handleGuaranteeChange}
              error={guarantee.error}
              onBlur={handleGuaranteeOnBlur}
              errorMessage={
                guarantee.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={11}
              minLength={2}
            />
          </ContainerData>
          <ContainerData input>
            {role === CASHIER_TYPES[0].value && (
              <React.Fragment>
                <Title subTitle>Tasa de cambio</Title>
                <InputLight
                  id="rate"
                  type="text"
                  value={rate.value}
                  onChange={handleRateChange}
                  error={rate.error}
                  onBlur={handleRateOnBlur}
                  errorMessage={rate.error ? rate.errorMessage : ""}
                  maxLength={11}
                  minLength={2}
                />
              </React.Fragment>
            )}
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="CONTINUAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={errorModal}
        title={""}
        body={"Ha ocurrido un error, inténtalo nuevamente"}
        handleClose={hideErrorModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.cashier.loading,
    success: state.cashier.successOnFormAction,
    error: state.cashier.error,
    availableCountries: state.country.availableCountries,
  };
};

export default connect(mapStateTopProps, {
  createNewCashier,
  getAvailableCountries,
  cleanCashierState,
})(NewCashier);
