import { OPERATION_ACTIONS } from "../actions/OperationActions";
import { OperationRow, Operation, CryptoActive } from "../../utils/DataType";

export type operationStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  operations: [] as Operation[],
  operationRow: [] as OperationRow[],
  cryptos: [] as CryptoActive[],
  reportData: [] as any,
  loading: false,
  successOnFormAction: false,
  error: "",
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case OPERATION_ACTIONS.FETCH_OPERATION_LIST_START:
      return { ...state, loading: true };
    case OPERATION_ACTIONS.FETCH_OPERATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        operations: action.payload.operations,
        operationRow: action.payload.operationRow,
        cryptos: action.payload.cryptos,
        reportData: action.payload.reportData,
      };
    case OPERATION_ACTIONS.FETCH_OPERATION_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case OPERATION_ACTIONS.FORM_ACTION_START:
      return { ...state, loading: true };
    case OPERATION_ACTIONS.FORM_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        successOnFormAction: true,
      };
    case OPERATION_ACTIONS.FORM_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case OPERATION_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: action.payload };
    case OPERATION_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case OPERATION_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
