import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const BreadcrumbsIcon = styled.img`
  height: 26px;
  width: 26px;
  object-fit: contain;
  ${(props) =>
    props.chevron &&
    css`
      cursor: pointer;
      margin-left: -20px;
    `};
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15%;
  border: none;
`;

export const Title = styled.p`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #23232d;
`;

export const BreadcrumbSeparator = styled.div`
  display: flex;
  height: 2px;
  flex: 1;
  background-color: ${Theme.colors.backgroundDisable};
  ${(props) =>
    props.separatorOn &&
    css`
      background-color: ${Theme.colors.colorSecondary};
    `};
`;

export const BreadcrumbsAssetContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  border: none;
`;

export const Pusher = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
`;
