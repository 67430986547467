import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../../common/Button";
import Dropdown from "../../../common/Dropdown";
import InputLight from "../../../common/InputLight";
import Modal from "../../../common/Modal";
import Loader from "../../../common/Loader";
import {
  validateFloat,
  validateNumberPoint,
} from "../../../../utils/Validators";
import { CASHIER_TYPES, STATUS_TYPES } from "../../../../utils/ConstHelper";
import { CashierEdit } from "../../../../utils/DataType";
import { globalStateType } from "../../../../ducks/reducers";
import {
  getCashierId,
  editCashier,
  cleanCashierState,
  getCashierInvoicedAmount,
} from "../../../../ducks/actions/CashierActions";
import {
  Container,
  MainContainer,
  Title,
  ContainerData,
  ButtonSection,
  PurpleTitle,
} from "../styles";

type Props = {
  selectedCashier: number;
  cashier: CashierEdit;
  loading: boolean;
  success: boolean;
  error: string;
  amountTransactionComplete: number;
  getCashierId: (id: number) => void;
  cleanCashierState: () => void;
  editCashierPress: () => void;
  getCashierInvoicedAmount: (id: number) => void;
  editCashier: (
    id: number,
    newRole: string,
    newContractEnd: string,
    newGuarantee: string,
    newRate: string,
    newDailyLimit: string,
    newStatus: string
  ) => void;
};

const EditCashier = ({
  selectedCashier,
  getCashierId,
  cashier,
  loading,
  success,
  editCashier,
  cleanCashierState,
  editCashierPress,
  getCashierInvoicedAmount,
  error,
  amountTransactionComplete,
}: Props) => {
  const {
    cashierType,
    name,
    lastName,
    email,
    country,
    phoneNumber,
    phonePrefix,
    docType,
    document,
    contractEnd,
    contractStart,
    celupagoId,
    cashierStatus,
    guarantee,
    rate,
    dailyLimit,
  } = cashier;

  const [visibleModal, setVisibleModal] = useState(false);

  const [newRole, setNewRole] = useState("");
  const [newStatus, setNewStatus] = useState("");

  const [newContractEnd, setNewContractEnd] = useState("");

  const [newGuarantee, setNewGuarantee] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  const [newRate, setNewRate] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  const [newDailyLimit, setNewDailyLimit] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  useEffect(() => {
    getCashierId(selectedCashier);
    getCashierInvoicedAmount(selectedCashier);
  }, [selectedCashier, getCashierId, getCashierInvoicedAmount]);

  useEffect(() => {
    setNewRole(cashierType);
    setNewContractEnd(contractEnd);
    setNewGuarantee((newGuarantee) => ({ ...newGuarantee, value: guarantee }));
    setNewRate((newRate) => ({ ...newRate, value: rate }));
    setNewDailyLimit((newDailyLimit) => ({
      ...newDailyLimit,
      value: dailyLimit,
    }));
    setNewStatus(cashierStatus);
  }, [cashierStatus, cashierType, contractEnd, dailyLimit, guarantee, rate]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const hideModal = () => {
    setVisibleModal(false);
    cleanCashierState();
    editCashierPress();
  };

  const handleNewRoleChange = (event: any) => {
    setNewRole(event.target.value);
  };

  const handleNewStatusChange = (event: any) => {
    setNewStatus(event.target.value);
  };

  const handleNewContractEndChange = (event: any) => {
    setNewContractEnd(event.target.value);
  };

  const handleNewGuaranteeChange = (event: any) => {
    const data = event.target.value;
    if (validateNumberPoint(data)) {
      setNewGuarantee({ ...newGuarantee, value: data });
    } else {
      if (data.length === 0) setNewGuarantee({ ...newGuarantee, value: data });
    }
  };

  const handleNewRateChange = (event: any) => {
    const data = event.target.value;
    if (validateNumberPoint(data)) {
      setNewRate({ ...newRate, value: data });
    } else {
      if (data.length === 0) setNewRate({ ...newRate, value: data });
    }
  };

  const handleNewDailyLimitChange = (event: any) => {
    const data = event.target.value;
    if (validateNumberPoint(data)) {
      setNewDailyLimit({ ...newDailyLimit, value: data });
    } else {
      if (data.length === 0)
        setNewDailyLimit({ ...newDailyLimit, value: data });
    }
  };

  const handleNewGuaranteeOnBlur = () => {
    if (newGuarantee.value) {
      if (validateFloat(newGuarantee.value)) {
        setNewGuarantee({ ...newGuarantee, error: false });
      } else
        setNewGuarantee({
          ...newGuarantee,
          error: true,
          errorMessage: "Formato es inválido: 0.00",
        });
    } else
      setNewGuarantee({
        ...newGuarantee,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleNewRateOnBlur = () => {
    if (newRate.value) {
      if (validateFloat(newRate.value)) {
        setNewRate({ ...newRate, error: false });
      } else
        setNewRate({
          ...newRate,
          error: true,
          errorMessage: "Formato es inválido: 0.00",
        });
    } else
      setNewRate({
        ...newRate,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleNewDailyLimitOnBlur = () => {
    if (newDailyLimit.value) {
      if (validateFloat(newDailyLimit.value)) {
        setNewDailyLimit({ ...newDailyLimit, error: false });
      } else
        setNewDailyLimit({
          ...newDailyLimit,
          error: true,
          errorMessage: "Formato es inválido: 0.00",
        });
    } else
      setNewDailyLimit({
        ...newDailyLimit,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const enableButton = () => {
    if (
      (cashierType !== newRole ||
        contractEnd !== newContractEnd ||
        guarantee !== newGuarantee.value ||
        rate !== newRate.value ||
        dailyLimit !== newDailyLimit.value ||
        cashierStatus !== newStatus) &&
      !newGuarantee.error &&
      !newRate.error &&
      !newDailyLimit.error
    )
      return false;
    else return true;
  };
  const handleOnButtonClick = () => {
    editCashier(
      selectedCashier,
      newRole,
      newContractEnd,
      newGuarantee.value,
      newRate.value,
      newDailyLimit.value,
      newStatus
    );
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container>
      {renderLoader()}
      <Container info>
        <Title bold> Editar cajero</Title>
        <Container roleContainer>
          <Container roleContainer idCelupagos>
            <Title light>Tipo</Title>
            <Dropdown
              id={"role"}
              styles={"role"}
              options={CASHIER_TYPES}
              value={newRole || ""}
              onChange={handleNewRoleChange}
            />
          </Container>
          <Title bold>ID cajero: {celupagoId}</Title>
        </Container>
        <ContainerData infoLine>
          <PurpleTitle>Monto total usado:</PurpleTitle>
          <PurpleTitle subTitle>
            {amountTransactionComplete.toFixed(2)} USD
          </PurpleTitle>
        </ContainerData>
      </Container>

      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre</Title>
            <InputLight id="name" type="text" disabled value={name || ""} />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Apellido</Title>
            <InputLight
              id="lastName"
              type="text"
              disabled
              value={lastName || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Correo electrónico</Title>
            <InputLight id="email" type="text" disabled value={email || ""} />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <InputLight
              id="country"
              type="text"
              disabled
              value={country || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nº teléfono</Title>
            <InputLight
              id="phone"
              type="text"
              disabled
              value={`${phonePrefix}-${phoneNumber}` || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Documento de identidad</Title>
            <InputLight
              id="document"
              type="text"
              disabled
              value={`${docType}-${document}` || ""}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Inicio contrato</Title>
            <InputLight
              id="dateStart"
              type="text"
              disabled
              value={contractStart || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Fin contrato</Title>
            <InputLight
              id="dateEnd"
              type="date"
              value={newContractEnd || ""}
              onChange={handleNewContractEndChange}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto límite diario</Title>
            <InputLight
              id="dailyLimit"
              type="text"
              value={newDailyLimit.value || ""}
              onChange={handleNewDailyLimitChange}
              error={newDailyLimit.error}
              onBlur={handleNewDailyLimitOnBlur}
              errorMessage={
                newDailyLimit.error ? newDailyLimit.errorMessage : ""
              }
              maxLength={11}
              minLength={2}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto garantía</Title>
            <InputLight
              id="guarantee"
              type="text"
              value={newGuarantee.value || ""}
              onChange={handleNewGuaranteeChange}
              error={newGuarantee.error}
              onBlur={handleNewGuaranteeOnBlur}
              errorMessage={newGuarantee.error ? newGuarantee.errorMessage : ""}
              maxLength={11}
              minLength={2}
            />
          </ContainerData>
          {newRole === CASHIER_TYPES[0].value && (
            <ContainerData input>
              <Title subTitle>Tasa de cambio</Title>
              <InputLight
                id="rate"
                type="text"
                value={newRate.value || ""}
                onChange={handleNewRateChange}
                error={newRate.error}
                onBlur={handleNewRateOnBlur}
                errorMessage={newRate.error ? newRate.errorMessage : ""}
                maxLength={11}
                minLength={2}
              />
            </ContainerData>
          )}
          <ContainerData input>
            <Title subTitle>Estatus</Title>
            <Dropdown
              id={"status"}
              value={newStatus || ""}
              options={STATUS_TYPES}
              onChange={handleNewStatusChange}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="CONTINUAR"
          disabled={enableButton()}
          onClick={handleOnButtonClick}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    cashier: state.cashier.cashier,
    loading: state.cashier.loading,
    success: state.cashier.successOnFormAction,
    error: state.cashier.error,
    amountTransactionComplete: state.cashier.amountTransactionComplete,
  };
};

export default connect(mapStateTopProps, {
  getCashierId,
  editCashier,
  cleanCashierState,
  getCashierInvoicedAmount,
})(EditCashier);
