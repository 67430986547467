import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../../../common/Table";
import Loader from "../../../common/Loader";
import AddButton from "../../../common/AddButton";
import OptionFilter from "../../../common/OptionFilter";
import SearchButton from "../../../common/SearchButton";
import Modal from "../../../common/Modal";
import Edit from "../../../../assets/icons/edit-purple.png";
import Trash from "../../../../assets/icons/trash-can.png";
import { STATUS_TYPES, BANK_TABLE } from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import { BankRow, Bank, BankCountryFilter } from "../../../../utils/DataType";
import {
  getAllBanks,
  disableBank,
} from "../../../../ducks/actions/BankActions";
import {
  Container,
  Title,
  TableContainer,
  AddButtonContainer,
  FilterContainer,
  EditIcon,
  TrashIcon,
} from "../styles";

type Props = {
  loading: boolean;
  error: string;
  bankRows: BankRow[];
  banks: Bank[];
  countryOption: BankCountryFilter[];
  newBankPress: () => void;
  editBankPress: () => void;
  selectedBank: (id: number) => void;
  getAllBanks: (status?: string, country?: string) => void;
  disableBank: (id: number) => void;
};

const BankList = ({
  loading,
  bankRows,
  getAllBanks,
  countryOption,
  newBankPress,
  editBankPress,
  selectedBank,
  disableBank,
  error,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [errorModal, setVisibleErrorModal] = useState(false);
  const [registerToDisable, setRegisterToDisable] = useState(0);
  const [bankStatus, setBankStatus] = useState({ value: "", label: "", id: 0 });
  const [bankCountry, setBankCountry] = useState({
    value: "",
    label: "",
    id: 0,
  });

  useEffect(() => {
    getAllBanks("ACTIVE");
  }, [getAllBanks]);

  useEffect(() => {
    if (error) {
      setVisibleErrorModal(true);
    }
  }, [error]);

  const filteredSearch = () => {
    if (bankStatus.value || bankCountry.value) {
      getAllBanks(bankStatus.value, bankCountry.value);
    } else {
      getAllBanks();
    }
  };

  const handleEditClick = (bank: Bank) => {
    editBankPress();
    selectedBank(bank.id);
  };

  const handleTrashClick = (bank: Bank) => {
    setVisibleModal(true);
    setRegisterToDisable(bank.id);
  };

  const hideModal = () => {
    setVisibleModal(false);
    setRegisterToDisable(0);
  };

  const hideErrorModal = () => {
    setVisibleErrorModal(false);
    setRegisterToDisable(0);
  };

  const confirmModalAction = () => {
    disableBank(registerToDisable);
    setVisibleModal(false);
  };
  const renderLoader = () => loading && <Loader />;

  const optionComponents = [<EditIcon src={Edit} />, <TrashIcon src={Trash} />];

  return (
    <Container tableView>
      {renderLoader()}
      <Container info>
        <Title>Bancos</Title>
      </Container>
      <TableContainer>
        <AddButtonContainer>
          <AddButton buttonText="Nuevo" onClick={newBankPress} />
        </AddButtonContainer>
        <FilterContainer>
          <OptionFilter
            value={bankStatus}
            handleFilterChange={(option) => {
              setBankStatus(option);
            }}
            label={"Estatus"}
            options={STATUS_TYPES}
            clearFilter={() => setBankStatus({ value: "", label: "", id: 0 })}
          />
          <OptionFilter
            value={bankCountry}
            handleFilterChange={(option) => {
              setBankCountry(option);
            }}
            label={"País"}
            options={countryOption}
            clearFilter={() => setBankCountry({ value: "", label: "", id: 0 })}
          />
          <SearchButton onClick={filteredSearch} />
        </FilterContainer>
        <Table
          tableColumns={BANK_TABLE}
          dataRows={bankRows}
          optionComponents={optionComponents}
          onOptionOneClick={handleEditClick}
          onOptionTwoClick={handleTrashClick}
        />
      </TableContainer>
      <Modal
        show={visibleModal}
        title={"¿Estás seguro que deseas hacer la desactivación?"}
        body={""}
        twoOption
        confirmationOption={confirmModalAction}
        cancelOption={hideModal}
      />
      <Modal
        show={errorModal}
        title={""}
        body={"Ha ocurrido un error, inténtalo nuevamente"}
        handleClose={hideErrorModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.bank.loading,
    error: state.bank.error,
    banks: state.bank.banks,
    bankRows: state.bank.bankRows,
    countryOption: state.bank.countryOption,
  };
};

export default connect(mapStateTopProps, { getAllBanks, disableBank })(
  BankList
);
