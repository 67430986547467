import styled, { css } from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 90px;
`;

export const ContentLogo = styled.a`
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 195px;
  height: 58.4px;
  object-fit: contain;
  ${(props) =>
    props.icon &&
    css`
      position: absolute;
      width: 31.6px;
      height: 23.7px;
      margin-left: 35%;
      cursor: pointer;
    `};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
