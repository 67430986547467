import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  background-color: white;
  ${(props) =>
    props.darkTheme &&
    css`
      background-color: ${Theme.colors.colorPrimary};
    `};

  ${(props) =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `};
`;
