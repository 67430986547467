import { USER_ACTIONS } from "../actions/UserActions";

export type userStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  id: undefined as undefined | number,
  name: "",
  lastName: "",
  email: "",
  role: "",
  loading: false,
  successOnFormAction: false,
  error: "",
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case USER_ACTIONS.LOG_IN_START:
      return { ...state, loading: true };
    case USER_ACTIONS.LOG_IN_SUCCESS:
      return { ...state, loading: false, error: "" };
    case USER_ACTIONS.LOG_IN_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_ACTIONS.SET_USER_PROFILE:
      return {
        ...state,
        name: action.payload.name,
        lastName: action.payload.lastName,
        email: action.payload.email,
        role: action.payload.role,
        id: action.payload.id,
        loading: false,
      };

    case USER_ACTIONS.FORM_ACTION_START:
      return { ...state, loading: true };
    case USER_ACTIONS.FORM_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successOnFormAction: true,
        error: "",
      };
    case USER_ACTIONS.FORM_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case USER_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, loading: false, error: "UNAUTHORIZED" };
    case USER_ACTIONS.CLEAR_SUCCESS:
      return { ...state, successOnFormAction: false, error: "" };
    case USER_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case USER_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
