import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";
import { dispatchUnauthorizedError } from "./UserActions";
import {
  getDocTypeRowData,
  getDocType,
  getDocTypeFilter,
} from "../../utils/Formatters";

const base = "DOC_TYPE_ACTIONS_";
const requestManager = new RequestManager();

export const DOC_TYPE_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",

  FETCH_DOC_TYPE_LIST_START: base + "FETCH_DOC_TYPE_LIST_START",
  FETCH_DOC_TYPE_LIST_SUCCESS: base + "FETCH_DOC_TYPE_LIST_SUCCESS",
  FETCH_DOC_TYPE_LIST_FAIL: base + "FETCH_DOC_TYPE_LIST_FAIL",

  DOC_TYPE_EDIT_ACTION_START: base + "DOC_TYPE_EDIT_ACTION_START",
  DOC_TYPE_EDIT_ACTION_SUCCESS: base + "DOC_TYPE_EDIT_ACTION_SUCCESS",
  DOC_TYPE_EDIT_ACTION_FAIL: base + "DOC_TYPE_EDIT_ACTION_FAIL",

  FORM_ACTION_START: base + "FORM_ACTION_START",
  FORM_ACTION_SUCCESS: base + "FORM_ACTION_SUCCESS",
  FORM_ACTION_FAIL: base + "FORM_ACTION_FAIL",
};

export const getAllDocTypes = (status?: string, country?: string) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: DOC_TYPE_ACTIONS.FETCH_DOC_TYPE_LIST_START });
    let url = `/docType?`;
    if (status) {
      url = url + `&status=${status}`;
    }
    if (country) {
      url = url + `&country=${country}`;
    }
    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });
      const docTypesUnformatted = response.data;
      let countries: any[] = [];
      const docTypes = docTypesUnformatted.map((docType: any) => {
        const document = getDocType(docType);
        countries.push(document.country);
        return document;
      });
      const docTypeRows = docTypesUnformatted.map((docType: any) => {
        return getDocTypeRowData(docType);
      });

      const countryOption = Array.from(
        new Set(countries.map((cItem) => cItem.id))
      ).map((id) => {
        return getDocTypeFilter(countries.find((c) => c.id === id));
      });

      dispatch({
        type: DOC_TYPE_ACTIONS.FETCH_DOC_TYPE_LIST_SUCCESS,
        payload: { docTypes, docTypeRows, countryOption },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: DOC_TYPE_ACTIONS.FETCH_DOC_TYPE_LIST_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const createNewDocType = (
  name: string,
  code: string,
  country: number
) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: DOC_TYPE_ACTIONS.FORM_ACTION_START });
    const data = {
      name,
      code,
      country,
    };
    try {
      await requestManager.post(`docType/create`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch({ type: DOC_TYPE_ACTIONS.FORM_ACTION_SUCCESS });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: DOC_TYPE_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const cleanDocTypeState = () => {
  return {
    type: DOC_TYPE_ACTIONS.CLEAN_STATE,
  };
};

export const disableDocType = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: DOC_TYPE_ACTIONS.FORM_ACTION_START });

    const data = {
      docTypeStatus: "INACTIVE",
    };
    try {
      await requestManager.patch(`docType/${id}/status`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch(getAllDocTypes("ACTIVE"));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: DOC_TYPE_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const editDocType = (id: number, newDocTypeStatus: string) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: DOC_TYPE_ACTIONS.DOC_TYPE_EDIT_ACTION_START });

    const data = {
      docTypeStatus: newDocTypeStatus,
    };
    try {
      await requestManager.patch(`/docType/${id}/status`, data, {
        Authorization: `Bearer ${accessToken}`,
      });

      dispatch({
        type: DOC_TYPE_ACTIONS.DOC_TYPE_EDIT_ACTION_SUCCESS,
        payload: {},
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: DOC_TYPE_ACTIONS.DOC_TYPE_EDIT_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};
