import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BackButton from "../../../common/BackButton";
import InputLight from "../../../common/InputLight";
import Button from "../../../common/Button";
import Modal from "../../../common/Modal";
import Dropdown from "../../../common/Dropdown";
import Loader from "../../../common/Loader";
import { Operation, CryptoActive } from "../../../../utils/DataType";
import {
  validateFloat,
  validateNumberPoint,
} from "../../../../utils/Validators";
import {
  OPERATION_STATUS_BY_ADMIN,
  OPERATION_STATUS_BY_AGENT,
  CELUPAGO_USER_ROLE,
} from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import {
  updateStatusOperation,
  clearOperationState,
  updateCryptoOperation,
} from "../../../../ducks/actions/OperationActions";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  selectedOperation: Operation;
  role: string;
  loading: boolean;
  success: boolean;
  error: string;
  cryptos: CryptoActive[];
  editOperationPress: () => void;
  clearOperationState: () => void;
  updateStatusOperation: (status: string, id: number) => void;
  updateCryptoOperation: (
    id: number,
    status: string,
    crypto?: string,
    cryptoAmount?: number,
    destinationCrypto?: string,
    destinationCryptoAmount?: number
  ) => void;
};

const EditOperation = ({
  selectedOperation,
  editOperationPress,
  updateStatusOperation,
  clearOperationState,
  role,
  loading,
  success,
  cryptos,
  updateCryptoOperation,
  error,
}: Props) => {
  const {
    clientAccount,
    cashierAccount,
    celupagoReference,
    emissionDate,
    bankReference,
    exchangeType,
    status,
    destinationAmount,
    destinationCurrency,
    crypto,
    cryptoAmount,
    destinationCrypto,
    destinationCryptoAmount,
    id,
  } = selectedOperation;
  const [visibleModal, setVisibleModal] = useState(false);
  const [newStatus, setNewStatus] = useState(status.code);
  const [cryptoActive, setCryptoActive] = useState(
    crypto ? crypto.id.toString() : ""
  );
  const [newCryptoAmount, setNewCryptoAmount] = useState({
    value: cryptoAmount,
    error: false,
    errorMessage: "",
  });

  const [destinationCryptoActive, setDestinationCryptoActive] = useState(
    destinationCrypto ? destinationCrypto.id.toString() : ""
  );
  const [newDestinationCryptoAmount, setNewDestinationCryptoAmount] = useState({
    value: destinationCryptoAmount,
    error: false,
    errorMessage: "",
  });

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const handleCryptoCurrencyChange = (event: any) => {
    setCryptoActive(event.target.value);
  };

  const handleDestinationCryptoCurrencyChange = (event: any) => {
    setDestinationCryptoActive(event.target.value);
  };

  const handleNewStatusChange = (event: any) => {
    setNewStatus(event.target.value);
    if (
      !(
        event.target.value === "CE" ||
        event.target.value === "CR" ||
        event.target.value === "CRZ" ||
        event.target.value === "VR" ||
        event.target.value === "CT"
      )
    ) {
      setNewCryptoAmount({ ...newCryptoAmount, value: "0.000000000" });
      setCryptoActive("");
    }
  };

  const handleCryptoAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNumberPoint(value)) {
      setNewCryptoAmount({ ...newCryptoAmount, value });
    } else {
      if (value.length === 0) setNewCryptoAmount({ ...newCryptoAmount, value });
    }
  };
  const handleDestinationCryptoAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNumberPoint(value)) {
      setNewDestinationCryptoAmount({ ...newDestinationCryptoAmount, value });
    } else {
      if (value.length === 0)
        setNewDestinationCryptoAmount({ ...newDestinationCryptoAmount, value });
    }
  };
  const handleCryptoAmountOnBlur = () => {
    if (newCryptoAmount.value) {
      if (validateFloat(newCryptoAmount.value, true, false)) {
        setNewCryptoAmount({ ...newCryptoAmount, error: false });
      } else
        setNewCryptoAmount({
          ...newCryptoAmount,
          error: true,
          errorMessage: "Formato es inválido: 0.0",
        });
    } else
      setNewCryptoAmount({
        ...newCryptoAmount,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleDestinationCryptoAmountOnBlur = () => {
    if (newCryptoAmount.value) {
      if (validateFloat(newCryptoAmount.value, true, false)) {
        setNewDestinationCryptoAmount({
          ...newDestinationCryptoAmount,
          error: false,
        });
      } else
        setNewDestinationCryptoAmount({
          ...newDestinationCryptoAmount,
          error: true,
          errorMessage: "Formato es inválido: 0.0",
        });
    } else
      setNewDestinationCryptoAmount({
        ...newDestinationCryptoAmount,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleOnButtonClick = () => {
    if (
      (cryptoActive && newCryptoAmount.value) ||
      (destinationCryptoActive && newDestinationCryptoAmount.value)
    ) {
      updateCryptoOperation(
        id,
        newStatus,
        cryptoActive,
        Number(newCryptoAmount.value),
        destinationCryptoActive,
        Number(newDestinationCryptoAmount.value)
      );
    } else {
      updateStatusOperation(newStatus, id);
    }
  };

  const enableCryptoInputs = () => {
    return (role === CELUPAGO_USER_ROLE[1].value ||
      role === CELUPAGO_USER_ROLE[2].value) &&
      !(
        status.code === OPERATION_STATUS_BY_AGENT[1].value ||
        status.code === OPERATION_STATUS_BY_AGENT[3].value
      ) &&
      (newStatus === "CE" ||
        newStatus === OPERATION_STATUS_BY_AGENT[1].value ||
        // newStatus === OPERATION_STATUS_BY_AGENT[3].value ||
        newStatus === OPERATION_STATUS_BY_AGENT[0].value)
      ? false
      : true;
  };

  const enableCryptoDestinationInputs = () => {
    return (role === CELUPAGO_USER_ROLE[1].value ||
      role === CELUPAGO_USER_ROLE[2].value ||
      role === CELUPAGO_USER_ROLE[3].value) &&
      status.code === OPERATION_STATUS_BY_AGENT[0].value &&
      newStatus === OPERATION_STATUS_BY_AGENT[2].value
      ? false
      : true;
  };

  const enableStatusChange = () => {
    return (
      status.code === OPERATION_STATUS_BY_AGENT[1].value ||
      status.code === OPERATION_STATUS_BY_AGENT[3].value
    );
  };

  const enableButton = () => {
    if (
      status.code === OPERATION_STATUS_BY_AGENT[1].value ||
      status.code === OPERATION_STATUS_BY_AGENT[3].value
    ) {
      return true;
    } else {
      if (
        (newStatus === "VD" || newStatus === "RZ" || newStatus === "DT") &&
        newStatus !== status.code
      ) {
        return false;
      }

      if (
        (newStatus === "CE" || newStatus === "CR" || newStatus === "CRZ") &&
        cryptoActive &&
        newCryptoAmount.value &&
        newCryptoAmount.value > "0.000000000" &&
        !newCryptoAmount.error &&
        (cryptoAmount !== newCryptoAmount.value ||
          status.code !== newStatus ||
          (crypto && crypto.id.toString()) !== cryptoActive)
      ) {
        return false;
      }
      if (
        newStatus === "VR" &&
        destinationCryptoActive &&
        newDestinationCryptoAmount.value &&
        newDestinationCryptoAmount.value > "0.000000000" &&
        !newDestinationCryptoAmount.error &&
        (destinationCryptoAmount !== newDestinationCryptoAmount.value ||
          status.code !== newStatus ||
          (destinationCrypto && destinationCrypto.id.toString()) !==
            destinationCryptoActive)
      ) {
        return false;
      }

      if (
        newStatus === "CT" &&
        cryptoActive &&
        destinationCryptoActive &&
        newCryptoAmount.value &&
        newCryptoAmount.value > "0.000000000" &&
        !newCryptoAmount.error &&
        newDestinationCryptoAmount.value > "0.000000000" &&
        newDestinationCryptoAmount.value &&
        !newCryptoAmount.error &&
        (cryptoAmount !== newCryptoAmount.value ||
          status.code !== newStatus ||
          (crypto && crypto.id.toString()) !== cryptoActive) &&
        (destinationCryptoAmount !== newDestinationCryptoAmount.value ||
          status.code !== newStatus ||
          (destinationCrypto && destinationCrypto.id.toString()) !==
            destinationCryptoActive)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const hideModal = () => {
    clearOperationState();
    setVisibleModal(false);
    editOperationPress();
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={editOperationPress} />
      </BackButtonContainer>
      <Container info>
        <Title>Editar operación</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Banco país destino</Title>
            <InputLight
              id="destinationBank"
              type="text"
              disabled
              defaultValue={clientAccount.bankName}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre del beneficiario</Title>
            <InputLight
              id="accountHolder"
              type="text"
              disabled
              defaultValue={`${clientAccount.accountHolder}`}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Nº de teléfono beneficiario</Title>
            <InputLight
              id="phoneNumber"
              type="text"
              disabled
              defaultValue={`${clientAccount.phoneNumber}`}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Cuenta bancaria beneficiario</Title>
            <InputLight
              id="accountNumber"
              type="text"
              disabled
              defaultValue={`${clientAccount.accountType}-${clientAccount.accountNumber}`}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Documento de Identidad</Title>
            <InputLight
              id="accountDocument"
              type="text"
              disabled
              defaultValue={`${clientAccount.docType}-${clientAccount.document}`}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto país destino</Title>
            <InputLight
              id="destinationAmount"
              type="text"
              disabled
              defaultValue={`${destinationAmount} ${destinationCurrency}`}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País origen</Title>
            <InputLight
              id="originCountry"
              type="text"
              disabled
              defaultValue={cashierAccount.country}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>País destino</Title>
            <InputLight
              id="destinationCountry"
              type="text"
              disabled
              defaultValue={clientAccount.country}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Transacción ID</Title>
            <InputLight
              id="celupagosReference"
              type="text"
              disabled
              defaultValue={celupagoReference}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Fecha de transacción</Title>
            <InputLight
              id="dateTransaction"
              type="text"
              disabled
              defaultValue={emissionDate}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tasa de cambio</Title>
            <InputLight
              id="rate"
              type="text"
              disabled
              defaultValue={exchangeType}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Referencia bancaria</Title>
            <InputLight
              id="bankReference"
              type="text"
              disabled
              defaultValue={bankReference}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tipo cripto origen</Title>
            <Dropdown
              id="cryptoCurrency"
              value={cryptoActive}
              options={cryptos}
              onChange={handleCryptoCurrencyChange}
              disable={enableCryptoInputs()}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Cantidad cripto origen</Title>
            <InputLight
              id="cryptoAmount"
              type="text"
              maxLength={11}
              disabled={enableCryptoInputs()}
              value={newCryptoAmount.value}
              error={newCryptoAmount.error}
              errorMessage={
                newCryptoAmount.error ? newCryptoAmount.errorMessage : ""
              }
              onChange={handleCryptoAmountChange}
              onBlur={handleCryptoAmountOnBlur}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tipo cripto destino</Title>
            <Dropdown
              id="destinationCryptoCurrency"
              value={destinationCryptoActive}
              options={cryptos}
              onChange={handleDestinationCryptoCurrencyChange}
              disable={enableCryptoDestinationInputs()}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Cantidad cripto destino</Title>
            <InputLight
              id="destinationCryptoAmount"
              type="text"
              maxLength={11}
              disabled={enableCryptoDestinationInputs()}
              value={newDestinationCryptoAmount.value}
              error={newDestinationCryptoAmount.error}
              errorMessage={
                newDestinationCryptoAmount.error
                  ? newDestinationCryptoAmount.errorMessage
                  : ""
              }
              onChange={handleDestinationCryptoAmountChange}
              onBlur={handleDestinationCryptoAmountOnBlur}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Estatus</Title>
            <Dropdown
              id={"status"}
              options={
                role === CELUPAGO_USER_ROLE[3].value
                  ? OPERATION_STATUS_BY_AGENT
                  : OPERATION_STATUS_BY_ADMIN
              }
              value={newStatus}
              disable={enableStatusChange()}
              onChange={handleNewStatusChange}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    role: state.user.role,
    loading: state.operation.loading,
    cryptos: state.operation.cryptos,
    success: state.operation.successOnFormAction,
    error: state.operation.error,
  };
};

export default connect(mapStateTopProps, {
  updateStatusOperation,
  clearOperationState,
  updateCryptoOperation,
})(EditOperation);
