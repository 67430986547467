import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";
import { dispatchUnauthorizedError } from "./UserActions";
import {
  getBank,
  getBakRowData,
  getBankFilter,
  getBankToDropDown,
} from "../../utils/Formatters";

const base = "BANK_ACTIONS_";
const requestManager = new RequestManager();

export const BANK_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",

  FETCH_BANK_LIST_START: base + "FETCH_BANK_LIST_START",
  FETCH_BANK_LIST_SUCCESS: base + "FETCH_BANK_LIST_SUCCESS",
  FETCH_BANK_LIST_FAIL: base + "FETCH_BANK_LIST_FAIL",

  FETCH_BANK_BY_COUNTRY_START: base + "FETCH_BANK_BY_COUNTRY_START",
  FETCH_BANK_BY_COUNTRY_SUCCESS: base + "FETCH_BANK_BY_COUNTRY_SUCCESS",
  FETCH_BANK_BY_COUNTRY_FAIL: base + "FETCH_BANK_BY_COUNTRY_FAIL",

  FETCH_BANK_BY_ID_START: base + "FETCH_BANK_BY_ID_START",
  FETCH_BANK_BY_ID_SUCCESS: base + "FETCH_BANK_BY_ID_SUCCESS",
  FETCH_BANK_BY_ID_FAIL: base + "FETCH_BANK_BY_ID_FAIL",

  BANK_EDIT_ACTION_START: base + "BANK_EDIT_ACTION_START",
  BANK_EDIT_ACTION_SUCCESS: base + "BANK_EDIT_ACTION_SUCCESS",
  BANK_EDIT_ACTION_FAIL: base + "BANK_EDIT_ACTION_FAIL",

  FORM_ACTION_START: base + "FORM_ACTION_START",
  FORM_ACTION_SUCCESS: base + "FORM_ACTION_SUCCESS",
  FORM_ACTION_FAIL: base + "FORM_ACTION_FAIL",
};

export const getAllBanks = (status?: string, country?: string) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: BANK_ACTIONS.FETCH_BANK_LIST_START });
    let url = `/bank/all?`;
    if (status) {
      url = url + `&status=${status}`;
    }
    if (country) {
      url = url + `&country=${country}`;
    }
    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });
      const bankUnformatted = response.data;
      let countries: any[] = [];
      const banks = bankUnformatted.map((bank: any) => {
        const bankFormatted = getBank(bank);
        countries.push(bankFormatted.country);
        return bankFormatted;
      });
      const bankRows = bankUnformatted.map((bank: any) => {
        return getBakRowData(bank);
      });

      const countryOption = Array.from(
        new Set(countries.map((cItem) => cItem.id))
      ).map((id) => {
        return getBankFilter(countries.find((c) => c.id === id));
      });

      dispatch({
        type: BANK_ACTIONS.FETCH_BANK_LIST_SUCCESS,
        payload: { banks, bankRows, countryOption },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: BANK_ACTIONS.FETCH_BANK_LIST_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getAllBanksByCountry = (status?: string, country?: string) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: BANK_ACTIONS.FETCH_BANK_BY_COUNTRY_START });
    let url = `/bank/all?`;
    if (status) {
      url = url + `&status=${status}`;
    }
    if (country) {
      url = url + `&country=${country}`;
    }
    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });
      const bankUnformatted = response.data;
      const banksAvailable = bankUnformatted.map((bank: any) => {
        return getBankToDropDown(bank);
      });

      dispatch({
        type: BANK_ACTIONS.FETCH_BANK_BY_COUNTRY_SUCCESS,
        payload: { banksAvailable },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: BANK_ACTIONS.FETCH_BANK_BY_COUNTRY_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const createNewBank = (
  entity_name: string,
  commercial_name: string,
  code: string,
  digits: number,
  country: number
) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: BANK_ACTIONS.FORM_ACTION_START });
    const data = {
      entity_name,
      commercial_name,
      code,
      digits,
      country,
    };
    try {
      await requestManager.post(`bank`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch({ type: BANK_ACTIONS.FORM_ACTION_SUCCESS });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: BANK_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const cleanBankState = () => {
  return {
    type: BANK_ACTIONS.CLEAN_STATE,
  };
};

export const disableBank = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: BANK_ACTIONS.FORM_ACTION_START });

    const data = {
      bankStatus: "INACTIVE",
    };
    try {
      await requestManager.patch(`bank/${id}/status`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch(getAllBanks("ACTIVE"));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: BANK_ACTIONS.FORM_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getBankId = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: BANK_ACTIONS.FETCH_BANK_BY_ID_START });
    let url = `/bank/${id}/`;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });

      const bankFormatted = getBank(response.data);

      dispatch({
        type: BANK_ACTIONS.FETCH_BANK_BY_ID_SUCCESS,
        payload: { bank: bankFormatted },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: BANK_ACTIONS.FETCH_BANK_BY_ID_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const editBank = (
  id: number,
  newCode: string,
  newStatus: string,
  newCommercialName: string
) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: BANK_ACTIONS.BANK_EDIT_ACTION_START });

    const data = {
      code: newCode,
      commercial_name: newCommercialName,
      bank_status: newStatus,
    };
    try {
      await requestManager.patch(`/bank/${id}/update`, data, {
        Authorization: `Bearer ${accessToken}`,
      });

      dispatch({
        type: BANK_ACTIONS.BANK_EDIT_ACTION_SUCCESS,
        payload: {},
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: BANK_ACTIONS.BANK_EDIT_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};
