import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BackButton from "../../../common/BackButton";
import InputLight from "../../../common/InputLight";
import Button from "../../../common/Button";
import Modal from "../../../common/Modal";
import Loader from "../../../common/Loader";
import Dropdown from "../../../common/Dropdown";
import { CLIENT_TYPE, STATUS_TYPES } from "../../../../utils/ConstHelper";
import { Client } from "../../../../utils/DataType";
import {
  getClientId,
  editClient,
  cleanClientState,
} from "../../../../ducks/actions/ClientActions";
import { globalStateType } from "../../../../ducks/reducers";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  selectedClient: number;
  client: Client;
  loading: boolean;
  success: boolean;
  error: string;
  getClientId: (id: number) => void;
  editClientPress: () => void;
  cleanClientState: () => void;
  editClient: (id: number, newClientType: string, newStatus: string) => void;
};

const EditClient = ({
  client,
  loading,
  success,
  editClient,
  selectedClient,
  editClientPress,
  getClientId,
  cleanClientState,
  error,
}: Props) => {
  const {
    name,
    lastName,
    email,
    document,
    phonePrefix,
    phoneNumber,
    birthday,
    clientType,
    clientStatus,
  } = client;

  const code = client.docType ? client.docType.code : "";

  const [visibleModal, setVisibleModal] = useState(false);

  const [newClientType, setNewClientType] = useState("");

  const [newStatus, setNewStatus] = useState("");

  useEffect(() => {
    getClientId(selectedClient);
  }, [selectedClient, getClientId]);

  useEffect(() => {
    setNewClientType(clientType);
    setNewStatus(clientStatus);
  }, [clientType, clientStatus]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const hideModal = () => {
    setVisibleModal(false);
    editClientPress();
    cleanClientState();
  };

  const handleOnButtonClick = () => {
    editClient(selectedClient, newClientType, newStatus);
  };

  const enableButton = () => {
    if (clientType !== newClientType || clientStatus !== newStatus)
      return false;
    return true;
  };

  const handleNewClientTypeChange = (event: any) => {
    setNewClientType(event.target.value);
  };

  const handleNewStatusChange = (event: any) => {
    setNewStatus(event.target.value);
  };

  const renderLoader = () => loading && <Loader />;
  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={editClientPress} />
      </BackButtonContainer>
      <Container info>
        <Title>Editar cliente</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Nombre</Title>
            <InputLight id="name" type="text" disabled value={name || ""} />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Apellido</Title>
            <InputLight
              id="lastName"
              type="text"
              disabled
              value={lastName || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Correo electrónico</Title>
            <InputLight id="email" type="text" disabled value={email || ""} />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Documento de identidad</Title>
            <InputLight
              id="document"
              type="text"
              disabled
              value={`${code || ""}-${document || ""}`}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Nº de teléfono</Title>
            <InputLight
              id="phoneNumber"
              type="text"
              disabled
              value={`${phonePrefix || ""}-${phoneNumber || ""}`}
            />
          </ContainerData>

          <ContainerData input>
            <Title subTitle>Fecha de nacimiento</Title>
            <InputLight id="rate" type="text" disabled value={birthday || ""} />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tipo de cliente</Title>
            <Dropdown
              id={"clientType"}
              defaultValue={clientType}
              options={CLIENT_TYPE}
              value={newClientType || ""}
              onChange={handleNewClientTypeChange}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Estatus</Title>
            <Dropdown
              id={"status"}
              defaultValue={clientStatus}
              options={STATUS_TYPES}
              value={newStatus || ""}
              onChange={handleNewStatusChange}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.client.loading,
    success: state.client.successOnEditAction,
    client: state.client.client,
    error: state.client.error,
  };
};

export default connect(mapStateTopProps, {
  getClientId,
  editClient,
  cleanClientState,
})(EditClient);
