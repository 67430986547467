import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SearchButton from "../../common/SearchButton";
import OptionFilter from "../../common/OptionFilter";
import Rechart from "../../common/Rechart";
import { globalStateType } from "../../../ducks/reducers";
import { getChartsData } from "../../../ducks/actions/DashboardActions";
import { CHART_FILTER, CELUPAGO_USER_ROLE } from "../../../utils/ConstHelper";
import { ChartContainer, FilterContainer, DashboardContainer } from "./styles";

type Props = {
  loading: boolean;
  error: string;
  dataOperations: any;
  dataCashiers: any;
  dataUsers: any;
  role: string;
  getChartsData: (from: number) => void;
};

const Dashboard = ({
  loading,
  error,
  role,
  dataOperations,
  dataCashiers,
  dataUsers,
  getChartsData,
}: Props) => {
  const [chartFilter, setChartFilter] = useState({
    value: "",
    label: "",
    id: 0,
  });

  useEffect(() => {
    getChartsData(CHART_FILTER[2].value);
  }, [getChartsData]);

  const filteredSearch = () => {
    if (chartFilter.value) {
      getChartsData(parseFloat(chartFilter.value));
    } else {
      getChartsData(CHART_FILTER[0].value);
    }
  };

  return (
    <DashboardContainer>
      <FilterContainer>
        <OptionFilter
          value={chartFilter}
          handleFilterChange={(option) => {
            setChartFilter(option);
          }}
          label={"Filtro"}
          options={CHART_FILTER}
          clearFilter={() => setChartFilter({ value: "", label: "", id: 0 })}
        />
        <SearchButton onClick={filteredSearch} />
      </FilterContainer>
      {(role === CELUPAGO_USER_ROLE[0].value ||
        role === CELUPAGO_USER_ROLE[1].value) && (
        <ChartContainer>
          <Rechart
            title={"Usuarios"}
            data={dataUsers}
            options={[{ key: "Usuarios", color: "blue" }]}
          />
          <Rechart
            title={"Cajeros"}
            data={dataCashiers}
            options={[{ key: "Cajeros", color: "green" }]}
          />
        </ChartContainer>
      )}
      <ChartContainer>
        <Rechart
          title={"Operaciones"}
          data={dataOperations}
          options={[{ key: "Aprobadas", color: "purple" }]}
        />
        <Rechart
          title={"Operaciones"}
          data={dataOperations}
          options={[{ key: "Rechazadas", color: "red" }]}
        />
      </ChartContainer>
    </DashboardContainer>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.dashboard.loading,
    error: state.dashboard.error,
    dataOperations: state.dashboard.dataOperations,
    dataCashiers: state.dashboard.dataCashiers,
    dataUsers: state.dashboard.dataUsers,
    role: state.user.role,
  };
};

export default connect(mapStateTopProps, {
  // getOperations,
  // getCashiers,
  // getUsers,
  getChartsData,
})(Dashboard);
