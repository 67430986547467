import moment from "moment";
import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";
import { dispatchUnauthorizedError } from "./UserActions";
import {
  getOperationChartMonth,
  sortAscId,
  countRepeated,
  getChartOperationFormatted,
  getChartMonth,
  getChartCashiersFormatted,
  getChartUsersFormatted,
  getChartWeekday,
  getOperationChartWeekday,
} from "../../utils/Formatters";
import { CHART_FILTER } from "../../utils/ConstHelper";

const base = "DASHBOARD_ACTIONS_";
const requestManager = new RequestManager();

export const DASHBOARD_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",

  FETCH_DASHBOARD_START: base + "FETCH_DASHBOARD_START",
  FETCH_DASHBOARD_OPERATIONS_SUCCESS:
    base + "FETCH_DASHBOARD_OPERATIONS_SUCCESS",
  FETCH_DASHBOARD_CASHIERS_SUCCESS: base + "FETCH_DASHBOARD_CASHIERS_SUCCESS",
  FETCH_DASHBOARD_USERS_SUCCESS: base + "FETCH_DASHBOARD_USERS_SUCCESS",
  FETCH_DASHBOARD_FAIL: base + "FETCH_DASHBOARD_FAIL",
};

export const getOperations = (fromTo: string, from: number) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_START });
    let url = `/transaction/chart?limit=500` + fromTo;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });
      const rejectedOperationUnformatted = response.data.transactionRZDT;
      const completedOperationUnformatted = response.data.transactionCT;

      let rejected: any = [];
      let rejectedSort: any = [];

      let completed: any = [];
      let completedSort: any = [];

      if (from < 1) {
        completedOperationUnformatted.map((operation: any) => {
          completed.push(getOperationChartWeekday(operation));
          return null;
        });

        rejectedOperationUnformatted.map((operation: any) => {
          rejected.push(getOperationChartWeekday(operation));
          return null;
        });
      } else {
        completedOperationUnformatted.map((operation: any) => {
          completed.push(getOperationChartMonth(operation));
          return null;
        });

        rejectedOperationUnformatted.map((operation: any) => {
          rejected.push(getOperationChartMonth(operation));
          return null;
        });
      }

      rejectedSort = sortAscId(rejected);
      completedSort = sortAscId(completed);

      const rejectedTags = countRepeated(rejectedSort);

      const completedTags = countRepeated(completedSort);

      const data = getChartOperationFormatted(rejectedTags, completedTags);
      dispatch({
        type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_OPERATIONS_SUCCESS,
        payload: { data },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getCashiers = (fromTo: string, from: number) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_START });
    let url = `/cashier/chart/cashiers?limit=500` + fromTo;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });

      const cashiersUnformatted = response.data;
      let cashiers: any = [];
      let cashiersSort: any = [];

      if (from < 1) {
        cashiersUnformatted.map((cashier: any) => {
          cashiers.push(getChartWeekday(cashier));
          return null;
        });
      } else {
        cashiersUnformatted.map((cashier: any) => {
          cashiers.push(getChartMonth(cashier));
          return null;
        });
      }

      cashiersSort = sortAscId(cashiers);

      const cashierTags = countRepeated(cashiersSort);

      const data = getChartCashiersFormatted(cashierTags);
      dispatch(getOperations(fromTo, from));
      dispatch({
        type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_CASHIERS_SUCCESS,
        payload: { data },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getUsers = (fromTo: string, from: number) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_START });
    let url = `/users/chart/users?limit=500` + fromTo;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });

      const usersUnformatted = response.data;

      let users: any = [];
      let usersSort: any = [];

      if (from < 1) {
        usersUnformatted.map((user: any) => {
          users.push(getChartWeekday(user));
          return null;
        });
      } else {
        usersUnformatted.map((user: any) => {
          users.push(getChartMonth(user));
          return null;
        });
      }

      usersSort = sortAscId(users);

      const userTags = countRepeated(usersSort);

      const data = getChartUsersFormatted(userTags);
      dispatch(getCashiers(fromTo, from));
      dispatch({
        type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_USERS_SUCCESS,
        payload: { data },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getChartsData = (from: number) => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_START });
    let fromTo = "";
    if (from) {
      const maxDate = moment().format().split("T")[0];

      let frontDate = "";

      if (from === CHART_FILTER[0].value) {
        frontDate = moment().format().split("T")[0];
      } else if (from === CHART_FILTER[1].value) {
        frontDate = moment().subtract(7, "days").format().split("T")[0];
      } else {
        frontDate = moment().subtract(from, "months").format().split("T")[0];
      }
      fromTo = `&from=${frontDate}&to=${maxDate}`;
    }

    try {
      dispatch(getUsers(fromTo, from));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: DASHBOARD_ACTIONS.FETCH_DASHBOARD_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const clearDashboardState = () => {
  return {
    type: DASHBOARD_ACTIONS.CLEAN_STATE,
  };
};
