import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../../../common/Table";
import Loader from "../../../common/Loader";
import AddButton from "../../../common/AddButton";
import OptionFilter from "../../../common/OptionFilter";
import SearchButton from "../../../common/SearchButton";
import Modal from "../../../common/Modal";
import Edit from "../../../../assets/icons/edit-purple.png";
import Trash from "../../../../assets/icons/trash-can.png";
import {
  STATUS_TYPES,
  COUNTRY_TABLE_COLUMNS,
} from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import { CountryRow, Country } from "../../../../utils/DataType";
import {
  getAllCountries,
  disableCountry,
} from "../../../../ducks/actions/CountryActions";
import {
  Container,
  Title,
  TableContainer,
  AddButtonContainer,
  FilterContainer,
  EditIcon,
  TrashIcon,
} from "../styles";

type Props = {
  loading: boolean;
  error: string;
  countryRows: CountryRow[];
  countries: Country[];
  newCountryPress: () => void;
  editCountryPress: () => void;
  selectedCountry: (id: number) => void;
  getAllCountries: (status?: string) => void;
  disableCountry: (id: number) => void;
};

const CountryList = ({
  loading,
  error,
  countryRows,
  newCountryPress,
  editCountryPress,
  selectedCountry,
  getAllCountries,
  disableCountry,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [errorModal, setVisibleErrorModal] = useState(false);
  const [registerToDisable, setRegisterToDisable] = useState(0);
  const [countryStatus, setCountryStatus] = useState({
    value: "",
    label: "",
    id: 0,
  });

  useEffect(() => {
    getAllCountries("ACTIVE");
  }, [getAllCountries]);

  useEffect(() => {
    if (error) {
      setVisibleErrorModal(true);
    }
  }, [error]);
  const filteredSearch = () => {
    if (countryStatus.value) {
      getAllCountries(countryStatus.value);
    } else {
      getAllCountries();
    }
  };
  const handleEditClick = (country: Country) => {
    editCountryPress();
    selectedCountry(country.id);
  };

  const handleTrashClick = (country: Country) => {
    setVisibleModal(true);
    setRegisterToDisable(country.id);
  };

  const hideModal = () => {
    setVisibleModal(false);
    setRegisterToDisable(0);
  };
  const confirmModalAction = () => {
    disableCountry(registerToDisable);
    setVisibleModal(false);
  };
  const hideErrorModal = () => {
    setVisibleErrorModal(false);
    setRegisterToDisable(0);
  };
  const renderLoader = () => loading && <Loader />;

  const optionComponents = [<EditIcon src={Edit} />, <TrashIcon src={Trash} />];

  return (
    <Container tableView>
      {renderLoader()}
      <Container info>
        <Title>Países</Title>
      </Container>
      <TableContainer>
        <AddButtonContainer>
          <AddButton buttonText="Nuevo" onClick={newCountryPress} />
        </AddButtonContainer>
        <FilterContainer>
          <OptionFilter
            value={countryStatus}
            handleFilterChange={(option) => {
              setCountryStatus(option);
            }}
            label={"Estatus"}
            options={STATUS_TYPES}
            clearFilter={() =>
              setCountryStatus({ value: "", label: "", id: 0 })
            }
          />

          <SearchButton onClick={filteredSearch} />
        </FilterContainer>
        <Table
          tableColumns={COUNTRY_TABLE_COLUMNS}
          dataRows={countryRows}
          optionComponents={optionComponents}
          onOptionOneClick={handleEditClick}
          onOptionTwoClick={handleTrashClick}
        />
      </TableContainer>
      <Modal
        show={visibleModal}
        title={"¿Estás seguro que deseas hacer la desactivación?"}
        body={""}
        twoOption
        confirmationOption={confirmModalAction}
        cancelOption={hideModal}
      />
      <Modal
        show={errorModal}
        title={""}
        body={"Ha ocurrido un error, inténtalo nuevamente"}
        handleClose={hideErrorModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.country.loading,
    error: state.country.error,
    countryRows: state.country.countryRows,
    countries: state.country.countries,
  };
};

export default connect(mapStateTopProps, { getAllCountries, disableCountry })(
  CountryList
);
