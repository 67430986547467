import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import LogoImg from "../../../assets/img/logoDark.png";
import SignOutIcon from "../../../assets/icons/sign-out.png";
import Container from "../../common/Container";
import Loader from "../../common/Loader";
import Modal from "../../common/Modal";
import Sidebar from "../../common/Sidebar";
import Profile from "../Profile";
import Dashboard from "../Dashboard";
import Cashier from "../Cashier";
import Operations from "../Operations";
import User from "../User";
import Country from "../Country";
import Bank from "../Bank";
import Document from "../Document";
import Client from "../Client";
import { SESSION_TIME_TO_ALERT } from "../../../utils/ConstHelper";
import { globalStateType } from "../../../ducks/reducers";
import {
  getUserProfile,
  clearSuccess,
} from "../../../ducks/actions/UserActions";
import { Header, Logo, MainContainer } from "./styles";

type Props = {
  loading: boolean;
  role: string;
  error: string;
  getUserProfile: () => void;
  clearSuccess: () => void;
  component?: number;
};

const Home = ({
  loading,
  getUserProfile,
  role,
  error,
  clearSuccess,
  component,
}: Props) => {
  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleErrorModal, setVisibleErrorModal] = useState(false);
  const [visibleTimeModal, setVisibleTimeModal] = useState(false);

  useEffect(() => {
    const sessionExpiresIn = localStorage.getItem("sessionExpiresIn");
    const now = moment();
    const expDate = moment(moment.unix(Number(sessionExpiresIn)).format());
    const duration = moment.duration(expDate.diff(now));
    const secondsLeft = duration.asSeconds();
    const delay = (Number(secondsLeft) - SESSION_TIME_TO_ALERT) * 1000;
    const timer = setTimeout(() => {
      setVisibleTimeModal(true);
    }, delay);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    getUserProfile();
    setCurrentComponent(component ? component : 0);
  }, [getUserProfile, component]);

  useEffect(() => {
    if (error && error === "UNAUTHORIZED") {
      setVisibleErrorModal(true);
    }
  }, [error]);

  const logOut = () => {
    setVisibleModal(false);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginDate");
    localStorage.removeItem("sessionExpiresIn");
    localStorage.removeItem("sessionTime");
    clearSuccess();
    history.push("/");
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  const hideTimeAlertModal = () => {
    setVisibleTimeModal(false);
  };

  const renderLoader = () => loading && <Loader />;

  const renderCurrentComponent = () => {
    const components = [
      <Dashboard />,
      <Profile />,
      <User />,
      <Cashier />,
      <Country />,
      <Bank />,
      <Document />,
      <Operations />,
      <Client />,
    ];
    return components[currentComponent];
  };
  return (
    <Container flexDirection="row">
      {renderLoader()}
      <Sidebar
        setCurrentComponent={setCurrentComponent}
        component={currentComponent}
      />
      <MainContainer>
        <Header>
          <Logo src={LogoImg} onClick={() => setCurrentComponent(0)} />
          <Logo icon src={SignOutIcon} onClick={() => setVisibleModal(true)} />
        </Header>
        {role && renderCurrentComponent()}
      </MainContainer>
      <Modal
        show={visibleModal}
        title={"¿Estás seguro que deseas cerrar sesión?"}
        body={""}
        twoOption
        confirmationOption={logOut}
        cancelOption={hideModal}
      />
      <Modal
        show={visibleErrorModal}
        title={"Su sesión ha expirado"}
        body={""}
        handleClose={logOut}
      />
      <Modal
        show={visibleTimeModal}
        title={"Su sesión expira en 10 minutos"}
        body={""}
        handleClose={hideTimeAlertModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.user.loading,
    role: state.user.role,
    error: state.user.error,
  };
};

export default connect(mapStateTopProps, { getUserProfile, clearSuccess })(
  Home
);
