import styled, { css } from "styled-components";
import { Theme } from "../../../../utils/theme";

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  ${(props) =>
    props.tab &&
    css`
      width: 50%;
      border-bottom: 3px solid ${Theme.colors.backgroundDisable};
      justify-content: center;
      align-items: center;
      height: 30px;
      cursor: pointer;
    `};
  ${(props) =>
    props.selectedTab &&
    css`
      border-bottom: 3px solid ${Theme.colors.colorSecondary};
    `};
`;

export const TagTitle = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
`;
