import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 295px;
  height: 121px;
  border: solid 1px #707070;
  border-radius: 2px;
  ${(props) =>
    props.header &&
    css`
      flex-direction: row;
      justify-content: center;
      height: 39px;
      background-color: #23232d;
      color: white;
    `};
  ${(props) =>
    props.body &&
    css`
      flex-direction: row;
      width: 290px;
      align-items: center;
      justify-content: flex-start;
      height: 20px;
      border: none;
    `};
  ${(props) =>
    props.footer &&
    css`
      justify-content: flex-end;
    `};
`;
export const Title = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #23232d;
  ${(props) =>
    props.light &&
    css`
      color: white;
    `};
`;

export const IconContainer = styled.img`
  width: 14.1px;
  height: 12.5px;
  margin-right: 2px;
  margin-left: 2px;
  object-fit: contain;
  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `};
  ${(props) =>
    props.flag &&
    css`
      width: 20px;
      height: 12px;
    `};
`;
