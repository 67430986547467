import React from "react";
import {
  Select,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
} from "@material-ui/core";

type Props = {
  id: string;
  styles?: string;
  options: [] | any;
  value?: string;
  defaultValue?: string;
  disable?: boolean;
  onChange: (value: any) => void;
};

const renderItems = (options: any) => {
  return options.map((option: any) => (
    <MenuItem value={option.value} key={option.id}>
      {option.label}
    </MenuItem>
  ));
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownRole: {
      width: 180,
      height: 30,
      marginLeft: 10,
    },
    dropdown: {
      width: 245,
      height: 35,
      color: "black",
    },
    dropdownSmall: {
      width: 80,
      height: 35,
    },
    selected: {
      "  &:focus": {
        backgroundColor: "white",
      },
    },
  })
);

const Dropdown = ({
  id,
  styles,
  options,
  value,
  onChange,
  defaultValue,
  disable = false,
}: Props) => {
  const classes = useStyles();

  const style =
    styles === "small"
      ? classes.dropdownSmall
      : styles === "role"
      ? classes.dropdownRole
      : classes.dropdown;

  return (
    <>
      <Select
        data-testid="dropdown-container"
        id={id}
        variant="outlined"
        className={style}
        classes={{ root: classes.selected }}
        value={value}
        onChange={(value) => onChange(value)}
        disabled={disable}
        defaultValue={defaultValue}
      >
        {renderItems(options)}
      </Select>
    </>
  );
};

export default Dropdown;
