import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../../common/Button";
import InputLight from "../../../common/InputLight";
import Modal from "../../../common/Modal";
import BackButton from "../../../common/BackButton";
import Loader from "../../../common/Loader";
import { globalStateType } from "../../../../ducks/reducers";
import {
  cleanCountryState,
  createNewCountry,
} from "../../../../ducks/actions/CountryActions";
import {
  validateNames,
  validateNumber,
  validateFloat,
  validateBlanks,
} from "../../../../utils/Validators";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
} from "../styles";

type Props = {
  success: boolean;
  loading: boolean;
  error: string;
  cleanCountryState: () => void;
  newCountryPress: () => void;
  createNewCountry: (
    name: string,
    iso: string,
    callingCode: string,
    currency: string,
    maxAmount: number,
    minAmount: number,
    rate: number,
    saving: number
  ) => void;
};

const NewCountry = ({
  success,
  loading,
  newCountryPress,
  cleanCountryState,
  createNewCountry,
  error,
}: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [country, setCountry] = useState({ value: "", error: false });
  const [currency, setCurrency] = useState({ value: "", error: false });
  const [isoCode, setIsoCode] = useState({ value: "", error: false });
  const [callingCode, setCallingCode] = useState({ value: "", error: false });
  const [minAmount, setMinAmount] = useState({ value: "", error: false });
  const [maxAmount, setMaxAmount] = useState({ value: "", error: false });
  const [rate, setRate] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });
  const [saving, setSaving] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.charAt(0) !== " ") {
      if (validateNames(value)) setCountry({ ...country, value });
    }
  };

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    if (!validateBlanks(value)) {
      if (validateNames(value)) setCurrency({ ...currency, value });
    }
  };

  const handleIsoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    if (!validateBlanks(value)) {
      if (validateNames(value)) setIsoCode({ ...isoCode, value });
    }
  };

  const handleCallingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (validateNumber(value)) setCallingCode({ ...callingCode, value });
    else {
      if (value.length === 0) setCallingCode({ ...callingCode, value });
    }
  };

  const handleMinAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNumber(value) && maxAmount)
      setMinAmount({ ...minAmount, value });
    else {
      if (value.length === 0) setMinAmount({ ...minAmount, value });
    }
  };

  const handleMaxAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (validateNumber(value)) setMaxAmount({ ...maxAmount, value });
    else {
      if (value.length === 0) setMaxAmount({ ...maxAmount, value });
    }
  };

  const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setRate({ ...rate, value });
  };

  const handleSavingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSaving({ ...saving, value });
  };

  const handleSavingOnBlur = () => {
    if (saving.value) {
      if (validateFloat(saving.value, false, true)) {
        setSaving({ ...saving, error: false });
      } else
        setSaving({
          ...saving,
          error: true,
          errorMessage: "Formato es inválido: 0.0000",
        });
    } else
      setSaving({
        ...saving,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleRateOnBlur = () => {
    if (rate.value) {
      if (validateFloat(rate.value, false, true)) {
        setRate({ ...rate, error: false });
      } else
        setRate({
          ...rate,
          error: true,
          errorMessage: "Formato es inválido: 0.0000",
        });
    } else
      setRate({
        ...rate,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleMaxAmountOnBlur = () => {
    if (minAmount.value && Number(minAmount.value) < Number(maxAmount.value)) {
      setMaxAmount({ ...maxAmount, error: false });
      setMinAmount({ ...minAmount, error: false });
    } else if (!minAmount.value && maxAmount.value)
      setMaxAmount({ ...maxAmount, error: false });
    else setMaxAmount({ ...maxAmount, error: true });
  };

  const handleMinAmountOnBlur = () => {
    if (maxAmount.value && Number(maxAmount.value) > Number(minAmount.value)) {
      setMinAmount({ ...minAmount, error: false });
      setMaxAmount({ ...maxAmount, error: false });
    } else if (!maxAmount.value && minAmount.value)
      setMinAmount({ ...minAmount, error: false });
    else setMinAmount({ ...minAmount, error: true });
  };

  const handleIsoCodeOnBlur = () => {
    if (isoCode.value) setIsoCode({ ...isoCode, error: false });
    else setIsoCode({ ...isoCode, error: true });
  };

  const handleCallingCodeOnBlur = () => {
    if (callingCode.value) setCallingCode({ ...callingCode, error: false });
    else setCallingCode({ ...callingCode, error: true });
  };

  const handleCurrencyOnBlur = () => {
    if (currency.value) setCurrency({ ...currency, error: false });
    else setCurrency({ ...currency, error: true });
  };

  const handleCountryOnBlur = () => {
    if (country.value) setCountry({ ...country, error: false });
    else setCountry({ ...country, error: true });
  };

  const hideModal = () => {
    setVisibleModal(false);
    cleanCountryState();
    newCountryPress();
  };
  const handleOnButtonClick = () => {
    createNewCountry(
      country.value,
      isoCode.value,
      "+" + callingCode.value,
      currency.value,
      Number(maxAmount.value),
      Number(minAmount.value),
      Number(rate.value),
      Number(saving.value)
    );
  };

  const enableButton = () => {
    if (
      country.value &&
      currency.value &&
      isoCode.value &&
      callingCode.value &&
      minAmount.value &&
      maxAmount.value &&
      rate.value &&
      saving.value &&
      !country.error &&
      !currency.error &&
      !isoCode.error &&
      !minAmount.error &&
      !maxAmount.error &&
      !rate.error &&
      !saving.error &&
      !callingCode.error
    )
      return false;
    else return true;
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={newCountryPress} />
      </BackButtonContainer>
      <Container info>
        <Title bold>Nuevo país</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <InputLight
              id="country"
              type="text"
              value={country.value}
              onChange={handleCountryChange}
              error={country.error}
              onBlur={handleCountryOnBlur}
              errorMessage={
                country.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={15}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Moneda</Title>
            <InputLight
              id="currency"
              type="text"
              value={currency.value}
              onChange={handleCurrencyChange}
              error={currency.error}
              onBlur={handleCurrencyOnBlur}
              errorMessage={
                currency.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={15}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Código país</Title>
            <InputLight
              id="callingCode"
              type="text"
              value={callingCode.value}
              onChange={handleCallingChange}
              error={callingCode.error}
              onBlur={handleCallingCodeOnBlur}
              errorMessage={
                callingCode.error ? "El campo no puede estar vacío" : ""
              }
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>ISO Code</Title>
            <InputLight
              id="isoCode"
              type="text"
              value={isoCode.value}
              onChange={handleIsoCodeChange}
              error={isoCode.error}
              onBlur={handleIsoCodeOnBlur}
              errorMessage={
                isoCode.error ? "El campo no puede estar vacío" : ""
              }
              maxLength={5}
              minLength={3}
            />
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Monto mínimo</Title>
            <InputLight
              id="minAmount"
              type="text"
              value={minAmount.value}
              onChange={handleMinAmountChange}
              error={minAmount.error}
              onBlur={handleMinAmountOnBlur}
              errorMessage={minAmount.error ? "Error en el monto" : ""}
              maxLength={11}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto máximo</Title>
            <InputLight
              id="maxAmount"
              type="text"
              value={maxAmount.value}
              onChange={handleMaxAmountChange}
              error={maxAmount.error}
              onBlur={handleMaxAmountOnBlur}
              errorMessage={maxAmount.error ? "Error en el monto" : ""}
              maxLength={11}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>% Tasa de cambio</Title>
            <InputLight
              id="rate"
              type="text"
              value={rate.value}
              onChange={handleRateChange}
              error={rate.error}
              onBlur={handleRateOnBlur}
              errorMessage={rate.error ? rate.errorMessage : ""}
              maxLength={11}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>% Ahorro</Title>
            <InputLight
              id="saving"
              type="text"
              value={saving.value}
              onChange={handleSavingChange}
              error={saving.error}
              onBlur={handleSavingOnBlur}
              errorMessage={saving.error ? saving.errorMessage : ""}
              maxLength={11}
              minLength={3}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.country.loading,
    success: state.country.successOnFormAction,
    error: state.country.error,
  };
};

export default connect(mapStateTopProps, {
  cleanCountryState,
  createNewCountry,
})(NewCountry);
