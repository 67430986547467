import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";
import { dispatchUnauthorizedError } from "./UserActions";
import {
  getClientRow,
  getClient,
  getClientsFilter,
} from "../../utils/Formatters";

const base = "CLIENT_ACTIONS_";
const requestManager = new RequestManager();

export const CLIENT_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",

  FETCH_CLIENT_LIST_START: base + "FETCH_CLIENT_LIST_START",
  FETCH_CLIENT_LIST_SUCCESS: base + "FETCH_CLIENT_LIST_SUCCESS",
  FETCH_CLIENT_LIST_FAIL: base + "FETCH_CLIENT_LIST_FAIL",

  FETCH_CLIENT_BY_ID_START: base + "FETCH_CLIENT_BY_ID_START",
  FETCH_CLIENT_BY_ID_SUCCESS: base + "FETCH_CLIENT_BY_ID_SUCCESS",
  FETCH_CLIENT_BY_ID_FAIL: base + "FETCH_CLIENT_BY_ID_FAIL",

  CLIENT_EDIT_ACTION_START: base + "CLIENT_EDIT_ACTION_START",
  CLIENT_EDIT_ACTION_SUCCESS: base + "CLIENT_EDIT_ACTION_SUCCESS",
  CLIENT_EDIT_ACTION_FAIL: base + "CLIENT_EDIT_ACTION_FAIL",
};

export const getAllClients = (country?: string, clientType?: string) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CLIENT_ACTIONS.FETCH_CLIENT_LIST_START });
    let url = `/users?`;

    if (country) {
      url = url + `&country=${country}`;
    }

    if (clientType) {
      url = url + `&clientType=${clientType}`;
    }
    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });
      const clientsUnformatted = response.data;
      let countries: any[] = [];
      const clients = clientsUnformatted.map((client: any) => {
        const clientFormatted = getClient(client);
        countries.push(clientFormatted.country);
        return clientFormatted;
      });
      const clientRows = clientsUnformatted.map((bank: any) => {
        return getClientRow(bank);
      });

      const countryOption = Array.from(
        new Set(countries.map((cItem) => cItem.id))
      ).map((id) => {
        return getClientsFilter(countries.find((c) => c.id === id));
      });

      dispatch({
        type: CLIENT_ACTIONS.FETCH_CLIENT_LIST_SUCCESS,
        payload: { clients, clientRows, countryOption },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CLIENT_ACTIONS.FETCH_CLIENT_LIST_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const getClientId = (id: number) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CLIENT_ACTIONS.FETCH_CLIENT_BY_ID_START });
    let url = `/users/${id}/`;

    try {
      const response = await requestManager.get(url, {
        Authorization: `Bearer ${accessToken}`,
      });

      const clientFormatted = getClient(response.data);

      dispatch({
        type: CLIENT_ACTIONS.FETCH_CLIENT_BY_ID_SUCCESS,
        payload: { client: clientFormatted },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CLIENT_ACTIONS.FETCH_CLIENT_BY_ID_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};

export const cleanClientState = () => {
  return {
    type: CLIENT_ACTIONS.CLEAN_STATE,
  };
};

export const editClient = (
  id: number,
  newClientType: string,
  newStatus: string
) => {
  const accessToken = localStorage.getItem("accessToken");
  return async (dispatch: any, getState: any) => {
    dispatch({ type: CLIENT_ACTIONS.CLIENT_EDIT_ACTION_START });

    const data = {
      clientType: newClientType,
      user_status: newStatus,
    };
    try {
      await requestManager.patch(`/users/${id}/edit`, data, {
        Authorization: `Bearer ${accessToken}`,
      });

      dispatch({
        type: CLIENT_ACTIONS.CLIENT_EDIT_ACTION_SUCCESS,
        payload: {},
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: CLIENT_ACTIONS.CLIENT_EDIT_ACTION_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      }
    }
  };
};
