import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const DateFilterContainer = styled.div`
  width: 184px;
  height: 50px;
  border-radius: 3px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  background-color: ${Theme.colors.filterBackground};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ClearButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
  ${(props) =>
    props.firefox &&
    css`
      display: none;
    `};
`;

export const ClearImage = styled.img`
  width: 12.5px;
  height: 12.5px;
  margin-right: 10px;
  object-fit: contain;
`;
