import React from "react";
import Button from "../Button";
import CheckIcon from "./../../../assets/icons/check-circle.png";
import ExclamationIcon from "./../../../assets/icons/exclamation-circle.png";
import {
  ModalContainer,
  MainContainer,
  TextModal,
  ButtonSection,
  BodySection,
  IconOption,
} from "./styles";

type Props = {
  title: string;
  check?: boolean;
  body?: string;
  show: boolean;
  twoOption?: boolean;
  handleClose?: () => void;
  confirmationOption?: () => void;
  cancelOption?: () => void;
};

const Modal = ({
  title,
  check,
  body,
  show,
  handleClose,
  twoOption,
  confirmationOption,
  cancelOption,
}: Props) => {
  return (
    <ModalContainer show={show} data-testid="modal-container">
      <MainContainer>
        <TextModal titleText>{title}</TextModal>
        <BodySection>
          <IconOption src={check ? CheckIcon : ExclamationIcon} />
          <TextModal> {body} </TextModal>
        </BodySection>
        {twoOption ? (
          <ButtonSection>
            <Button
              secondary
              buttonText={"NO"}
              onClick={cancelOption}
              twoInRow={twoOption}
            />
            <Button
              buttonText={"SI"}
              onClick={confirmationOption}
              twoInRow={twoOption}
            />
          </ButtonSection>
        ) : (
          <ButtonSection>
            <Button buttonText={"OK"} onClick={handleClose} />
          </ButtonSection>
        )}
      </MainContainer>
    </ModalContainer>
  );
};

export default Modal;
