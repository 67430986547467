import React from "react";
import CurrentBreadcrumb from "../../../assets/icons/circleSolidPurple.png";
import NextBreadcrumb from "../../../assets/icons/circleSolidGray.png";
import CheckedBreadcrumb from "../../../assets/icons/circleSolidPurple.png";
import ChevronLeft from "../../../assets/icons/chevronLeft.png";
import {
  Container,
  BreadcrumbsIcon,
  BreadcrumbsContainer,
  Title,
  BreadcrumbSeparator,
  BreadcrumbsAssetContainer,
  Pusher,
} from "./styles";

type Props = {
  currentStep: number;
  handleBackPress?: () => void;
};

const getBreadcrumb = (currentIndex: number, breadcrumbIndex: number) => {
  if (currentIndex === breadcrumbIndex) {
    return <BreadcrumbsIcon src={CurrentBreadcrumb} />;
  }

  if (currentIndex < breadcrumbIndex) {
    return <BreadcrumbsIcon src={NextBreadcrumb} />;
  }

  if (currentIndex > breadcrumbIndex) {
    return <BreadcrumbsIcon src={CheckedBreadcrumb} />;
  }
};
const Breadcrumbs = ({ currentStep, handleBackPress }: Props) => {
  return (
    <Container data-testid="breadcrumbs-container">
      {(currentStep === 1 || currentStep === 2) && (
        <BreadcrumbsIcon chevron src={ChevronLeft} onClick={handleBackPress} />
      )}
      <BreadcrumbsContainer>
        <BreadcrumbsAssetContainer>
          <Pusher />
          {getBreadcrumb(currentStep, 0)}
          <BreadcrumbSeparator separatorOn={currentStep > 0} />
        </BreadcrumbsAssetContainer>
        <Title>Datos del cajero</Title>
      </BreadcrumbsContainer>
      <BreadcrumbsContainer>
        <BreadcrumbsAssetContainer>
          <BreadcrumbSeparator separatorOn={currentStep > 0} />
          {getBreadcrumb(currentStep, 1)}
          <BreadcrumbSeparator separatorOn={currentStep > 1} />
        </BreadcrumbsAssetContainer>
        <Title>Resumen de cajero</Title>
      </BreadcrumbsContainer>
      <BreadcrumbsContainer>
        <BreadcrumbsAssetContainer>
          <BreadcrumbSeparator separatorOn={currentStep > 1} />
          {getBreadcrumb(currentStep, 2)}
          <Pusher />
        </BreadcrumbsAssetContainer>
        <Title>Datos de cuenta bancaria</Title>
      </BreadcrumbsContainer>
    </Container>
  );
};

export default Breadcrumbs;
