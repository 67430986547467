import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90%;
  width: 95%;
  ${(props) =>
    props.tableView &&
    css`
      justify-content: flex-start;
      align-items: center;
      height: auto;
    `};
  ${(props) =>
    props.info &&
    css`
      justify-content: flex-start;
      align-items: flex-start;
      height: auto;
    `};
`;

export const Title = styled.span`
  font-family: ${Theme.fonts.fontPrimary}, ${Theme.fonts.fontSecondary};
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold;
  ${(props) =>
    props.light &&
    css`
      font-weight: normal;
    `};
  ${(props) =>
    props.subTitle &&
    css`
      margin-top: 5px;
      font-size: 14px;
    `};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
`;

export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  ${(props) =>
    props.input &&
    css`
      flex-direction: row;
      min-height: 45px;
      align-items: flex-start;
      justify-content: space-between;
      width: 92%;
      @media (min-width: 1300px) {
        width: 85%;
      }
    `};
  ${(props) =>
    props.helpPassword &&
    css`
      justify-content: center;
      align-items: flex-end;
      margin-top: 5px;
      margin-bottom: 5px;
    `};
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled.div`
  width: 97%;
  display: flex;
  flex-direction: column;
`;
export const EditIcon = styled.img`
  width: 14.1px;
  height: 12.5px;
  object-fit: contain;
  cursor: pointer;
`;

export const EyeIcon = styled.img`
  width: 14.9px;
  height: 9.9px;
  margin-right: 10px;
  object-fit: contain;
  cursor: pointer;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
export const BackButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const FilterArea = styled.div`
  display: flex;
  height: 100%;
  width: 80%;
  flex-direction: row;
  align-items: center;
`;

export const DownloadButtonContainer = styled.div`
  display: flex;
  width: 20%;
  height: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;
