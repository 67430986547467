import React, { ReactElement } from "react";
import { CSVLink } from "react-csv";
import Download from "../../../assets/icons/download.png";
import {
  DownloadIcon,
  ButtonText,
  ButtonContainer,
  ButtonComponent,
} from "./styles";

type Props = {
  dataToDownload: any;
  filename: string;
  disabled?: boolean;
};

const ReportButton = ({
  dataToDownload,
  filename,
  disabled = false,
}: Props): ReactElement => {
  return (
    <>
      <ButtonContainer>
        {disabled ? (
          <ButtonComponent type="button" disabled={disabled}>
            <ButtonText>Descargar reporte</ButtonText>
            <DownloadIcon src={Download} />
          </ButtonComponent>
        ) : (
          <CSVLink data={dataToDownload} filename={filename} target="_blank">
            <ButtonComponent type="button" disabled={disabled}>
              <ButtonText>Descargar reporte</ButtonText>
              <DownloadIcon src={Download} />
            </ButtonComponent>
          </CSVLink>
        )}
      </ButtonContainer>
    </>
  );
};

export default ReportButton;
