import React, { ReactElement } from "react";
import Add from "../../../assets/icons/plus-circle.png";
import AddLight from "../../../assets/icons/plus-circle-light.png";
import { Container, AddIcon, ButtonText } from "./styles";

type Props = {
  onClick?: () => void;
  buttonText?: string;
  dark?: boolean;
};

const AddButton = ({ onClick, buttonText, dark }: Props): ReactElement => {
  return (
    <Container dark={dark} buttonText={buttonText} onClick={onClick}>
      <AddIcon src={dark ? AddLight : Add} />
      {buttonText && <ButtonText dark={dark}>{buttonText}</ButtonText>}
    </Container>
  );
};

export default AddButton;
