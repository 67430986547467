import React, { ReactElement } from "react";
import ReactLoading from "react-loading";
import { LoaderContainer, LoaderPosition } from "./styles";
import { Theme } from "../../../utils/theme";

const Loader = (): ReactElement => {
  return (
    <LoaderContainer data-testid="loader-container">
      <LoaderPosition>
        <ReactLoading
          type={"spin"}
          color={Theme.colors.colorSecondary}
          width={"40px"}
        />
      </LoaderPosition>
    </LoaderContainer>
  );
};

export default Loader;
