import styled, { css } from "styled-components";
import { Theme } from "../../../utils/theme";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: none;
  ${(props) =>
    props.show &&
    css`
      display: block;
    `};
`;

export const MainContainer = styled.div`
  position: fixed;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  width: 35%;
  max-width: 500px;
  height: auto;
  max-height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TextModal = styled.p`
  font-family: ${Theme.fonts.fontPrimary} ${Theme.fonts.fontSecondary};
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: center;
  color: #262734;
  ${(props) =>
    props.titleText &&
    css`
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 0.25px;
      color: #2a2c3c;
    `};
`;

export const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  justify-content: center;
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
`;

export const IconOption = styled.img`
  width: 54px;
  height: 54px;
  object-fit: contain;
`;
