import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import InputLight from "../../../common/InputLight";
import BackButton from "../../../common/BackButton";
import Button from "../../../common/Button";
import Modal from "../../../common/Modal";
import Loader from "../../../common/Loader";
import Dropdown from "../../../common/Dropdown";
import {
  getCountryId,
  editCountry,
  cleanCountryState,
} from "../../../../ducks/actions/CountryActions";
import { globalStateType } from "../../../../ducks/reducers";
import { STATUS_TYPES } from "../../../../utils/ConstHelper";
import { Country } from "../../../../utils/DataType";
import {
  validateNumberPoint,
  validateFloat,
  validateNumber,
} from "../../../../utils/Validators";
import { Theme } from "../../../../utils/theme";
import {
  Container,
  Title,
  MainContainer,
  ContainerData,
  ButtonSection,
  BackButtonContainer,
  ToggleContainer,
} from "../styles";

type Props = {
  country: Country;
  loading: boolean;
  success: boolean;
  selectedCountry: number;
  error: string;
  editCountryPress: () => void;
  getCountryId: (id: number) => void;
  cleanCountryState: () => void;
  editCountry: (
    id: number,
    newMinAmount: string,
    newMaxAmount: string,
    newRate: string,
    newSaving: string,
    newStatus: string,
    newExchangeRate: string,
    newExchangeRateUsd: string,

    newManualRateEnable: string
  ) => void;
};

const PurpleSwitch = withStyles({
  switchBase: {
    color: Theme.colors.colorDisabled,
    "&$checked": {
      color: Theme.colors.colorSecondary,
    },
    "&$checked + $track": {
      backgroundColor: Theme.colors.colorSecondary,
    },
  },
  checked: {},
  track: {},
})(Switch);

const EditCountry = ({
  country,
  selectedCountry,
  editCountryPress,
  getCountryId,
  editCountry,
  cleanCountryState,
  loading,
  success,
  error,
}: Props) => {
  const {
    callingCode,
    countryStatus,
    currency,
    iso,
    maxAmount,
    minAmount,
    name,
    rate,
    saving,
    manualRateEnable,
    manualRate,
    manualUsdRate,
  } = country;

  const [visibleModal, setVisibleModal] = useState(false);
  const [newMinAmount, setNewMinAmount] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });
  const [newMaxAmount, setNewMaxAmount] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  const [newRate, setNewRate] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  const [newSaving, setNewSaving] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  const [newExchangeRate, setNewExchangeRate] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  const [newExchangeRateUsd, setNewExchangeRateUsd] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  const [newStatus, setNewStatus] = useState("");
  const [newManualRateEnable, setNewManualRateEnable] = useState({
    stringValue: "INACTIVE",
    booleanValue: false,
  });

  useEffect(() => {
    getCountryId(selectedCountry);
  }, [getCountryId, selectedCountry]);

  useEffect(() => {
    if (success || error) {
      setVisibleModal(true);
    }
  }, [success, error]);

  useEffect(() => {
    setNewMinAmount((newMinAmount) => ({ ...newMinAmount, value: minAmount }));
    setNewMaxAmount((newMaxAmount) => ({ ...newMaxAmount, value: maxAmount }));
    setNewRate((newRate) => ({ ...newRate, value: rate }));
    setNewSaving((newSaving) => ({ ...newSaving, value: saving }));
    setNewExchangeRate((newExchangeRate) => ({
      ...newExchangeRate,
      value: manualRate,
    }));
    setNewExchangeRateUsd((newExchangeRateUsd) => ({
      ...newExchangeRateUsd,
      value: manualUsdRate,
    }));
    setNewStatus(countryStatus);
    setNewManualRateEnable((newManualRateEnable) => ({
      ...newManualRateEnable,
      stringValue: manualRateEnable,
      booleanValue: manualRateEnable === "ACTIVE" ? true : false,
    }));
  }, [
    countryStatus,
    minAmount,
    maxAmount,
    rate,
    saving,
    manualRateEnable,
    manualRate,
    manualUsdRate,
  ]);

  const hideModal = () => {
    setVisibleModal(false);
    cleanCountryState();
    editCountryPress();
  };

  const handleOnButtonClick = () => {
    editCountry(
      selectedCountry,
      newMinAmount.value,
      newMaxAmount.value,
      newRate.value,
      newSaving.value,
      newStatus,
      newExchangeRate.value,
      newExchangeRateUsd.value,
      newManualRateEnable.stringValue
    );
  };

  const enableButton = () => {
    if (
      (maxAmount !== newMaxAmount.value ||
        minAmount !== newMinAmount.value ||
        rate !== newRate.value ||
        saving !== newSaving.value ||
        countryStatus !== newStatus ||
        manualRate !== newExchangeRate.value ||
        newManualRateEnable.stringValue !== manualRateEnable ||
        newExchangeRateUsd.value !== manualUsdRate) &&
      !newMaxAmount.error &&
      !newMinAmount.error &&
      !newRate.error &&
      !newSaving.error &&
      !newExchangeRate.error &&
      !newExchangeRateUsd.error
    )
      return false;
    else return true;
  };

  const handleNewMinAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = event.target.value;
    if (validateNumber(data)) {
      setNewMinAmount({ ...newMinAmount, value: data });
    } else {
      if (data.length === 0) setNewMinAmount({ ...newMinAmount, value: data });
    }
  };

  const handleNewMinAmountOnBlur = () => {
    if (newMinAmount.value) {
      setNewMinAmount({ ...newMinAmount, error: false });
    } else
      setNewMinAmount({
        ...newMinAmount,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleNewMaxAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = event.target.value;
    if (validateNumber(data)) {
      setNewMaxAmount({ ...newMaxAmount, value: data });
    } else {
      if (data.length === 0) setNewMaxAmount({ ...newMaxAmount, value: data });
    }
  };

  const handleNewMaxAmountOnBlur = () => {
    if (newMaxAmount.value) {
      setNewMaxAmount({ ...newMaxAmount, error: false });
    } else
      setNewMaxAmount({
        ...newMaxAmount,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleNewRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    if (validateNumberPoint(data)) {
      setNewRate({ ...newRate, value: data });
    } else {
      if (data.length === 0) setNewRate({ ...newRate, value: data });
    }
  };

  const handleNewRateOnBlur = () => {
    if (newRate.value) {
      if (validateFloat(newRate.value, false, true)) {
        setNewRate({ ...newRate, error: false });
      } else
        setNewRate({
          ...newRate,
          error: true,
          errorMessage: "Formato es inválido: 0.0000",
        });
    } else
      setNewRate({
        ...newRate,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleNewStatusChange = (event: any) => {
    setNewStatus(event.target.value);
  };

  const handleNewSavingChange = (event: any) => {
    const data = event.target.value;
    if (validateNumberPoint(data)) {
      setNewSaving({ ...newSaving, value: data });
    } else {
      if (data.length === 0) setNewSaving({ ...newSaving, value: data });
    }
  };

  const handleNewSavingOnBlur = () => {
    if (newSaving.value) {
      if (validateFloat(newSaving.value, false, true)) {
        setNewSaving({ ...newSaving, error: false });
      } else
        setNewSaving({
          ...newSaving,
          error: true,
          errorMessage: "Formato es inválido: 0.0000",
        });
    } else
      setNewSaving({
        ...newSaving,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleNewManualExchangeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = event.target.value;
    if (validateNumberPoint(data)) {
      setNewExchangeRate({ ...newExchangeRate, value: data });
    } else {
      if (data.length === 0)
        setNewExchangeRate({ ...newExchangeRate, value: data });
    }
  };

  const handleNewManualExchangeOnBlur = () => {
    if (newRate.value) {
      if (validateFloat(newExchangeRate.value)) {
        setNewExchangeRate({ ...newExchangeRate, error: false });
      } else
        setNewExchangeRate({
          ...newExchangeRate,
          error: true,
          errorMessage: "Monto inválido: 0.0000",
        });
    } else
      setNewExchangeRate({
        ...newExchangeRate,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleNewManualUsdExchangeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = event.target.value;
    if (validateNumberPoint(data)) {
      setNewExchangeRateUsd({ ...newExchangeRateUsd, value: data });
    } else {
      if (data.length === 0)
        setNewExchangeRateUsd({ ...newExchangeRateUsd, value: data });
    }
  };

  const handleNewManualUsdExchangeOnBlur = () => {
    if (newRate.value) {
      if (validateFloat(newExchangeRateUsd.value)) {
        setNewExchangeRateUsd({ ...newExchangeRateUsd, error: false });
      } else
        setNewExchangeRateUsd({
          ...newExchangeRateUsd,
          error: true,
          errorMessage: "Monto inválido: 0.0000",
        });
    } else
      setNewExchangeRateUsd({
        ...newExchangeRateUsd,
        error: true,
        errorMessage: "El campo no puede estar vacío",
      });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setNewManualRateEnable({ stringValue: "ACTIVE", booleanValue: true });
    } else {
      setNewManualRateEnable({ stringValue: "INACTIVE", booleanValue: false });
    }
  };

  const renderLoader = () => loading && <Loader />;

  return (
    <Container>
      {renderLoader()}
      <BackButtonContainer>
        <BackButton buttonText="Volver" onClick={editCountryPress} />
      </BackButtonContainer>
      <Container info>
        <Title bold>Editar país</Title>
      </Container>
      <MainContainer>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>País</Title>
            <InputLight
              id="country"
              type="text"
              maxLength={15}
              minLength={3}
              disabled
              value={name || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Moneda</Title>
            <InputLight
              id="currency"
              type="text"
              disabled
              value={currency || ""}
              maxLength={15}
              minLength={3}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Código país</Title>
            <InputLight
              id="callingCode"
              type="text"
              disabled
              value={callingCode || ""}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>ISO Code</Title>
            <InputLight id="isoCode" type="text" disabled value={iso || ""} />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tasa de cambio {currency}/VES </Title>
            <InputLight
              id="manualExchangeType"
              type="text"
              maxLength={18}
              minLength={3}
              disabled={newManualRateEnable.booleanValue}
              defaultValue={manualRate}
              value={newExchangeRate.value || ""}
              error={newExchangeRate.error}
              errorMessage={
                newExchangeRate.error ? newExchangeRate.errorMessage : ""
              }
              onChange={handleNewManualExchangeChange}
              onBlur={handleNewManualExchangeOnBlur}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Tasa de cambio USD/VES </Title>
            <InputLight
              id="manualExchangeTypeUsd"
              type="text"
              maxLength={18}
              minLength={3}
              disabled={newManualRateEnable.booleanValue}
              defaultValue={manualUsdRate}
              value={newExchangeRateUsd.value || ""}
              error={newExchangeRateUsd.error}
              errorMessage={
                newExchangeRateUsd.error ? newExchangeRateUsd.errorMessage : ""
              }
              onChange={handleNewManualUsdExchangeChange}
              onBlur={handleNewManualUsdExchangeOnBlur}
            />
          </ContainerData>
          <ContainerData switchToggle>
            <Title lightSubTitle>Automático </Title>
            <ToggleContainer>
              <PurpleSwitch
                checked={newManualRateEnable.booleanValue}
                onChange={handleSwitchChange}
                name="enableManualRate"
              />
            </ToggleContainer>
          </ContainerData>
        </ContainerData>
        <ContainerData>
          <ContainerData input>
            <Title subTitle>Monto mínimo</Title>
            <InputLight
              id="minAmount"
              type="text"
              maxLength={11}
              minLength={3}
              defaultValue={minAmount}
              value={newMinAmount.value || ""}
              error={newMinAmount.error}
              errorMessage={newMinAmount.error ? newMinAmount.errorMessage : ""}
              onChange={handleNewMinAmountChange}
              onBlur={handleNewMinAmountOnBlur}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Monto máximo</Title>
            <InputLight
              id="maxAmount"
              type="text"
              maxLength={11}
              minLength={3}
              defaultValue={maxAmount}
              value={newMaxAmount.value || ""}
              error={newMaxAmount.error}
              errorMessage={newMaxAmount.error ? newMaxAmount.errorMessage : ""}
              onChange={handleNewMaxAmountChange}
              onBlur={handleNewMaxAmountOnBlur}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>% Tasa de cambio</Title>
            <InputLight
              id="rate"
              type="text"
              maxLength={11}
              minLength={3}
              defaultValue={rate}
              value={newRate.value || ""}
              error={newRate.error}
              errorMessage={newRate.error ? newRate.errorMessage : ""}
              onChange={handleNewRateChange}
              onBlur={handleNewRateOnBlur}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>% Ahorro</Title>
            <InputLight
              id="saving"
              type="text"
              maxLength={11}
              minLength={3}
              defaultValue={saving}
              value={newSaving.value || ""}
              error={newSaving.error}
              errorMessage={newSaving.error ? newSaving.errorMessage : ""}
              onChange={handleNewSavingChange}
              onBlur={handleNewSavingOnBlur}
            />
          </ContainerData>
          <ContainerData input>
            <Title subTitle>Estatus</Title>
            <Dropdown
              id={"status"}
              defaultValue={countryStatus}
              value={newStatus || ""}
              options={STATUS_TYPES}
              onChange={handleNewStatusChange}
            />
          </ContainerData>
        </ContainerData>
      </MainContainer>
      <ButtonSection>
        <Button
          buttonText="GUARDAR"
          onClick={handleOnButtonClick}
          disabled={enableButton()}
        />
      </ButtonSection>
      <Modal
        show={visibleModal}
        title={success ? "¡Actualización exitosa!" : ""}
        check={success}
        body={
          success
            ? "Su proceso ha sido completado correctamente"
            : "Ha ocurrido un error, inténtalo nuevamente"
        }
        handleClose={hideModal}
      />
    </Container>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.country.loading,
    success: state.country.successOnFormAction,
    country: state.country.country,
    error: state.country.error,
  };
};

export default connect(mapStateTopProps, {
  getCountryId,
  editCountry,
  cleanCountryState,
})(EditCountry);
