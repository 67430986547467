import React, { useState } from "react";
import NewUser from "./NewUser";
import UserList from "./UserList";
import EditUser from "./EditUser";

const User = () => {
  const [newUser, handleNewUserPress] = useState(false);
  const [editUserPress, handleEditUserPress] = useState(false);
  const [selectedUser, handleSelectedUser] = useState();

  return (
    <>
      {!newUser ? (
        !editUserPress ? (
          <UserList
            newUserPress={() => handleNewUserPress(!newUser)}
            editUserPress={() => handleEditUserPress(!editUserPress)}
            selectedUser={(user) => handleSelectedUser(user)}
          />
        ) : (
          <EditUser
            selectedUser={selectedUser}
            editUserPress={() => handleEditUserPress(!editUserPress)}
          />
        )
      ) : (
        <NewUser newUserPress={() => handleNewUserPress(!newUser)} />
      )}
    </>
  );
};

export default User;
