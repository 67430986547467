import moment from "moment";
import { getErrorMessage } from "../../services/ErrorHandler";
import RequestManager from "../../services/RequestManager";

const base = "USER_ACTIONS_";
const requestManager = new RequestManager();

export const USER_ACTIONS = {
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  CLEAN_STATE: base + "CLEAN_STATE",
  CLEAR_SUCCESS: base + "CLEAR_SUCCESS",
  DO_NOTHING: base + "DO_NOTHING",

  LOG_IN_START: base + "LOG_IN_START",
  LOG_IN_FAIL: base + "LOG_IN_FAIL",
  LOG_IN_SUCCESS: base + "LOG_IN_SUCCESS",
  SET_USER_PROFILE: base + "SET_USER_PROFILE",

  FORM_ACTION_START: base + "FORM_ACTION_START",
  FORM_ACTION_SUCCESS: base + "FORM_ACTION_SUCCESS",
  FORM_ACTION_FAIL: base + "FORM_ACTION_FAIL",
};

export const getUserProfile = (navigateTo?: any) => {
  const accessToken = localStorage.getItem("accessToken");

  return (dispatch: any, getState: any) => {
    requestManager
      .get("/celupago-user/profile", {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        const { email, id, name, role, last_name } = response.data;
        dispatch({
          type: USER_ACTIONS.SET_USER_PROFILE,
          payload: {
            id,
            name,
            role,
            email,
            lastName: last_name,
          },
        });
        if (navigateTo) {
          navigateTo();
        }
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        if (
          errorMessage.message &&
          errorMessage.error &&
          errorMessage.error !== "UNAUTHORIZED"
        ) {
          dispatch({
            type: USER_ACTIONS.LOG_IN_FAIL,
            payload: errorMessage.message
              ? errorMessage.message
              : errorMessage.error,
          });
        } else {
          dispatch({
            type: USER_ACTIONS.DISPATCH_UNAUTHORIZED,
          });
        }
      });
  };
};

export const login = (email: string, password: string, navigateTo: any) => {
  const data = { email, password };
  return (dispatch: any, getState: any) => {
    dispatch({ type: USER_ACTIONS.LOG_IN_START });
    requestManager
      .post("/celupago-user/signin", data, {})
      .then((response: any) => {
        const sessionExpiresIn = moment()
          .add(response.data.expiresIn, "seconds")
          .unix();
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("sessionExpiresIn", sessionExpiresIn.toString());
        localStorage.setItem("sessionTime", response.data.expiresIn.toString());
        dispatch({ type: USER_ACTIONS.LOG_IN_SUCCESS });
        navigateTo();
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({
          type: USER_ACTIONS.LOG_IN_FAIL,
          payload: errorMessage.message
            ? errorMessage.message
            : errorMessage.error,
        });
      });
  };
};

export const passwordUpdate = (newPassword: string, password: string) => {
  return async (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: USER_ACTIONS.FORM_ACTION_START });
    const data = {
      password,
      newPassword,
    };
    try {
      await requestManager.post(`celupago-user/changepassword`, data, {
        Authorization: `Bearer ${accessToken}`,
      });
      dispatch({ type: USER_ACTIONS.FORM_ACTION_SUCCESS });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage.error === "UNAUTHORIZED") {
        dispatch(dispatchUnauthorizedError());
      } else {
        dispatch({
          type: USER_ACTIONS.FORM_ACTION_FAIL,
          payload: errorMessage.message,
        });
      }
    }
  };
};

export const clearSuccess = () => {
  return {
    type: USER_ACTIONS.CLEAR_SUCCESS,
  };
};

export const dispatchUnauthorizedError = () => {
  return {
    type: USER_ACTIONS.DISPATCH_UNAUTHORIZED,
  };
};
