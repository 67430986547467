export const getErrorMessage = (error: any, user?: any) => {
  if (error.response) {
    if (error.response.data) {
      if (
        error.response.data.error &&
        error.response.data.statusCode &&
        error.response.data.statusCode === 401 &&
        error.response.data.error === "Unauthorized"
      ) {
        error.response.data.error = "UNAUTHORIZED";

        localStorage.removeItem("accessToken");
        localStorage.removeItem("loginDate");
        localStorage.removeItem("sessionExpiresIn");
        localStorage.removeItem("sessionTime");

        return error.response.data;
      }
      return error.response.data;
    }
  } else if (error.request) {
    error = { message: "Comprueba tu conexión e inténtalo nuevamente" };
    return error;
  } else {
    error = { message: "Error Inesperado" };
    return error;
  }
};
